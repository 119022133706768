import { Card, CardContent, Typography } from '@mui/material';

interface ReportStatCardProps {
  label: string;
  value: string | number;
}

export default function ReportStatisticCard({
  label,
  value,
}: ReportStatCardProps) {
  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: 200,
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
      variant="outlined"
      elevation={0}>
      <CardContent>
        <Typography variant="overline">{label}</Typography>
        <Typography variant="h4">{value}</Typography>
      </CardContent>
    </Card>
  );
}
