import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  campaignsDocument,
  dashboardCampaignsDocument,
  deleteCampaignDocument,
} from '@social-garden/api/documents/campaign.ts';
import { CampaignStatus } from '@social-garden/utils/types.ts';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Confirm from '../../components/Confirm.tsx';

interface DeleteCampaignMenuItemProps {
  campaign: {
    id: string;
    status: CampaignStatus;
  };
}

export default function DeleteCampaignMenuItem({
  campaign,
}: DeleteCampaignMenuItemProps) {
  const { t } = useTranslation(['common', 'manager']);
  const navigate = useNavigate();

  const [deleteCampaign, { loading }] = useMutation(deleteCampaignDocument, {
    refetchQueries: [
      campaignsDocument,
      {
        query: dashboardCampaignsDocument,
        variables: {
          input: {
            brandIds: [],
            status: [CampaignStatus.DRAFT, CampaignStatus.ACTIVE],
          },
          first: 4,
          page: 1,
        },
      },
    ],
  });

  const handleOnDelete = useCallback(async () => {
    if (campaign.status !== CampaignStatus.DRAFT) {
      return;
    }
    const result = await deleteCampaign({
      variables: {
        id: campaign.id,
      },
    });
    if (result.data?.deleteCampaign) {
      navigate('/campaigns');
    }
  }, [campaign.status, campaign.id, deleteCampaign, navigate]);

  if (campaign.status !== CampaignStatus.DRAFT) {
    return null;
  }

  return (
    <Confirm
      title={t('manager:campaign.confirmDelete.title')}
      description={t('manager:campaign.confirmDelete.description')}
      buttonText={t('common:delete')}
      color="error">
      <MenuItem disabled={loading} onClick={handleOnDelete}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faTrash} />
        </ListItemIcon>
        <ListItemText>{t('common:delete')}</ListItemText>
      </MenuItem>
    </Confirm>
  );
}
