import { Button, Link, Stack, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { CampaignGiftType } from '@social-garden/utils/types.ts';
import {
  acceptCampaignApplicationDocument,
  declineCampaignApplicationDocument,
} from '@social-garden/api/documents/creatorHasCampaign.ts';
import { formatISO } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import {
  AcceptCampaignApplicationFieldValues,
  AcceptCampaignApplicationSchema,
} from '../../constants/ValidationSchema.ts';
import Section from '../../components/Section.tsx';
import GiftCodeField from '../../components/GiftCodeField.tsx';
import ChannelStatistics, {
  ChannelStatisticsData,
} from '../../components/ChannelStatistics.tsx';
import PriceSummary from '../../components/PriceSummary.tsx';
import { FormFieldProps } from '../../utils/types.ts';
import DeliveryAddress from '../../components/DeliveryAddress.tsx';

export interface ReviewCampaignApplicationProps {
  creatorHasCampaign: {
    id: string;
    creator: {
      username: string;
    };
    rewardValue?: number | null;
    price: number;
    channel?: {
      channelable: ChannelStatisticsData;
    } | null;
    answers: {
      id: string;
      answer: string;
      question: {
        question: string;
      };
    }[];
    deliveryAddress?: {
      id: string;
      recipientName: string;
      streetAddressLine1: string;
      streetAddressLine2?: string | null;
      postcode: string;
      city: string;
      country: string;
    } | null;
    campaign: {
      submitUntil: string;
      gifts: {
        id: string;
        name: string;
        type: CampaignGiftType;
      }[];
    };
  };
}

export default function ReviewCampaignApplication({
  creatorHasCampaign,
}: ReviewCampaignApplicationProps) {
  const { t } = useTranslation(['common', 'manager']);
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<AcceptCampaignApplicationFieldValues>({
    mode: 'all',
    resolver: zodResolver(AcceptCampaignApplicationSchema),
    defaultValues: {
      submitUntil: new Date(
        new Date(creatorHasCampaign.campaign.submitUntil).setUTCHours(
          0,
          0,
          0,
          0,
        ),
      ),
      campaignGiftCodes: creatorHasCampaign.campaign.gifts
        .filter((gift) => gift.type === CampaignGiftType.CODE)
        .map((gift) => ({
          campaignGiftId: gift.id,
          code: '',
        })),
    },
  });

  const { fields: giftCodeFields } = useFieldArray<
    AcceptCampaignApplicationFieldValues,
    'campaignGiftCodes'
  >({
    control,
    name: 'campaignGiftCodes',
  });

  const [
    acceptCampaignApplication,
    {
      loading: acceptCampaignApplicationLoading,
      error: acceptCampaignApplicationError,
    },
  ] = useMutation(acceptCampaignApplicationDocument);

  const [
    declineCampaignApplication,
    { loading: declineCampaignApplicationLoading },
  ] = useMutation(declineCampaignApplicationDocument);

  const renderSubmitUntil = useCallback(
    ({
      field: { value, onChange },
      fieldState: { invalid, error },
      formState: { defaultValues },
    }: FormFieldProps<AcceptCampaignApplicationFieldValues, 'submitUntil'>) => (
      <DatePicker
        sx={{ maxWidth: 300 }}
        label={t('common:campaign.submitUntil')}
        timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        defaultValue={value}
        minDate={defaultValues?.submitUntil}
        slotProps={{
          textField: {
            fullWidth: true,
            required: true,
            error: invalid,
            helperText: error?.message,
          },
        }}
        onChange={onChange}
      />
    ),
    [t],
  );

  const handleOnAcceptApplication = useCallback(
    async ({
      submitUntil,
      campaignGiftCodes,
    }: AcceptCampaignApplicationFieldValues) => {
      await acceptCampaignApplication({
        variables: {
          input: {
            creatorHasCampaignId: creatorHasCampaign.id,
            submitUntil: formatISO(submitUntil, {
              representation: 'date',
            }),
            campaignGiftCodes:
              campaignGiftCodes.length > 0 ? campaignGiftCodes : undefined,
          },
        },
      });
    },
    [acceptCampaignApplication, creatorHasCampaign.id],
  );

  const handleOnDeclineApplication = useCallback(async () => {
    await declineCampaignApplication({
      variables: {
        creatorHasCampaignId: creatorHasCampaign.id,
      },
    });
  }, [declineCampaignApplication, creatorHasCampaign.id]);

  useEffect(() => {
    if (
      acceptCampaignApplicationError?.message ===
      'Payment could not be completed!'
    ) {
      enqueueSnackbar(t('manager:campaignApplication.paymentError'), {
        variant: 'info',
      });
    }
  }, [acceptCampaignApplicationError, enqueueSnackbar, t]);

  return (
    <Stack
      spacing={2}
      component="form"
      noValidate
      onSubmit={handleSubmit(handleOnAcceptApplication)}>
      {creatorHasCampaign.channel ? (
        <ChannelStatistics data={creatorHasCampaign.channel.channelable} />
      ) : null}
      <Controller<AcceptCampaignApplicationFieldValues, 'submitUntil'>
        name="submitUntil"
        control={control}
        render={renderSubmitUntil}
      />
      {creatorHasCampaign.answers.length > 0 ? (
        <Section
          header={t('manager:campaignApplication.questionsAndAnswers.header')}
          spacing={1}>
          {creatorHasCampaign.answers.map(({ id, question, answer }, index) => (
            <Stack key={id}>
              <Typography variant="subtitle2">
                {`${index + 1}. ${question.question}`}
              </Typography>
              <Typography>{answer}</Typography>
            </Stack>
          ))}
        </Section>
      ) : null}
      {giftCodeFields.length > 0 ? (
        <Section
          header={t('manager:campaignApplication.giftCode.header')}
          subHeader={t('manager:campaignApplication.giftCode.subHeader')}>
          {giftCodeFields.map((field, index) => (
            <Stack key={field.id} spacing={1}>
              <Typography variant="subtitle2">
                {
                  creatorHasCampaign.campaign.gifts.find(
                    (gift) => gift.id === field.campaignGiftId,
                  )?.name
                }
              </Typography>
              <Controller<
                AcceptCampaignApplicationFieldValues,
                `campaignGiftCodes.${typeof index}.code`
              >
                name={`campaignGiftCodes.${index}.code`}
                control={control}
                render={GiftCodeField}
              />
            </Stack>
          ))}
        </Section>
      ) : null}
      {creatorHasCampaign.deliveryAddress ? (
        <Section
          header={t('manager:campaignApplication.deliveryAddress.header')}
          subHeader={t(
            'manager:campaignApplication.deliveryAddress.subHeader',
          )}>
          <DeliveryAddress
            deliveryAddress={creatorHasCampaign.deliveryAddress}
          />
        </Section>
      ) : null}
      <Section header={t('manager:campaignApplication.priceSummary.header')}>
        <PriceSummary
          price={creatorHasCampaign.price}
          rewardValue={creatorHasCampaign.rewardValue}
        />
      </Section>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          color="error"
          disabled={declineCampaignApplicationLoading}
          onClick={handleOnDeclineApplication}>
          {t('common:decline')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="success"
          disabled={!isValid || acceptCampaignApplicationLoading}>
          {creatorHasCampaign.price > 0
            ? t('manager:campaignApplication.paid.acceptButton')
            : t('manager:campaignApplication.free.acceptButton')}
        </Button>
      </Stack>
      {creatorHasCampaign.price > 0 ? (
        <Typography variant="caption">
          <Trans
            i18nKey="campaignApplication.paid.disclaimer"
            ns="manager"
            components={[
              <Link
                key="tos"
                href={import.meta.env.VITE_TERMS_OF_SERVICE_URL}
                target="_blank"
              />,
            ]}
          />
        </Typography>
      ) : null}
    </Stack>
  );
}
