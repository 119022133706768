import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faPowerOff } from '@fortawesome/pro-regular-svg-icons/faPowerOff';
import { MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import ProfilePicture from './ProfilePicture.tsx';

interface ProfileIconButtonProps {
  email: string;
}

export default function ProfileIconButton({ email }: ProfileIconButtonProps) {
  const { t } = useTranslation(['common', 'manager']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton
        color="inherit"
        size="small"
        aria-controls="profile-button-menu"
        aria-haspopup="true"
        onClick={handleMenu}>
        <ProfilePicture username={email} size={26} />
      </IconButton>
      <Menu
        id="profile-button-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem to="/profile" component={Link} onClick={handleClose}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faUser} />
          </ListItemIcon>
          <ListItemText primary={t('manager:navigation.profile')} />
        </MenuItem>
        <MenuItem
          to={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
          component={Link}
          onClick={handleClose}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faEnvelope} />
          </ListItemIcon>
          <ListItemText primary={t('common:support')} />
        </MenuItem>
        <MenuItem to="/logout" component={Link} onClick={handleClose}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faPowerOff} />
          </ListItemIcon>
          <ListItemText primary={t('common:logout')} />
        </MenuItem>
      </Menu>
    </>
  );
}
