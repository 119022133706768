import { useTheme } from '@mui/material';
import { CSSProperties } from 'react';

interface LogoProps {
  style?: Omit<CSSProperties, 'aspectRatio'>;
  fill?: string;
}

export default function Logo({ style, fill }: LogoProps) {
  const { palette } = useTheme();

  return (
    <svg
      style={{
        aspectRatio: 47 / 59,
        height: 59,
        ...style,
      }}
      viewBox="0 0 47 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4923 14.4132C12.1649 16.7441 10.8575 19.9084 10.8575 23.2055C10.8575 26.4487 12.4019 30.0835 15.5662 34.3755C18.6801 38.5849 22.9182 42.7727 26.9983 46.6445L27.0087 46.6544C27.2935 46.9236 28.5975 48.1559 30.9204 50.3582L23.4929 58.2059C21.1894 56.0253 19.8883 54.7957 19.5863 54.5103L19.5672 54.4922C15.0705 50.2792 10.4122 45.6747 6.80255 40.7829C2.22322 34.5838 0 28.83 0 23.2055C0 10.4121 10.4409 0 23.2846 0C36.1282 0 46.5655 10.4121 46.5655 23.2055C46.5655 28.2502 45.2766 32.7781 41.6946 38.1383L41.6982 38.1417C40.5992 39.8154 39.4247 41.4101 38.1425 42.9545C37.9342 43.206 36.8962 44.4594 35.0286 46.7222C33.6075 45.3769 32.5679 44.3922 31.9091 43.7682C31.4756 43.3576 31.207 43.1031 31.1029 43.0048C26.9977 39.133 23.1259 35.3151 19.7246 30.9477C20.0932 30.558 21.7211 28.8397 23.3773 27.0913C25.1102 25.262 26.8742 23.3999 27.2599 22.9922C27.3102 23.1 27.3892 23.2042 27.4682 23.3083C28.8367 25.1599 30.9015 27.511 33.6645 30.3626C35.0454 27.7811 35.7619 25.3792 35.7619 23.2055C35.7619 19.9084 34.4545 16.7441 32.1272 14.4132C29.771 12.0858 26.6068 10.7784 23.3097 10.7784C19.9874 10.7784 16.8232 12.0858 14.4923 14.4132Z"
        fill={fill ?? palette.primary.main}
      />
    </svg>
  );
}
