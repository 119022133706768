import { Stack, Typography } from '@mui/material';

interface DeliveryAddressProps {
  deliveryAddress: {
    recipientName: string;
    streetAddressLine1: string;
    streetAddressLine2?: string | undefined | null;
    postcode: string;
    city: string;
    country: string;
  };
}

export default function DeliveryAddress({
  deliveryAddress,
}: DeliveryAddressProps) {
  return (
    <Stack>
      <Typography>{deliveryAddress.recipientName}</Typography>
      <Typography>{deliveryAddress.streetAddressLine1}</Typography>
      {deliveryAddress.streetAddressLine2 ? (
        <Typography>{deliveryAddress.streetAddressLine2}</Typography>
      ) : null}
      <Typography>
        {`${deliveryAddress.postcode}, ${deliveryAddress.city}`}
      </Typography>
      <Typography>{deliveryAddress.country}</Typography>
    </Stack>
  );
}
