import { Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useStripeCustomerPortalUrl from '../../hooks/useStripeCustomerPortalUrl.ts';

export default function SubscriptionPastDue() {
  const { t } = useTranslation(['manager']);
  const { data } = useStripeCustomerPortalUrl();

  return (
    <Container
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: 6,
      }}
      maxWidth="md">
      <Typography variant="h2" align="center">
        {t('manager:subscriptionPastDue.title')}
      </Typography>
      <Typography variant="h5" align="center">
        {t('manager:subscriptionPastDue.message')}
      </Typography>
      <Button
        href={data.stripeCustomerPortalUrl}
        variant="contained"
        size="large">
        {t('manager:manageStripeAccountButton')}
      </Button>
    </Container>
  );
}
