import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteManagerDocument } from '@social-garden/api/documents/manager.ts';
import AuthToken from '../constants/AuthToken.ts';
import { persistor } from '../utils/apolloInMemoryCache.ts';

export default function useDeleteManager() {
  const navigate = useNavigate();
  const [deleteManager, { error, loading, client }] = useMutation(
    deleteManagerDocument,
  );

  const handleOnDelete = useCallback(async () => {
    const response = await deleteManager();
    if (response.data?.deleteManager) {
      AuthToken.removeToken();
      await persistor.purge();
      await client.clearStore();
      navigate('/login');
    }
  }, [client, navigate, deleteManager]);

  return [handleOnDelete, { error, loading }] as const;
}
