export const IGNORE_ERRORS = [
  'Unauthenticated',
  'Invalid credentials',
  'Invalid credentials!',
  'Creator could not be found!',
  'Email not verified!',
  'Internal server error',
  'The email-verification-code is invalid!',
  'Validation failed for the field [registerCreator].',
  'Validation failed for the field [registerCompany].',
  'The meta-tag does not exist!',
  'The value already exists with that type!',
  'This action is unauthorized.',
  'Payment could not be completed!',
  'The required scopes are not granted!',
  'The data-access expires earlier than 1 week!',
  'The data-access expires earlier than 24 hours!',
  'The password-recovery-code is invalid!',
  'The Instagram-channel already exists!',
  'The Tiktok-channel already exists!',
  'The Youtube-channel already exists!',
  'The accessToken does not have access to the Instagram-Account!',
  'The accessToken belongs to another Youtube-Account!',
  'The accessToken belongs to another Tiktok-Account!',
];
