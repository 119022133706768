import { Button, Container, Stack, Typography, Grid2 } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCallback, MouseEventHandler } from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { brandsDocument } from '@social-garden/api/documents/brand.ts';
import BrandLogo from '../../components/BrandLogo.tsx';
import useBrandUsage from '../../hooks/useBrandUsage.ts';
import ErrorBoundary from '../../components/ErrorBoundary.tsx';
import PageTitle from '../../components/PageTitle.tsx';

export default function Brands() {
  const { t } = useTranslation(['common', 'manager']);
  const { data } = useSuspenseQuery(brandsDocument);
  const navigate = useNavigate();
  const { exceeded } = useBrandUsage();

  const handleOnBrandEditClick = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(
    (event) => {
      navigate(`/update-brand/${event.currentTarget.value}`);
    },
    [navigate],
  );

  if (data.whoAmI.__typename !== 'Manager') {
    return <ErrorBoundary />;
  }

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}>
      <Grid2 container spacing={4} alignItems="center" size={12}>
        <Grid2
          size={{
            xs: 12,
            md: 'grow',
          }}>
          <PageTitle>{t('manager:navigation.brands')}</PageTitle>
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 'auto',
          }}>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            disabled={exceeded}
            href="/create-brand">
            {t('manager:brand.create.newButton')}
          </Button>
        </Grid2>
      </Grid2>
      <Grid2 container spacing={4}>
        {data.whoAmI.company.brands.map((brand) => (
          <Grid2
            key={brand.id}
            size={{
              xs: 12,
              sm: 6,
              md: 4,
              lg: 3,
            }}>
            <Stack spacing={1} alignItems="center" textAlign="center">
              <BrandLogo
                sx={{
                  width: 'auto',
                  height: 'auto',
                  aspectRatio: 1,
                }}
                src={brand.logo}
                alt={brand.name}
              />
              <Typography
                sx={{
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word',
                }}
                variant="h5">
                {brand.name}
              </Typography>
              <Button value={brand.id} onClick={handleOnBrandEditClick}>
                {t('common:edit')}
              </Button>
            </Stack>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  );
}
