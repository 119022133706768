import { useSuspenseQuery } from '@apollo/client';
import { storageSpaceUsageDocument } from '@social-garden/api/documents/manager.ts';

export default function useStorageSpaceUsage() {
  const {
    data: { whoAmI },
  } = useSuspenseQuery(storageSpaceUsageDocument);

  if (whoAmI.__typename !== 'Manager') {
    return { current: 0, max: 0, exceeded: true };
  }

  if (
    !whoAmI.company.currentSubscription ||
    !whoAmI.company.currentSubscription.subscriptionPlan
  ) {
    return {
      current: whoAmI.company.usedStorageSpace,
      max: 0,
      exceeded: true,
    };
  }

  return {
    current: whoAmI.company.usedStorageSpace,
    max: whoAmI.company.currentSubscription.subscriptionPlan.storageSpaceLimit,
    exceeded:
      whoAmI.company.usedStorageSpace >=
      whoAmI.company.currentSubscription.subscriptionPlan.storageSpaceLimit,
  };
}
