import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/pro-regular-svg-icons/faShare';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCopyToClipboard from '../../hooks/useCopyToClipboard.ts';

interface ShareCampaignMenuItemProps {
  campaign: {
    id: string;
  };
}

export default function ShareCampaignMenuItem({
  campaign,
}: ShareCampaignMenuItemProps) {
  const { t } = useTranslation(['common']);
  const [shareUrl, setShareUrl] = useState<string>();
  const [copyToClipboard] = useCopyToClipboard();

  const handleOnShare = useCallback(() => {
    setShareUrl(
      `${import.meta.env.VITE_CREATOR_APP_URL}/campaign-details/${campaign.id}`,
    );
  }, [campaign.id]);

  const handleCopyToClipboard = useCallback(async () => {
    if (shareUrl) {
      await copyToClipboard(shareUrl);
    }
  }, [shareUrl, copyToClipboard]);

  const handleOnClose = useCallback(() => {
    setShareUrl(undefined);
  }, []);

  return (
    <>
      <MenuItem onClick={handleOnShare}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faShare} />
        </ListItemIcon>
        <ListItemText>{t('common:share')}</ListItemText>
      </MenuItem>
      <Dialog open={Boolean(shareUrl)} onClose={handleOnClose}>
        <DialogTitle>{t('common:share')}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.default,
              p: 1,
              border: 1,
              borderColor: 'divider',
              borderRadius: 1,
            }}>
            <code
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
              }}>
              {shareUrl}
            </code>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleOnClose}>
            {t('common:close')}
          </Button>
          <Button variant="contained" onClick={handleCopyToClipboard}>
            {t('common:copy')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
