import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useManagerLayoutOutletContext from '../hooks/useManagerLayoutOutletContext.ts';

export default function SetupLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { manager } = useManagerLayoutOutletContext();

  useEffect(() => {
    if (manager.company.currentSubscription?.status === 'past_due') {
      if (location.pathname !== '/subscription-past-due') {
        navigate('/subscription-past-due');
      }
    } else if (
      !manager.company.currentSubscription ||
      !manager.company.currentSubscription.subscriptionPlan ||
      manager.company.currentSubscription.status !== 'active'
    ) {
      if (location.pathname !== '/choose-subscription') {
        navigate('/choose-subscription');
      }
    } else if (manager.company.brands.length === 0) {
      if (location.pathname !== '/create-first-brand') {
        navigate('/create-first-brand');
      }
    } else {
      navigate('/');
    }
  }, [
    location.pathname,
    manager.company.brands.length,
    manager.company.currentSubscription,
    navigate,
  ]);

  return <Outlet />;
}
