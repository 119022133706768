import { Divider, Stack, StackProps, Typography } from '@mui/material';
import { formatEuroCents } from '@social-garden/utils/helpers.ts';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

interface PriceSummaryProps extends StackProps {
  price: number;
  rewardValue?: number | null;
}

export default function PriceSummary({
  price,
  rewardValue,
  ...rest
}: PriceSummaryProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common']);

  const bookingFee = useMemo<number>(() => {
    if (price === 0) {
      return 0;
    }
    if (rewardValue) {
      return price - rewardValue;
    }
    return price;
  }, [price, rewardValue]);

  return (
    <Stack maxWidth={300} spacing={0.5} {...rest}>
      <Stack direction="row" spacing={0.5} justifyContent="space-between">
        <Typography>{t('common:creatorRemuneration')}:</Typography>
        <Typography>
          {formatEuroCents(rewardValue || 0, resolvedLanguage)}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={0.5} justifyContent="space-between">
        <Typography>{t('common:bookingFee')}:</Typography>
        <Typography>{formatEuroCents(bookingFee, resolvedLanguage)}</Typography>
      </Stack>
      <Divider />
      <Stack>
        <Stack direction="row" spacing={0.5} justifyContent="space-between">
          <Typography fontWeight={500}>{t('common:price')}:</Typography>
          <Typography fontWeight={500}>
            {formatEuroCents(price, resolvedLanguage)}
          </Typography>
        </Stack>
        {price > 0 ? (
          <Typography
            variant="caption"
            fontSize={11}
            color="text.secondary"
            alignSelf="flex-end"
            textAlign="right">
            {t('common:withoutVAT')}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
}
