import { Alert, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface CampaignGoalSubmissionPendingProps {
  creatorHasCampaign: {
    submitUntil: string;
    reason?: string | null;
  };
}

export default function CampaignGoalSubmissionPending({
  creatorHasCampaign,
}: CampaignGoalSubmissionPendingProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common', 'manager']);

  return (
    <Stack spacing={2}>
      <Alert severity="info">
        {t('manager:creatorHasCampaignFlow.waitForSubmissionStep.content', {
          dateTime: new Date(creatorHasCampaign.submitUntil).toLocaleString(
            resolvedLanguage,
            {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            },
          ),
        })}
      </Alert>
      {creatorHasCampaign.reason ? (
        <Stack>
          <Typography fontWeight={500}>
            {t('common:creatorHasCampaign.reason')}
          </Typography>
          <Typography>{creatorHasCampaign.reason}</Typography>
        </Stack>
      ) : null}
    </Stack>
  );
}
