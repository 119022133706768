import { useCallback, useState } from 'react';
import { DataGrid, GridActionsCellItem, GridRowId } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  creatorsDocument,
  impersonateCreatorDocument,
} from '@social-garden/api/documents/creator.ts';
import usePaginationModel from '../../hooks/usePaginationModel.ts';
import useCopyToClipboard from '../../hooks/useCopyToClipboard.ts';

export default function AdminCreatorsTab() {
  const [copyToClipboard] = useCopyToClipboard();
  const [paginationModel, setPaginationModel] = usePaginationModel();
  const [impersonateCreatorUrl, setImpersonateCreatorUrl] = useState<string>();

  const { data, previousData, loading } = useQuery(creatorsDocument, {
    variables: {
      first: paginationModel.pageSize,
      page: paginationModel.page + 1,
    },
  });

  const [impersonateCreator] = useMutation(impersonateCreatorDocument);

  const handleOnImpersonate = useCallback(
    (id: GridRowId) => async () => {
      const result = await impersonateCreator({
        variables: {
          id: id.toString(),
        },
      });
      if (result.data?.impersonateCreator) {
        setImpersonateCreatorUrl(
          `creatorsocialgarden://impersonate/${result.data.impersonateCreator}`,
        );
      }
    },
    [impersonateCreator],
  );

  const handleCopyToClipboard = useCallback(async () => {
    if (impersonateCreatorUrl) {
      await copyToClipboard(impersonateCreatorUrl);
    }
  }, [copyToClipboard, impersonateCreatorUrl]);

  const handleOnClose = useCallback(() => {
    setImpersonateCreatorUrl(undefined);
  }, []);

  return (
    <>
      <DataGrid
        loading={loading || data == undefined}
        rows={data?.creators.data ?? []}
        columns={[
          {
            flex: 1,
            field: 'id',
            headerName: 'ID',
          },
          {
            flex: 1,
            minWidth: 200,
            field: 'username',
            headerName: 'Username',
          },
          {
            field: 'actions',
            type: 'actions',
            getActions: ({ id }) => {
              return [
                <GridActionsCellItem
                  key="impersonate"
                  icon={<FontAwesomeIcon icon={faEye} />}
                  label="Impersonate"
                  showInMenu={true}
                  onClick={handleOnImpersonate(id)}
                />,
              ];
            },
          },
        ]}
        paginationMode="server"
        rowCount={
          data?.creators.paginatorInfo.total ??
          previousData?.creators.paginatorInfo.total ??
          0
        }
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
      <Dialog open={!!impersonateCreatorUrl} onClose={handleOnClose}>
        <DialogTitle>Impersonate URL</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.default,
              p: 1,
              border: 1,
              borderColor: 'divider',
              borderRadius: 1,
            }}>
            <code
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
              }}>
              {impersonateCreatorUrl}
            </code>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleOnClose}>
            Close
          </Button>
          <Button variant="contained" onClick={handleCopyToClipboard}>
            Copy
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
