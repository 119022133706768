interface ColorShade {
  [key: string]: string;
  '50': string;
  '100': string;
  '200': string;
  '300': string;
  '400': string;
  '500': string;
  '600': string;
  '700': string;
  '800': string;
  '900': string;
  '950': string;
}

export const WHITE = '#FFFFFF';
export const BLACK = '#000000';

export const GREEN: ColorShade = {
  '50': '#f3faf3',
  '100': '#e3f5e4',
  '200': '#c7ebca',
  '300': '#9bdaa1',
  '400': '#68c070',
  '500': '#45a84f',
  '600': '#33863c',
  '700': '#2b6a32',
  '800': '#26552b',
  '900': '#214626',
  '950': '#0d2610',
};

export const GRAY: ColorShade = {
  '50': '#fafafa',
  '100': '#f4f4f5',
  '200': '#e4e4e7',
  '300': '#d4d4d8',
  '400': '#a1a1aa',
  '500': '#71717a',
  '600': '#52525b',
  '700': '#3f3f46',
  '800': '#27272a',
  '900': '#18181b',
  '950': '#09090b',
};

export const ORANGE: ColorShade = {
  '50': '#fffbea',
  '100': '#fff2c5',
  '200': '#ffe585',
  '300': '#ffd146',
  '400': '#ffbc1b',
  '500': '#ff9800',
  '600': '#e27100',
  '700': '#bb4c02',
  '800': '#983a08',
  '900': '#7c300b',
  '950': '#481700',
};

export const RED: ColorShade = {
  '50': '#fef3f2',
  '100': '#ffe3e1',
  '200': '#ffccc8',
  '300': '#ffa8a2',
  '400': '#fc776d',
  '500': '#f44336',
  '600': '#e22d20',
  '700': '#be2217',
  '800': '#9d2017',
  '900': '#82211a',
  '950': '#470c08',
};

export const TEAL: ColorShade = {
  '50': '#effefb',
  '100': '#c7fff4',
  '200': '#90ffe9',
  '300': '#51f7dc',
  '400': '#1de4ca',
  '500': '#04c8b1',
  '600': '#009688',
  '700': '#058075',
  '800': '#0a655f',
  '900': '#0d544f',
  '950': '#003332',
};

export const PURPLE: ColorShade = {
  '50': '#f9f0fa',
  '100': '#f6e4f7',
  '200': '#e6beeb',
  '300': '#d99be0',
  '400': '#b85ac7',
  '500': '#9c27b0',
  '600': '#85209e',
  '700': '#691585',
  '800': '#4c0e69',
  '900': '#34084f',
  '950': '#1d0333',
};

export const BLUE: ColorShade = {
  '50': '#CFE8FC',
  '100': '#BCDFFB',
  '200': '#95CDF9',
  '300': '#6EBBF7',
  '400': '#48A8F5',
  '500': '#2196F3',
  '600': '#0B79D1',
  '700': '#085A9B',
  '800': '#063B66',
  '900': '#031C31',
  '950': '#010D16',
};
