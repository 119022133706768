import { useTranslation } from 'react-i18next';
import { Grid2, Link, Stack, Typography, Chip, Tooltip } from '@mui/material';
import { formatEuroCents } from '@social-garden/utils/helpers.ts';
import { CreatorHasCampaignStatus } from '@social-garden/utils/types.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/pro-light-svg-icons/faBullhorn';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { ORANGE } from '@social-garden/utils/colors.ts';
import useCreatorHasCampaignStatus from '../../hooks/useCreatorHasCampaignStatus.ts';
import ProfilePicture from '../../components/ProfilePicture.tsx';
import PageTitle from '../../components/PageTitle.tsx';
import ReplaceUrlWithLink from '../../components/ReplaceUrlWithLink.tsx';
import { InstagramChannelInsightsData } from '../../components/InstagramChannelInsights.tsx';
import ChannelStatistics, {
  InstagramChannelStatistics,
  TiktokChannelStatistics,
  YoutubeChannelStatistics,
} from '../../components/ChannelStatistics.tsx';
import InstagramChannelInsightsDialog from '../../components/InstagramChannelInsightsDialog.tsx';

interface CreatorHasCampaignHeaderProps {
  creatorHasCampaign: {
    creator: {
      username: string;
      description?: string | null;
      isUnreliable: boolean;
      profilePicture?: {
        uri: string;
        hash?: string | null;
      } | null;
    };
    campaign: {
      id: string;
      name: string;
    };
    price: number;
    status: CreatorHasCampaignStatus;
    channel?: {
      channelable:
        | (InstagramChannelStatistics & InstagramChannelInsightsData)
        | TiktokChannelStatistics
        | YoutubeChannelStatistics;
    } | null;
  };
}

export default function CreatorHasCampaignHeader({
  creatorHasCampaign,
}: CreatorHasCampaignHeaderProps) {
  const {
    t,
    i18n: { resolvedLanguage, languages },
  } = useTranslation(['manager']);
  const { getCreatorHasCampaignStatusName, getCreatorHasCampaignStatusColor } =
    useCreatorHasCampaignStatus();

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        <Stack direction="row" spacing={0.5}>
          <Typography variant="subtitle2">
            {t('manager:creatorHasCampaign.campaign.name.label')}:
          </Typography>
          <Link
            href={`/campaign-details/${creatorHasCampaign.campaign.id}`}
            variant="subtitle2">
            {creatorHasCampaign.campaign.name}
          </Link>
        </Stack>
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 'auto',
        }}>
        <ProfilePicture
          username={creatorHasCampaign.creator.username}
          src={creatorHasCampaign.creator.profilePicture}
          size={170}
        />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 'grow',
        }}>
        <Stack spacing={0.5}>
          <Stack direction="row" alignItems="flex-start" spacing={2}>
            <PageTitle>{creatorHasCampaign.creator.username}</PageTitle>
            {creatorHasCampaign.creator.isUnreliable ? (
              <Tooltip
                title={t('manager:creator.isUnreliable')}
                arrow
                disableInteractive>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  size="2xl"
                  color={ORANGE['500']}
                />
              </Tooltip>
            ) : null}
          </Stack>
          {creatorHasCampaign.channel ? (
            <Stack alignItems="flex-start" spacing={2}>
              <ChannelStatistics
                data={creatorHasCampaign.channel.channelable}
              />
              {creatorHasCampaign.channel.channelable.__typename ===
              'InstagramChannel' ? (
                <InstagramChannelInsightsDialog
                  data={creatorHasCampaign.channel.channelable}
                />
              ) : null}
            </Stack>
          ) : (
            <Stack direction="row" spacing={1.5} alignItems="center">
              <FontAwesomeIcon icon={faBullhorn} size="xl" />
              <Typography fontSize={20}>
                {t('manager:channel.missing')}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 'auto',
        }}>
        <Stack
          sx={{
            alignItems: {
              xs: 'flex-start',
              sm: 'flex-end',
            },
          }}
          spacing={2}>
          <Chip
            label={getCreatorHasCampaignStatusName(creatorHasCampaign.status)}
            variant="filled"
            color={getCreatorHasCampaignStatusColor(creatorHasCampaign.status)}
          />
          <Typography variant="h4">
            {formatEuroCents(
              creatorHasCampaign.price,
              resolvedLanguage ?? languages[0],
            )}
          </Typography>
        </Stack>
      </Grid2>
      {creatorHasCampaign.creator.description ? (
        <Grid2 size={12}>
          <Stack maxWidth={600}>
            <Typography
              style={{
                whiteSpace: 'pre-line',
              }}>
              <ReplaceUrlWithLink>
                {creatorHasCampaign.creator.description}
              </ReplaceUrlWithLink>
            </Typography>
          </Stack>
        </Grid2>
      ) : null}
    </Grid2>
  );
}
