import { ApolloClient, ApolloProvider, from } from '@apollo/client';
import { ReactNode } from 'react';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { useSnackbar } from 'notistack';
import AuthToken from '../constants/AuthToken.ts';
import { cache, persistor } from '../utils/apolloInMemoryCache.ts';

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => !!error,
  },
});

export default function ApolloClientProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const { enqueueSnackbar } = useSnackbar();

  const httpLink = createUploadLink({
    uri: `${import.meta.env.VITE_APP_URL}/graphql`,
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        if (message === 'Unauthenticated.') {
          AuthToken.removeToken();
          persistor.purge();
          location.reload();
          return;
        }
        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
    }
  });

  const authLink = setContext((_, { headers }) => {
    const token = AuthToken.getToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : undefined,
      },
    };
  });

  return (
    <ApolloProvider
      client={
        new ApolloClient({
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'cache-and-network',
            },
          },
          link: from([authLink, errorLink, retryLink, httpLink]),
          cache,
        })
      }>
      {children}
    </ApolloProvider>
  );
}
