import { useTranslation } from 'react-i18next';
import { Link, Stack, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  assertUnreachable,
  formatEuroCents,
  formatPercentage,
} from '@social-garden/utils/helpers.ts';
import { CreatorHasCampaignStatus } from '@social-garden/utils/types.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation';
import { BLUE, GREEN, ORANGE } from '@social-garden/utils/colors.ts';
import { faCircleQuarterStroke } from '@fortawesome/pro-regular-svg-icons/faCircleQuarterStroke';
import ReportStatisticCard from '../../components/ReportStatisticCard.tsx';
import CampaignReportToolbar from './CampaignReportToolbar.tsx';
interface InstagramReelCampaignReportData {
  id: string;
  completedCount: number;
  followerCount: number;
  plays: number;
  likes: number;
  comments: number;
  shares: number;
  reach: number;
  engagementRate?: number | null;
  cpv?: number | null;
  price: number;
  creatorHasCampaignReports: {
    id: string;
    creatorHasCampaign: {
      id: string;
      status: CreatorHasCampaignStatus;
      creator: {
        username: string;
      };
    };
    followerCount: number;
    plays: number;
    likes: number;
    comments: number;
    shares: number;
    reach: number;
    engagementRate?: number | null;
    cpv?: number | null;
    price: number;
  }[];
}

interface InstagramReelCampaignReportProps {
  data: InstagramReelCampaignReportData;
}

export default function InstagramReelCampaignReport({
  data,
}: InstagramReelCampaignReportProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common']);

  return (
    <Stack spacing={4}>
      <Stack sx={{ gap: 2 }} direction="row" flexWrap="wrap">
        <ReportStatisticCard
          label={t('common:report.completed')}
          value={data.completedCount.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.followers')}
          value={data.followerCount.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.plays')}
          value={data.plays.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.likes')}
          value={data.likes.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.comments')}
          value={data.comments.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.shares')}
          value={data.shares.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.reach')}
          value={data.reach.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.engagementRate')}
          value={
            data.engagementRate
              ? formatPercentage(data.engagementRate, resolvedLanguage)
              : 'n/a'
          }
        />
        <ReportStatisticCard
          label={t('common:report.cpv')}
          value={data.cpv ? formatEuroCents(data.cpv, resolvedLanguage) : 'n/a'}
        />
        <ReportStatisticCard
          label={t('common:report.price')}
          value={formatEuroCents(data.price, resolvedLanguage)}
        />
      </Stack>
      <DataGrid
        rows={data.creatorHasCampaignReports}
        columns={[
          {
            flex: 1,
            minWidth: 150,
            field: 'creatorUsername',
            headerName: t('common:username'),
            valueGetter: (_, row) => row.creatorHasCampaign.creator.username,
            renderCell: ({ row }) => (
              <Link href={`/creator-has-campaign/${row.creatorHasCampaign.id}`}>
                {row.creatorHasCampaign.creator.username}
              </Link>
            ),
          },
          {
            width: 120,
            field: 'followerCount',
            headerName: t('common:report.followers'),
            valueGetter: (_, row) =>
              row.followerCount.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'plays',
            headerName: t('common:report.plays'),
            valueGetter: (_, row) => row.plays.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'likes',
            headerName: t('common:report.likes'),
            valueGetter: (_, row) => row.likes.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'comments',
            headerName: t('common:report.comments'),
            valueGetter: (_, row) =>
              row.comments.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'shares',
            headerName: t('common:report.shares'),
            valueGetter: (_, row) =>
              row.shares.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'reach',
            headerName: t('common:report.reach'),
            valueGetter: (_, row) => row.reach.toLocaleString(resolvedLanguage),
          },
          {
            width: 150,
            field: 'engagementRate',
            headerName: t('common:report.engagementRate'),
            valueGetter: (_, row) =>
              row.engagementRate
                ? formatPercentage(row.engagementRate, resolvedLanguage)
                : 'n/a',
          },
          {
            width: 120,
            field: 'cpv',
            headerName: t('common:report.cpv'),
            valueGetter: (_, row) =>
              row.cpv ? formatEuroCents(row.cpv, resolvedLanguage) : 'n/a',
          },
          {
            width: 120,
            field: 'price',
            headerName: t('common:report.price'),
            valueGetter: (_, row) =>
              formatEuroCents(row.price, resolvedLanguage),
          },
          {
            width: 50,
            field: 'status',
            align: 'center',
            disableExport: true,
            renderHeader: () => null,
            valueGetter: (_, row) => row.creatorHasCampaign.status,
            renderCell: ({ row }) => {
              switch (row.creatorHasCampaign.status) {
                case CreatorHasCampaignStatus.APPLIED:
                case CreatorHasCampaignStatus.DECLINED:
                case CreatorHasCampaignStatus.SUBMISSION_PENDING:
                case CreatorHasCampaignStatus.SUBMITTED:
                case CreatorHasCampaignStatus.FAILED:
                case CreatorHasCampaignStatus.CANCELLED:
                case CreatorHasCampaignStatus.SUBMISSION_ACCEPTED:
                  return (
                    <Tooltip
                      title={t('common:report.dataStatus.fetching')}
                      arrow
                      disableInteractive>
                      <FontAwesomeIcon
                        icon={faCircleQuarterStroke}
                        size="lg"
                        color={BLUE['500']}
                      />
                    </Tooltip>
                  );
                case CreatorHasCampaignStatus.REPORTING_COMPLETED:
                  return (
                    <Tooltip
                      title={t('common:report.dataStatus.completed')}
                      arrow
                      disableInteractive>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        size="lg"
                        color={GREEN['500']}
                      />
                    </Tooltip>
                  );
                case CreatorHasCampaignStatus.REPORTING_FAILED:
                  return (
                    <Tooltip
                      title={t('common:report.dataStatus.failed')}
                      arrow
                      disableInteractive>
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        size="lg"
                        color={ORANGE['500']}
                      />
                    </Tooltip>
                  );
                default:
                  assertUnreachable(row.creatorHasCampaign.status);
              }
            },
          },
        ]}
        disableColumnSorting={false}
        slots={{
          toolbar: CampaignReportToolbar,
          pagination: null,
        }}
      />
    </Stack>
  );
}
