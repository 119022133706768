import { Button, Stack, StackProps } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScrollPagination, {
  Paginator,
} from '../hooks/useInfiniteScrollPagination.ts';

interface StackListProps<T> extends Omit<StackProps, 'direction' | 'children'> {
  paginator: Paginator<T>;
  fetchMore(options: { variables: { page: number } }): Promise<any>;
  renderItem(item: T, index: number): ReactElement;
  loading?: boolean;
  ListEmptyComponent?: ReactElement | null;
}

export default function StackList<T>({
  paginator,
  fetchMore,
  renderItem,
  loading,
  ListEmptyComponent,
  ...rest
}: StackListProps<T>) {
  const { t } = useTranslation(['manager']);

  const { next } = useInfiniteScrollPagination({
    paginator,
    fetchMore,
  });

  if (paginator.data.length === 0 && ListEmptyComponent) {
    return <Stack {...rest}>{ListEmptyComponent}</Stack>;
  }

  return (
    <Stack {...rest}>
      {paginator.data.map((item, index) => renderItem(item, index))}
      {paginator.paginatorInfo.hasMorePages ? (
        <Stack textAlign="center" alignItems="center" justifyContent="center">
          <Button variant="text" disabled={loading} onClick={next}>
            {t('manager:list.loadMoreButton')}
          </Button>
        </Stack>
      ) : null}
    </Stack>
  );
}
