import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { HTMLAttributeAnchorTarget } from 'react';

interface BlogPostItemProps {
  sx?: SxProps<Theme>;
  href: string;
  image: string;
  title: string;
  content: string;
  target?: HTMLAttributeAnchorTarget;
}

export default function BlogPostItem({
  sx,
  image,
  title,
  content,
  href,
  target = '_blank',
}: BlogPostItemProps) {
  return (
    <Card sx={sx} variant="outlined">
      <CardActionArea href={href} target={target}>
        <CardMedia
          sx={{
            aspectRatio: 16 / 9,
          }}
          image={image}
        />
        <CardContent>
          <Typography
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              lineClamp: '2',
              WebkitLineClamp: '2',
              boxOrient: 'vertical',
              WebkitBoxOrient: 'vertical',
            }}
            variant="h6"
            mb={1}>
            {title}
          </Typography>
          <Typography
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              lineClamp: '4',
              WebkitLineClamp: '4',
              boxOrient: 'vertical',
              WebkitBoxOrient: 'vertical',
            }}>
            {content}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
