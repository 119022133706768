import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { updateManagerPasswordDocument } from '@social-garden/api/documents/manager.ts';
import {
  UpdatePasswordFieldValues,
  UpdatePasswordSchema,
} from '../../constants/ValidationSchema.ts';
import NewPasswordField from '../../components/NewPasswordField.tsx';
import ConfirmPasswordField from '../../components/ConfirmPasswordField.tsx';
import PasswordField from '../../components/PasswordField.tsx';
import Section from '../../components/Section.tsx';

export default function UpdatePassword() {
  const { t } = useTranslation(['manager']);
  const { enqueueSnackbar } = useSnackbar();
  const [updateManagerPassword, { loading }] = useMutation(
    updateManagerPasswordDocument,
  );
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<UpdatePasswordFieldValues>({
    resolver: zodResolver(UpdatePasswordSchema),
    mode: 'all',
  });

  const handleOnValid = useCallback(
    async ({ oldPassword, newPassword }: UpdatePasswordFieldValues) => {
      const result = await updateManagerPassword({
        variables: {
          input: {
            oldPassword,
            newPassword,
          },
        },
      });
      if (result.data?.updateManagerPassword) {
        enqueueSnackbar(t('manager:updatePassword.successText'), {
          variant: 'success',
        });
      }
    },
    [updateManagerPassword, enqueueSnackbar, t],
  );

  return (
    <form noValidate onSubmit={handleSubmit(handleOnValid)}>
      <Section header={t('manager:updatePassword.header')}>
        <Controller<UpdatePasswordFieldValues, 'oldPassword'>
          name="oldPassword"
          control={control}
          render={PasswordField}
        />
        <Controller<UpdatePasswordFieldValues, 'newPassword'>
          name="newPassword"
          control={control}
          render={NewPasswordField}
        />
        <Controller<UpdatePasswordFieldValues, 'newPasswordConfirm'>
          name="newPasswordConfirm"
          control={control}
          render={ConfirmPasswordField}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={!isValid || loading}>
          {t('manager:updatePassword.submitButton')}
        </Button>
      </Section>
    </form>
  );
}
