import {
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid2,
  Typography,
  Tooltip,
  Stack,
} from '@mui/material';
import { formatEuroCents } from '@social-garden/utils/helpers.ts';
import {
  CreatorHasCampaignStatus,
  Download,
} from '@social-garden/utils/types.ts';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/pro-light-svg-icons/faBullhorn';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { ORANGE } from '@social-garden/utils/colors.ts';
import ProfilePicture from './ProfilePicture.tsx';
import ChannelStatistics, {
  ChannelStatisticsData,
} from './ChannelStatistics.tsx';
import useCreatorHasCampaignStatus from '../hooks/useCreatorHasCampaignStatus.ts';

export interface CreatorHasCampaignListItemData {
  id: string;
  creator: {
    username: string;
    isUnreliable: boolean;
    profilePicture?: Download | null;
  };
  channel?: {
    channelable: ChannelStatisticsData;
  } | null;
  status: CreatorHasCampaignStatus;
  price: number;
  campaign: {
    statistics?: {
      reviewApplicationBefore?: string | null;
      reviewSubmissionBefore?: string | null;
    } | null;
  };
}

interface CreatorHasCampaignListItemProps {
  data: CreatorHasCampaignListItemData;
}

export default function CreatorHasCampaignListItem({
  data,
}: CreatorHasCampaignListItemProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['manager']);

  const { getCreatorHasCampaignStatusName, getCreatorHasCampaignStatusColor } =
    useCreatorHasCampaignStatus();

  return (
    <Card>
      <CardActionArea href={`/creator-has-campaign/${data.id}`}>
        <CardContent>
          <Grid2 container spacing={2} alignItems="center">
            <Grid2
              container
              alignItems="center"
              size={{
                xs: 12,
                sm: 12,
                md: 7,
                lg: 8,
              }}>
              <Grid2
                size={{
                  xs: 12,
                  sm: 'auto',
                }}>
                <ProfilePicture
                  src={data.creator.profilePicture}
                  username={data.creator.username}
                  size={100}
                />
              </Grid2>
              <Grid2 container size="grow" spacing={1}>
                <Grid2 size={12}>
                  <Typography
                    overflow="hidden"
                    textOverflow="ellipsis"
                    lineHeight="normal"
                    fontSize={22}>
                    {data.creator.username}
                  </Typography>
                </Grid2>
                <Grid2 size={12}>
                  {data.channel ? (
                    <ChannelStatistics data={data.channel.channelable} />
                  ) : (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <FontAwesomeIcon icon={faBullhorn} />
                      <Typography fontWeight={300} fontSize={18}>
                        {t('manager:channel.missing')}
                      </Typography>
                    </Stack>
                  )}
                </Grid2>
              </Grid2>
              {data.creator.isUnreliable ? (
                <Grid2 size="auto">
                  <Tooltip
                    title={t('manager:creator.isUnreliable')}
                    arrow
                    disableInteractive>
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      color={ORANGE['500']}
                      size="xl"
                    />
                  </Tooltip>
                </Grid2>
              ) : null}
            </Grid2>
            <Grid2
              sx={{
                textAlign: {
                  xs: 'left',
                  sm: 'left',
                  md: 'center',
                  lg: 'center',
                },
              }}
              container
              spacing={0.5}
              alignItems="center"
              size={{
                xs: 12,
                sm: 7,
                md: 3,
                lg: 2,
              }}>
              <Grid2 size={12}>
                <Chip
                  label={getCreatorHasCampaignStatusName(data.status)}
                  color={getCreatorHasCampaignStatusColor(data.status)}
                  variant="filled"
                />
              </Grid2>
              <Grid2 size={12}>
                {data.status === CreatorHasCampaignStatus.APPLIED &&
                data.campaign.statistics?.reviewApplicationBefore ? (
                  <Typography variant="caption">
                    {t('manager:campaign.reviewBefore', {
                      date: new Date(
                        data.campaign.statistics.reviewApplicationBefore,
                      ).toLocaleString(resolvedLanguage, {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      }),
                    })}
                  </Typography>
                ) : null}
                {data.status === CreatorHasCampaignStatus.SUBMITTED &&
                data.campaign.statistics?.reviewSubmissionBefore ? (
                  <Typography variant="caption">
                    {t('manager:campaign.reviewBefore', {
                      date: new Date(
                        data.campaign.statistics.reviewSubmissionBefore,
                      ).toLocaleString(resolvedLanguage, {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      }),
                    })}
                  </Typography>
                ) : null}
              </Grid2>
            </Grid2>
            <Grid2
              sx={{
                textAlign: {
                  xs: 'left',
                  sm: 'right',
                  md: 'right',
                  lg: 'right',
                },
              }}
              size={{
                xs: 12,
                sm: 5,
                md: 2,
                lg: 2,
              }}>
              <Typography variant="h5">
                {formatEuroCents(data.price, resolvedLanguage)}
              </Typography>
            </Grid2>
          </Grid2>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
