import { useTranslation } from 'react-i18next';
import { Link, Stack, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  assertUnreachable,
  formatEuroCents,
  formatPercentage,
} from '@social-garden/utils/helpers.ts';
import { CreatorHasCampaignStatus } from '@social-garden/utils/types.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuarterStroke } from '@fortawesome/pro-regular-svg-icons/faCircleQuarterStroke';
import { BLUE, GREEN, ORANGE } from '@social-garden/utils/colors.ts';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation';
import CampaignReportToolbar from './CampaignReportToolbar.tsx';
import ReportStatisticCard from '../../components/ReportStatisticCard.tsx';

interface YoutubeVideoCampaignReportData {
  id: string;
  completedCount: number;
  subscriberCount: number;
  views: number;
  likes: number;
  comments: number;
  favorites: number;
  engagementRate?: number | null;
  cpv?: number | null;
  price: number;
  creatorHasCampaignReports: {
    id: string;
    creatorHasCampaign: {
      id: string;
      status: CreatorHasCampaignStatus;
      creator: {
        username: string;
      };
    };
    subscriberCount: number;
    views: number;
    likes: number;
    comments: number;
    favorites: number;
    engagementRate?: number | null;
    cpv?: number | null;
    price: number;
  }[];
}

interface YoutubeVideoCampaignReportProps {
  data: YoutubeVideoCampaignReportData;
}

export default function YoutubeVideoCampaignReport({
  data,
}: YoutubeVideoCampaignReportProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common']);

  return (
    <Stack spacing={4}>
      <Stack sx={{ gap: 2 }} direction="row" flexWrap="wrap">
        <ReportStatisticCard
          label={t('common:report.completed')}
          value={data.completedCount.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.subscribers')}
          value={data.subscriberCount.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.views')}
          value={data.views.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.likes')}
          value={data.likes.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.comments')}
          value={data.comments.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.favorites')}
          value={data.favorites.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.engagementRate')}
          value={
            data.engagementRate
              ? formatPercentage(data.engagementRate, resolvedLanguage)
              : 'n/a'
          }
        />
        <ReportStatisticCard
          label={t('common:report.cpv')}
          value={data.cpv ? formatEuroCents(data.cpv, resolvedLanguage) : 'n/a'}
        />
        <ReportStatisticCard
          label={t('common:report.price')}
          value={formatEuroCents(data.price, resolvedLanguage)}
        />
      </Stack>
      <DataGrid
        rows={data.creatorHasCampaignReports}
        columns={[
          {
            flex: 1,
            minWidth: 150,
            field: 'creatorUsername',
            headerName: t('common:username'),
            valueGetter: (_, row) => row.creatorHasCampaign.creator.username,
            renderCell: ({ row }) => (
              <Link href={`/creator-has-campaign/${row.creatorHasCampaign.id}`}>
                {row.creatorHasCampaign.creator.username}
              </Link>
            ),
          },
          {
            width: 120,
            field: 'subscriberCount',
            headerName: t('common:report.subscribers'),
            valueGetter: (_, row) =>
              row.subscriberCount.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'views',
            headerName: t('common:report.views'),
            valueGetter: (_, row) => row.views.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'likes',
            headerName: t('common:report.likes'),
            valueGetter: (_, row) => row.likes.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'comments',
            headerName: t('common:report.comments'),
            valueGetter: (_, row) =>
              row.comments.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'favorites',
            headerName: t('common:report.favorites'),
            valueGetter: (_, row) =>
              row.favorites.toLocaleString(resolvedLanguage),
          },
          {
            width: 150,
            field: 'engagementRate',
            headerName: t('common:report.engagementRate'),
            valueGetter: (_, row) =>
              row.engagementRate
                ? formatPercentage(row.engagementRate, resolvedLanguage)
                : 'n/a',
          },
          {
            width: 120,
            field: 'cpv',
            headerName: t('common:report.cpv'),
            valueGetter: (_, row) =>
              row.cpv ? formatEuroCents(row.cpv, resolvedLanguage) : 'n/a',
          },
          {
            width: 120,
            field: 'price',
            headerName: t('common:report.price'),
            valueGetter: (_, row) =>
              formatEuroCents(row.price, resolvedLanguage),
          },
          {
            width: 50,
            field: 'status',
            align: 'center',
            disableExport: true,
            renderHeader: () => null,
            valueGetter: (_, row) => row.creatorHasCampaign.status,
            renderCell: ({ row }) => {
              switch (row.creatorHasCampaign.status) {
                case CreatorHasCampaignStatus.APPLIED:
                case CreatorHasCampaignStatus.DECLINED:
                case CreatorHasCampaignStatus.SUBMISSION_PENDING:
                case CreatorHasCampaignStatus.SUBMITTED:
                case CreatorHasCampaignStatus.FAILED:
                case CreatorHasCampaignStatus.CANCELLED:
                case CreatorHasCampaignStatus.SUBMISSION_ACCEPTED:
                  return (
                    <Tooltip
                      title={t('common:report.dataStatus.fetching')}
                      arrow
                      disableInteractive>
                      <FontAwesomeIcon
                        icon={faCircleQuarterStroke}
                        size="lg"
                        color={BLUE['500']}
                      />
                    </Tooltip>
                  );
                case CreatorHasCampaignStatus.REPORTING_COMPLETED:
                  return (
                    <Tooltip
                      title={t('common:report.dataStatus.completed')}
                      arrow
                      disableInteractive>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        size="lg"
                        color={GREEN['500']}
                      />
                    </Tooltip>
                  );
                case CreatorHasCampaignStatus.REPORTING_FAILED:
                  return (
                    <Tooltip
                      title={t('common:report.dataStatus.failed')}
                      arrow
                      disableInteractive>
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        size="lg"
                        color={ORANGE['500']}
                      />
                    </Tooltip>
                  );
                default:
                  assertUnreachable(row.creatorHasCampaign.status);
              }
            },
          },
        ]}
        disableColumnSorting={false}
        slots={{
          toolbar: CampaignReportToolbar,
          pagination: null,
        }}
      />
    </Stack>
  );
}
