import { useContext } from 'react';
import {
  ColorModeContext,
  ColorModeContextData,
} from '../contexts/colorModeContext.ts';

export default function useColorMode(): ColorModeContextData {
  const context = useContext(ColorModeContext);

  if (!context) {
    throw new Error('useColorMode must be used within an ColorModeProvider');
  }

  return context;
}
