import { Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface EmptyStateProps {
  icon: IconProp;
  text: string;
}

export default function EmptyState({ icon, text }: EmptyStateProps) {
  return (
    <Stack py={8} px={4} alignItems="center" justifyContent="center">
      <Stack
        spacing={4}
        alignItems="center"
        justifyContent="center"
        maxWidth={600}>
        <FontAwesomeIcon icon={icon} size="6x" />
        <Typography variant="h3" fontWeight={300} textAlign="center">
          {text}
        </Typography>
      </Stack>
    </Stack>
  );
}
