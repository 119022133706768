/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A datetime string with format `Y-m-d\TH:i:sP`, e.g. `2024-07-12T10:00:59+00:0`. */
  DateTime: { input: string; output: string };
  /** Input: string with format `Y-m-d`, e.g. `2018-05-23` Output: string (time always set to 23:59:59) with format `Y-m-d\TH:i:sP`, e.g. `2024-07-12T23:59:59+00:0`. */
  DueDate: { input: string; output: string };
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: { input: File; output: any };
};

export type AcceptCampaignApplicationInput = {
  campaignGiftCodes?: InputMaybe<Array<CampaignGiftCodeInput>>;
  creatorHasCampaignId: Scalars['ID']['input'];
  submitUntil: Scalars['DueDate']['input'];
};

export type AddInstagramChannelInput = {
  accessToken: Scalars['String']['input'];
  instagramBusinessAccountId: Scalars['String']['input'];
};

export type AddTiktokChannelInput = {
  code: Scalars['String']['input'];
};

export type AddYoutubeChannelInput = {
  code: Scalars['String']['input'];
};

export type AnswerInput = {
  answer: Scalars['String']['input'];
  questionId: Scalars['ID']['input'];
};

export type ApplyForCampaignInput = {
  answers?: InputMaybe<Array<AnswerInput>>;
  campaignId: Scalars['ID']['input'];
  deliveryAddress?: InputMaybe<DeliveryAddressInput>;
  dynamicMoneyRewardValue?: InputMaybe<Scalars['Int']['input']>;
  variableMoneyRewardValue?: InputMaybe<Scalars['Int']['input']>;
};

export type CampaignGiftCodeInput = {
  campaignGiftId: Scalars['ID']['input'];
  code: Scalars['String']['input'];
};

export type CampaignGiftInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: CampaignGiftType;
};

export enum CampaignGiftType {
  CODE = 'CODE',
  PARCEL = 'PARCEL',
}

export enum CampaignRewardType {
  DYNAMIC_MONEY_REWARD = 'DYNAMIC_MONEY_REWARD',
  FIXED_MONEY_REWARD = 'FIXED_MONEY_REWARD',
  NONE = 'NONE',
  VARIABLE_MONEY_REWARD = 'VARIABLE_MONEY_REWARD',
}

export enum CampaignStatus {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
}

export type CampaignTypeInput = {
  channelType: ChannelType;
  instagramCampaign?: InputMaybe<InstagramCampaignInput>;
  tiktokCampaign?: InputMaybe<TiktokCampaignInput>;
  youtubeCampaign?: InputMaybe<YoutubeCampaignInput>;
};

export type CampaignsInput = {
  brandIds: Array<Scalars['ID']['input']>;
  status: Array<CampaignStatus>;
};

export enum ChannelType {
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
}

export type CheckoutSubscriptionForCompanyInput = {
  cancelUrl: Scalars['String']['input'];
  lookupKey: SubscriptionPriceLookupKey;
  priceAmount: Scalars['Int']['input'];
  recurringInterval: RecurringIntervalPrice;
  subscriptionPlanId: Scalars['ID']['input'];
  successUrl: Scalars['String']['input'];
};

export type CreateBrandInput = {
  logo: Scalars['Upload']['input'];
  name: Scalars['String']['input'];
};

export type CreateCampaignInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  applyUntil: Scalars['DueDate']['input'];
  brandId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  fixedMoneyRewardValue?: InputMaybe<Scalars['Int']['input']>;
  gifts: Array<CampaignGiftInput>;
  headerImage: Scalars['Upload']['input'];
  includeContentFileOnSubmission: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  questions: Array<QuestionInput>;
  rewardType: CampaignRewardType;
  submitUntil: Scalars['DueDate']['input'];
  taskItems: Array<TaskItemInput>;
  type: CampaignTypeInput;
};

export type CreateMessageInput = {
  creatorHasCampaignId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export enum CreatorHasCampaignStatus {
  APPLIED = 'APPLIED',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
  FAILED = 'FAILED',
  REPORTING_COMPLETED = 'REPORTING_COMPLETED',
  REPORTING_FAILED = 'REPORTING_FAILED',
  SUBMISSION_ACCEPTED = 'SUBMISSION_ACCEPTED',
  SUBMISSION_PENDING = 'SUBMISSION_PENDING',
  SUBMITTED = 'SUBMITTED',
}

export enum CreatorNotificationChannel {
  EMAIL = 'EMAIL',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
}

export enum CreatorNotificationType {
  CAMPAIGN_APPLICATION_ACCEPTED = 'CAMPAIGN_APPLICATION_ACCEPTED',
  CAMPAIGN_APPLICATION_DECLINED = 'CAMPAIGN_APPLICATION_DECLINED',
  CAMPAIGN_GOAL_SUBMISSION_ACCEPTED = 'CAMPAIGN_GOAL_SUBMISSION_ACCEPTED',
  CAMPAIGN_GOAL_SUBMISSION_FAILED = 'CAMPAIGN_GOAL_SUBMISSION_FAILED',
  CAMPAIGN_GOAL_SUBMISSION_REMINDER = 'CAMPAIGN_GOAL_SUBMISSION_REMINDER',
  CAMPAIGN_GOAL_SUBMISSION_RETRIED = 'CAMPAIGN_GOAL_SUBMISSION_RETRIED',
  CONNECT_CHANNEL_REMINDER = 'CONNECT_CHANNEL_REMINDER',
  CREATOR_HAS_CAMPAIGN_CANCELLED = 'CREATOR_HAS_CAMPAIGN_CANCELLED',
  NEW_CAMPAIGN_AVAILABLE = 'NEW_CAMPAIGN_AVAILABLE',
  NEW_MESSAGE = 'NEW_MESSAGE',
}

export type CreatorsOfCampaignInput = {
  campaignId: Scalars['ID']['input'];
  status: Array<CreatorHasCampaignStatus>;
};

export type DeliveryAddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
  recipientName: Scalars['String']['input'];
  streetAddressLine1: Scalars['String']['input'];
  streetAddressLine2?: InputMaybe<Scalars['String']['input']>;
};

export type DiscoverCampaignsInput = {
  categoryIds: Array<Scalars['ID']['input']>;
  searchName?: InputMaybe<Scalars['String']['input']>;
};

export type ExtendCampaignDeadlinesInput = {
  applyUntil: Scalars['DueDate']['input'];
  id: Scalars['ID']['input'];
  submitUntil: Scalars['DueDate']['input'];
};

export type FailCampaignGoalSubmissionInput = {
  creatorHasCampaignId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type ForgotCreatorPasswordInput = {
  email: Scalars['String']['input'];
};

export type ForgotManagerPasswordInput = {
  email: Scalars['String']['input'];
};

export type FreeTextTaskInput = {
  description: Scalars['String']['input'];
};

export type HashtagTaskInput = {
  hashtags: Array<Scalars['String']['input']>;
};

export type InstagramCampaignInput = {
  contentType: InstagramContentType;
};

export enum InstagramContentType {
  POST = 'POST',
  REEL = 'REEL',
  STORY = 'STORY',
}

export enum InstagramMediaProductType {
  AD = 'AD',
  FEED = 'FEED',
  REELS = 'REELS',
  STORY = 'STORY',
}

export enum InstagramMediaType {
  CAROUSEL_ALBUM = 'CAROUSEL_ALBUM',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export type LoginCreatorInput = {
  deviceName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginManagerInput = {
  deviceName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export enum MessageSender {
  BRAND = 'BRAND',
  CREATOR = 'CREATOR',
}

export type MyCreatorHasCampaignsInput = {
  searchName?: InputMaybe<Scalars['String']['input']>;
  status: Array<CreatorHasCampaignStatus>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String']['input'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  COUNT = 'COUNT',
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  AVG = 'AVG',
  /** Amount of items. */
  COUNT = 'COUNT',
  /** Maximum. */
  MAX = 'MAX',
  /** Minimum. */
  MIN = 'MIN',
  /** Sum. */
  SUM = 'SUM',
}

export type QuestionInput = {
  question: Scalars['String']['input'];
};

export type RecoverCreatorPasswordInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RecoverManagerPasswordInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export enum RecurringIntervalPrice {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export type RegisterCompanyInput = {
  manager: RegisterManagerInput;
  name: Scalars['String']['input'];
};

export type RegisterCreatorInput = {
  acceptedNewsletter: Scalars['Boolean']['input'];
  confirmedPrivacyTerms: Scalars['Boolean']['input'];
  confirmedTermsOfService: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  olderThanSixteen: Scalars['Boolean']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type RegisterManagerInput = {
  acceptedNewsletter: Scalars['Boolean']['input'];
  confirmedPrivacyTerms: Scalars['Boolean']['input'];
  confirmedTermsOfService: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RequestVerificationOfNewCreatorEmailInput = {
  email: Scalars['String']['input'];
};

export type RequestVerificationOfNewManagerEmailInput = {
  email: Scalars['String']['input'];
};

export type ResendCreatorVerificationEmailInput = {
  email: Scalars['String']['input'];
};

export type ResendManagerVerificationEmailInput = {
  email: Scalars['String']['input'];
};

export type RetryCampaignGoalSubmissionInput = {
  creatorHasCampaignId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
  submitUntil: Scalars['DueDate']['input'];
};

export type SetCreatorExpoPushTokenInput = {
  token: Scalars['String']['input'];
};

export type SocialLoginCreatorInput = {
  provider: SocialLoginProvider;
  token: Scalars['String']['input'];
};

export enum SocialLoginProvider {
  APPLE = 'APPLE',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
}

export type SocialRegisterCreatorInput = {
  acceptedNewsletter: Scalars['Boolean']['input'];
  confirmedPrivacyTerms: Scalars['Boolean']['input'];
  confirmedTermsOfService: Scalars['Boolean']['input'];
  locale: Scalars['String']['input'];
  olderThanSixteen: Scalars['Boolean']['input'];
  provider: SocialLoginProvider;
  token: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  ASC = 'ASC',
  /** Sort records in descending order. */
  DESC = 'DESC',
}

export type StripeCustomerPortalUrlInput = {
  returnUrl: Scalars['String']['input'];
};

export type SubmitCampaignGoalInput = {
  contentFile?: InputMaybe<Scalars['Upload']['input']>;
  contentId: Scalars['String']['input'];
  creatorHasCampaignId: Scalars['ID']['input'];
};

export enum SubscriptionPriceLookupKey {
  LARGE_MONTHLY = 'LARGE_MONTHLY',
  LARGE_YEARLY = 'LARGE_YEARLY',
  MEDIUM_MONTHLY = 'MEDIUM_MONTHLY',
  MEDIUM_YEARLY = 'MEDIUM_YEARLY',
  SMALL_MONTHLY = 'SMALL_MONTHLY',
  SMALL_YEARLY = 'SMALL_YEARLY',
}

export type TaskItemInput = {
  freeTextTask?: InputMaybe<FreeTextTaskInput>;
  hashtagTask?: InputMaybe<HashtagTaskInput>;
  type: TaskItemType;
  websiteTask?: InputMaybe<WebsiteTaskInput>;
};

export enum TaskItemType {
  FREE_TEXT = 'FREE_TEXT',
  HASHTAG = 'HASHTAG',
  WEBSITE = 'WEBSITE',
}

export type TiktokCampaignInput = {
  contentType: TiktokContentType;
};

export enum TiktokContentType {
  VIDEO = 'VIDEO',
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  ONLY = 'ONLY',
  /** Return both trashed and non-trashed results. */
  WITH = 'WITH',
  /** Only return non-trashed results. */
  WITHOUT = 'WITHOUT',
}

export type UpdateAccessTokenForInstagramChannelInput = {
  accessToken: Scalars['String']['input'];
  instagramChannelId: Scalars['ID']['input'];
};

export type UpdateAccessTokenForTiktokChannelInput = {
  code: Scalars['String']['input'];
  tiktokChannelId: Scalars['ID']['input'];
};

export type UpdateAccessTokenForYoutubeChannelInput = {
  code: Scalars['String']['input'];
  youtubeChannelId: Scalars['ID']['input'];
};

export type UpdateBrandInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateBrandLogoInput = {
  id: Scalars['ID']['input'];
  logo: Scalars['Upload']['input'];
};

export type UpdateCampaignHeaderImageInput = {
  headerImage: Scalars['Upload']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateCampaignInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  applyUntil: Scalars['DueDate']['input'];
  brandId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  fixedMoneyRewardValue?: InputMaybe<Scalars['Int']['input']>;
  gifts: Array<CampaignGiftInput>;
  id: Scalars['ID']['input'];
  includeContentFileOnSubmission: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  questions: Array<QuestionInput>;
  rewardType: CampaignRewardType;
  submitUntil: Scalars['DueDate']['input'];
  taskItems: Array<TaskItemInput>;
  type: CampaignTypeInput;
};

export type UpdateCreatorLocaleInput = {
  locale: Scalars['String']['input'];
};

export type UpdateCreatorNotificationSettingInput = {
  channels: Array<CreatorNotificationChannel>;
  type: CreatorNotificationType;
};

export type UpdateCreatorPasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type UpdateCreatorProfileInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type UpdateManagerLocaleInput = {
  locale: Scalars['String']['input'];
};

export type UpdateManagerPasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type UpdateNewCampaignAvailableNotificationCategoriesInput = {
  categoryIds: Array<Scalars['ID']['input']>;
};

export type UploadCreatorProfilePictureInput = {
  profilePicture: Scalars['Upload']['input'];
};

export type VerifyCreatorEmailInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type VerifyManagerEmailInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type WebsiteTaskInput = {
  url: Scalars['String']['input'];
};

export type YoutubeCampaignInput = {
  contentType: YoutubeContentType;
};

export enum YoutubeContentType {
  VIDEO = 'VIDEO',
}

export type BrandsQueryVariables = Exact<{ [key: string]: never }>;

export type BrandsQuery = {
  __typename: 'Query';
  whoAmI:
    | { __typename: 'Creator' }
    | {
        __typename: 'Manager';
        id: string;
        company: {
          __typename: 'Company';
          id: string;
          brands: Array<{
            __typename: 'Brand';
            id: string;
            name: string;
            logo: { __typename: 'Download'; uri: string; hash?: string | null };
          }>;
        };
      };
};

export type BrandQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BrandQuery = {
  __typename: 'Query';
  brand: {
    __typename: 'Brand';
    id: string;
    name: string;
    logo: { __typename: 'Download'; uri: string; hash?: string | null };
  };
};

export type CreateBrandMutationVariables = Exact<{
  input: CreateBrandInput;
}>;

export type CreateBrandMutation = {
  __typename: 'Mutation';
  createBrand: {
    __typename: 'Brand';
    id: string;
    name: string;
    logo: { __typename: 'Download'; uri: string; hash?: string | null };
  };
};

export type UpdateBrandMutationVariables = Exact<{
  input: UpdateBrandInput;
}>;

export type UpdateBrandMutation = {
  __typename: 'Mutation';
  updateBrand: {
    __typename: 'Brand';
    id: string;
    name: string;
    logo: { __typename: 'Download'; uri: string; hash?: string | null };
  };
};

export type UpdateBrandLogoMutationVariables = Exact<{
  input: UpdateBrandLogoInput;
}>;

export type UpdateBrandLogoMutation = {
  __typename: 'Mutation';
  updateBrandLogo: {
    __typename: 'Brand';
    id: string;
    name: string;
    logo: { __typename: 'Download'; uri: string; hash?: string | null };
  };
};

export type CampaignQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CampaignQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    description: string;
    status: CampaignStatus;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    applyUntil: string;
    submitUntil: string;
    address?: string | null;
    includeContentFileOnSubmission: boolean;
    taskItems: Array<{
      __typename: 'TaskItem';
      id: string;
      type: TaskItemType;
      taskable:
        | { __typename: 'FreeTextTask'; id: string; description: string }
        | { __typename: 'HashtagTask'; id: string; hashtags: Array<string> }
        | { __typename: 'WebsiteTask'; id: string; url: string };
    }>;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    category: { __typename: 'Category'; id: string; name: string };
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      type: CampaignGiftType;
      name: string;
      description: string;
    }>;
    questions: Array<{ __typename: 'Question'; id: string; question: string }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type CampaignWithCreatorHasCampaignQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CampaignWithCreatorHasCampaignQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    description: string;
    status: CampaignStatus;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    applyUntil: string;
    submitUntil: string;
    address?: string | null;
    includeContentFileOnSubmission: boolean;
    creatorHasCampaign?: {
      __typename: 'CreatorHasCampaign';
      id: string;
      status: CreatorHasCampaignStatus;
      contentUrl?: string | null;
      reason?: string | null;
      submitUntil: string;
      rewardValue?: number | null;
      answers: Array<{
        __typename: 'Answer';
        id: string;
        answer: string;
        question: { __typename: 'Question'; id: string; question: string };
      }>;
    } | null;
    taskItems: Array<{
      __typename: 'TaskItem';
      id: string;
      type: TaskItemType;
      taskable:
        | { __typename: 'FreeTextTask'; id: string; description: string }
        | { __typename: 'HashtagTask'; id: string; hashtags: Array<string> }
        | { __typename: 'WebsiteTask'; id: string; url: string };
    }>;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    category: { __typename: 'Category'; id: string; name: string };
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      type: CampaignGiftType;
      name: string;
      description: string;
    }>;
    questions: Array<{ __typename: 'Question'; id: string; question: string }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type DynamicMoneyRewardValueQueryVariables = Exact<{
  campaignId: Scalars['ID']['input'];
}>;

export type DynamicMoneyRewardValueQuery = {
  __typename: 'Query';
  dynamicMoneyRewardValue: number;
};

export type CampaignYouAreApplyingForQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CampaignYouAreApplyingForQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    headerImage: { __typename: 'Download'; hash?: string | null; uri: string };
    questions: Array<{ __typename: 'Question'; id: string; question: string }>;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      type: CampaignGiftType;
    }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type CampaignGiftQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CampaignGiftQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      type: CampaignGiftType;
      name: string;
      description: string;
    }>;
    creatorHasCampaign?: {
      __typename: 'CreatorHasCampaign';
      id: string;
      campaignGiftCodes: Array<{
        __typename: 'CampaignGiftCode';
        id: string;
        code: string;
        gift: { __typename: 'CampaignGift'; id: string };
      }>;
    } | null;
  };
};

export type DiscoverCampaignsQueryVariables = Exact<{
  input: DiscoverCampaignsInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DiscoverCampaignsQuery = {
  __typename: 'Query';
  discoverCampaigns: {
    __typename: 'CampaignPaginator';
    data: Array<{
      __typename: 'Campaign';
      id: string;
      name: string;
      headerImage: {
        __typename: 'Download';
        uri: string;
        hash?: string | null;
      };
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
        logo: { __typename: 'Download'; uri: string; hash?: string | null };
      };
      category: { __typename: 'Category'; id: string; name: string };
      typeable:
        | {
            __typename: 'InstagramCampaign';
            id: string;
            channelType: ChannelType;
            instagramContentType: InstagramContentType;
          }
        | {
            __typename: 'TiktokCampaign';
            id: string;
            channelType: ChannelType;
            tiktokContentType: TiktokContentType;
          }
        | {
            __typename: 'YoutubeCampaign';
            id: string;
            channelType: ChannelType;
            youtubeContentType: YoutubeContentType;
          };
    }>;
    paginatorInfo: {
      __typename: 'PaginatorInfo';
      hasMorePages: boolean;
      currentPage: number;
    };
  };
};

export type CampaignWithStatisticsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CampaignWithStatisticsQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    description: string;
    status: CampaignStatus;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    applyUntil: string;
    submitUntil: string;
    address?: string | null;
    includeContentFileOnSubmission: boolean;
    statistics?: {
      __typename: 'CampaignStatistics';
      id: string;
      applications: number;
      applicationsAccepted: number;
      submissions: number;
      submissionsAccepted: number;
    } | null;
    taskItems: Array<{
      __typename: 'TaskItem';
      id: string;
      type: TaskItemType;
      taskable:
        | { __typename: 'FreeTextTask'; id: string; description: string }
        | { __typename: 'HashtagTask'; id: string; hashtags: Array<string> }
        | { __typename: 'WebsiteTask'; id: string; url: string };
    }>;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    category: { __typename: 'Category'; id: string; name: string };
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      type: CampaignGiftType;
      name: string;
      description: string;
    }>;
    questions: Array<{ __typename: 'Question'; id: string; question: string }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type CampaignBriefingQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CampaignBriefingQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    description: string;
    status: CampaignStatus;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    applyUntil: string;
    submitUntil: string;
    address?: string | null;
    includeContentFileOnSubmission: boolean;
    taskItems: Array<{
      __typename: 'TaskItem';
      id: string;
      type: TaskItemType;
      taskable:
        | { __typename: 'FreeTextTask'; id: string; description: string }
        | { __typename: 'HashtagTask'; id: string; hashtags: Array<string> }
        | { __typename: 'WebsiteTask'; id: string; url: string };
    }>;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    category: { __typename: 'Category'; id: string; name: string };
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      type: CampaignGiftType;
      name: string;
      description: string;
    }>;
    questions: Array<{ __typename: 'Question'; id: string; question: string }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type PublishCampaignMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PublishCampaignMutation = {
  __typename: 'Mutation';
  publishCampaign: {
    __typename: 'Campaign';
    id: string;
    status: CampaignStatus;
  };
};

export type DeleteCampaignMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCampaignMutation = {
  __typename: 'Mutation';
  deleteCampaign: string;
};

export type CampaignReportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CampaignReportQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    status: CampaignStatus;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    category: { __typename: 'Category'; id: string; name: string };
    reportable?:
      | {
          __typename: 'InstagramPostCampaignReport';
          id: string;
          completedCount: number;
          followerCount: number;
          views: number;
          saves: number;
          engagementRate?: number | null;
          cpv?: number | null;
          price: number;
          creatorHasCampaignReports: Array<{
            __typename: 'InstagramPostCreatorHasCampaignReport';
            id: string;
            followerCount: number;
            views: number;
            saves: number;
            engagementRate?: number | null;
            cpv?: number | null;
            price: number;
            creatorHasCampaign: {
              __typename: 'CreatorHasCampaign';
              id: string;
              status: CreatorHasCampaignStatus;
              creator: { __typename: 'Creator'; id: string; username: string };
            };
          }>;
        }
      | {
          __typename: 'InstagramReelCampaignReport';
          id: string;
          completedCount: number;
          followerCount: number;
          plays: number;
          likes: number;
          comments: number;
          shares: number;
          reach: number;
          engagementRate?: number | null;
          cpv?: number | null;
          price: number;
          creatorHasCampaignReports: Array<{
            __typename: 'InstagramReelCreatorHasCampaignReport';
            id: string;
            followerCount: number;
            plays: number;
            likes: number;
            comments: number;
            shares: number;
            reach: number;
            engagementRate?: number | null;
            cpv?: number | null;
            price: number;
            creatorHasCampaign: {
              __typename: 'CreatorHasCampaign';
              id: string;
              status: CreatorHasCampaignStatus;
              creator: { __typename: 'Creator'; id: string; username: string };
            };
          }>;
        }
      | {
          __typename: 'InstagramStoryCampaignReport';
          id: string;
          completedCount: number;
          followerCount: number;
          views: number;
          engagementRate?: number | null;
          cpv?: number | null;
          price: number;
          creatorHasCampaignReports: Array<{
            __typename: 'InstagramStoryCreatorHasCampaignReport';
            id: string;
            followerCount: number;
            views: number;
            engagementRate?: number | null;
            cpv?: number | null;
            price: number;
            creatorHasCampaign: {
              __typename: 'CreatorHasCampaign';
              id: string;
              status: CreatorHasCampaignStatus;
              creator: { __typename: 'Creator'; id: string; username: string };
            };
          }>;
        }
      | {
          __typename: 'TiktokVideoCampaignReport';
          id: string;
          completedCount: number;
          followerCount: number;
          views: number;
          likes: number;
          comments: number;
          shares: number;
          engagementRate?: number | null;
          cpv?: number | null;
          price: number;
          creatorHasCampaignReports: Array<{
            __typename: 'TiktokVideoCreatorHasCampaignReport';
            id: string;
            followerCount: number;
            views: number;
            likes: number;
            comments: number;
            shares: number;
            engagementRate?: number | null;
            cpv?: number | null;
            price: number;
            creatorHasCampaign: {
              __typename: 'CreatorHasCampaign';
              id: string;
              status: CreatorHasCampaignStatus;
              creator: { __typename: 'Creator'; id: string; username: string };
            };
          }>;
        }
      | {
          __typename: 'YoutubeVideoCampaignReport';
          id: string;
          completedCount: number;
          subscriberCount: number;
          views: number;
          likes: number;
          comments: number;
          favorites: number;
          engagementRate?: number | null;
          cpv?: number | null;
          price: number;
          creatorHasCampaignReports: Array<{
            __typename: 'YoutubeVideoCreatorHasCampaignReport';
            id: string;
            subscriberCount: number;
            views: number;
            likes: number;
            comments: number;
            favorites: number;
            engagementRate?: number | null;
            cpv?: number | null;
            price: number;
            creatorHasCampaign: {
              __typename: 'CreatorHasCampaign';
              id: string;
              status: CreatorHasCampaignStatus;
              creator: { __typename: 'Creator'; id: string; username: string };
            };
          }>;
        }
      | null;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type CampaignReportsQueryVariables = Exact<{
  brandIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CampaignReportsQuery = {
  __typename: 'Query';
  campaigns: {
    __typename: 'CampaignPaginator';
    data: Array<{
      __typename: 'Campaign';
      id: string;
      name: string;
      reportable?:
        | {
            __typename: 'InstagramPostCampaignReport';
            id: string;
            completedCount: number;
            followerCount: number;
            cpv?: number | null;
            price: number;
          }
        | {
            __typename: 'InstagramReelCampaignReport';
            id: string;
            completedCount: number;
            followerCount: number;
            cpv?: number | null;
            price: number;
          }
        | {
            __typename: 'InstagramStoryCampaignReport';
            id: string;
            completedCount: number;
            followerCount: number;
            cpv?: number | null;
            price: number;
          }
        | {
            __typename: 'TiktokVideoCampaignReport';
            id: string;
            completedCount: number;
            followerCount: number;
            cpv?: number | null;
            price: number;
          }
        | {
            __typename: 'YoutubeVideoCampaignReport';
            id: string;
            completedCount: number;
            subscriberCount: number;
            cpv?: number | null;
            price: number;
          }
        | null;
    }>;
    paginatorInfo: {
      __typename: 'PaginatorInfo';
      currentPage: number;
      hasMorePages: boolean;
    };
  };
};

export type CampaignsQueryVariables = Exact<{
  input: CampaignsInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CampaignsQuery = {
  __typename: 'Query';
  campaigns: {
    __typename: 'CampaignPaginator';
    data: Array<{
      __typename: 'Campaign';
      id: string;
      name: string;
      status: CampaignStatus;
      headerImage: {
        __typename: 'Download';
        uri: string;
        hash?: string | null;
      };
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
        logo: { __typename: 'Download'; uri: string; hash?: string | null };
      };
      statistics?: {
        __typename: 'CampaignStatistics';
        id: string;
        reviewApplicationBefore?: string | null;
        reviewSubmissionBefore?: string | null;
      } | null;
    }>;
    paginatorInfo: {
      __typename: 'PaginatorInfo';
      currentPage: number;
      hasMorePages: boolean;
    };
  };
};

export type DashboardCampaignsQueryVariables = Exact<{
  input: CampaignsInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DashboardCampaignsQuery = {
  __typename: 'Query';
  campaigns: {
    __typename: 'CampaignPaginator';
    data: Array<{
      __typename: 'Campaign';
      id: string;
      name: string;
      status: CampaignStatus;
      headerImage: {
        __typename: 'Download';
        uri: string;
        hash?: string | null;
      };
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
        logo: { __typename: 'Download'; uri: string; hash?: string | null };
      };
      statistics?: {
        __typename: 'CampaignStatistics';
        id: string;
        reviewApplicationBefore?: string | null;
        reviewSubmissionBefore?: string | null;
      } | null;
    }>;
    paginatorInfo: {
      __typename: 'PaginatorInfo';
      hasMorePages: boolean;
      currentPage: number;
    };
  };
};

export type CreateCampaignMutationVariables = Exact<{
  input: CreateCampaignInput;
}>;

export type CreateCampaignMutation = {
  __typename: 'Mutation';
  createCampaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    description: string;
    status: CampaignStatus;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    applyUntil: string;
    submitUntil: string;
    address?: string | null;
    includeContentFileOnSubmission: boolean;
    taskItems: Array<{
      __typename: 'TaskItem';
      id: string;
      type: TaskItemType;
      taskable:
        | { __typename: 'FreeTextTask'; id: string; description: string }
        | { __typename: 'HashtagTask'; id: string; hashtags: Array<string> }
        | { __typename: 'WebsiteTask'; id: string; url: string };
    }>;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    category: { __typename: 'Category'; id: string; name: string };
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      type: CampaignGiftType;
      name: string;
      description: string;
    }>;
    questions: Array<{ __typename: 'Question'; id: string; question: string }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type UpdateCampaignMutationVariables = Exact<{
  input: UpdateCampaignInput;
}>;

export type UpdateCampaignMutation = {
  __typename: 'Mutation';
  updateCampaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    description: string;
    status: CampaignStatus;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    applyUntil: string;
    submitUntil: string;
    address?: string | null;
    includeContentFileOnSubmission: boolean;
    taskItems: Array<{
      __typename: 'TaskItem';
      id: string;
      type: TaskItemType;
      taskable:
        | { __typename: 'FreeTextTask'; id: string; description: string }
        | { __typename: 'HashtagTask'; id: string; hashtags: Array<string> }
        | { __typename: 'WebsiteTask'; id: string; url: string };
    }>;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    category: { __typename: 'Category'; id: string; name: string };
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      type: CampaignGiftType;
      name: string;
      description: string;
    }>;
    questions: Array<{ __typename: 'Question'; id: string; question: string }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type UpdateCampaignHeaderImageMutationVariables = Exact<{
  input: UpdateCampaignHeaderImageInput;
}>;

export type UpdateCampaignHeaderImageMutation = {
  __typename: 'Mutation';
  updateCampaignHeaderImage: {
    __typename: 'Campaign';
    id: string;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
  };
};

export type CompleteCampaignMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CompleteCampaignMutation = {
  __typename: 'Mutation';
  completeCampaign: {
    __typename: 'Campaign';
    id: string;
    status: CampaignStatus;
  };
};

export type CampaignPreviewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CampaignPreviewQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    description: string;
    status: CampaignStatus;
    applyUntil: string;
    submitUntil: string;
    updatedAt: string;
    address?: string | null;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      logo: { __typename: 'Download'; uri: string; hash?: string | null };
    };
    category: { __typename: 'Category'; id: string; name: string };
    gifts: Array<{
      __typename: 'CampaignGift';
      id: string;
      name: string;
      type: CampaignGiftType;
    }>;
    taskItems: Array<{
      __typename: 'TaskItem';
      id: string;
      type: TaskItemType;
      taskable:
        | { __typename: 'FreeTextTask'; id: string; description: string }
        | { __typename: 'HashtagTask'; id: string; hashtags: Array<string> }
        | { __typename: 'WebsiteTask'; id: string; url: string };
    }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type InstagramPostQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type InstagramPostQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    name: string;
    applyUntil: string;
    rewardType: CampaignRewardType;
    fixedMoneyRewardValue?: number | null;
    headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
    gifts: Array<{ __typename: 'CampaignGift'; id: string; name: string }>;
    typeable:
      | {
          __typename: 'InstagramCampaign';
          id: string;
          channelType: ChannelType;
          instagramContentType: InstagramContentType;
        }
      | {
          __typename: 'TiktokCampaign';
          id: string;
          channelType: ChannelType;
          tiktokContentType: TiktokContentType;
        }
      | {
          __typename: 'YoutubeCampaign';
          id: string;
          channelType: ChannelType;
          youtubeContentType: YoutubeContentType;
        };
  };
};

export type DiscoverCampaignsForSitemapQueryVariables = Exact<{
  input: DiscoverCampaignsInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DiscoverCampaignsForSitemapQuery = {
  __typename: 'Query';
  discoverCampaigns: {
    __typename: 'CampaignPaginator';
    data: Array<{ __typename: 'Campaign'; id: string; updatedAt: string }>;
  };
};

export type DiscoverCampaignsForInstagramPostQueryVariables = Exact<{
  input: DiscoverCampaignsInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DiscoverCampaignsForInstagramPostQuery = {
  __typename: 'Query';
  discoverCampaigns: {
    __typename: 'CampaignPaginator';
    data: Array<{ __typename: 'Campaign'; id: string; description: string }>;
  };
};

export type PublicDiscoverCampaignsQueryVariables = Exact<{
  input: DiscoverCampaignsInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type PublicDiscoverCampaignsQuery = {
  __typename: 'Query';
  discoverCampaigns: {
    __typename: 'CampaignPaginator';
    data: Array<{
      __typename: 'Campaign';
      id: string;
      name: string;
      headerImage: {
        __typename: 'Download';
        uri: string;
        hash?: string | null;
      };
      brand: {
        __typename: 'Brand';
        id: string;
        name: string;
        logo: { __typename: 'Download'; uri: string; hash?: string | null };
      };
      category: { __typename: 'Category'; id: string; name: string };
      typeable:
        | {
            __typename: 'InstagramCampaign';
            id: string;
            channelType: ChannelType;
            instagramContentType: InstagramContentType;
          }
        | {
            __typename: 'TiktokCampaign';
            id: string;
            channelType: ChannelType;
            tiktokContentType: TiktokContentType;
          }
        | {
            __typename: 'YoutubeCampaign';
            id: string;
            channelType: ChannelType;
            youtubeContentType: YoutubeContentType;
          };
    }>;
  };
};

export type CategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type CategoriesQuery = {
  __typename: 'Query';
  categories: Array<{ __typename: 'Category'; id: string; name: string }>;
};

export type ChannelsQueryVariables = Exact<{ [key: string]: never }>;

export type ChannelsQuery = {
  __typename: 'Query';
  whoAmI:
    | {
        __typename: 'Creator';
        id: string;
        channels: Array<{
          __typename: 'Channel';
          id: string;
          isAccessTokenValid: boolean;
          channelableType: ChannelType;
          channelable:
            | {
                __typename: 'InstagramChannel';
                id: string;
                username: string;
                url: string;
                followersCount: number;
                averageEngagementRate?: number | null;
                averageReach?: number | null;
                mediaCount: number;
              }
            | {
                __typename: 'TiktokChannel';
                id: string;
                displayName: string;
                url: string;
                followerCount: number;
                averageEngagementRate?: number | null;
                averageReach?: number | null;
                videoCount: number;
              }
            | {
                __typename: 'YoutubeChannel';
                id: string;
                title: string;
                url: string;
                subscriberCount: number;
                averageEngagementRate?: number | null;
                averageReach?: number | null;
                videoCount: number;
              };
        }>;
      }
    | { __typename: 'Manager' };
};

export type DeleteChannelMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteChannelMutation = {
  __typename: 'Mutation';
  deleteChannel: {
    __typename: 'Creator';
    id: string;
    channels: Array<{
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    }>;
  };
};

export type StripeCustomerPortalUrlQueryVariables = Exact<{
  input: StripeCustomerPortalUrlInput;
}>;

export type StripeCustomerPortalUrlQuery = {
  __typename: 'Query';
  stripeCustomerPortalUrl: string;
};

export type RegisterCompanyMutationVariables = Exact<{
  input: RegisterCompanyInput;
}>;

export type RegisterCompanyMutation = {
  __typename: 'Mutation';
  registerCompany: { __typename: 'Company'; id: string; name: string };
};

export type CheckoutSubscriptionForCompanyMutationVariables = Exact<{
  input: CheckoutSubscriptionForCompanyInput;
}>;

export type CheckoutSubscriptionForCompanyMutation = {
  __typename: 'Mutation';
  checkoutSubscriptionForCompany: string;
};

export type RegisterCreatorMutationVariables = Exact<{
  input: RegisterCreatorInput;
}>;

export type RegisterCreatorMutation = {
  __typename: 'Mutation';
  registerCreator: {
    __typename: 'Creator';
    locale: string;
    hasSSOLogin: boolean;
    id: string;
    username: string;
    description?: string | null;
    profilePicture?: {
      __typename: 'Download';
      uri: string;
      hash?: string | null;
    } | null;
  };
};

export type SocialRegisterCreatorMutationVariables = Exact<{
  input: SocialRegisterCreatorInput;
}>;

export type SocialRegisterCreatorMutation = {
  __typename: 'Mutation';
  socialRegisterCreator: string;
};

export type DeactivateCreatorMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeactivateCreatorMutation = {
  __typename: 'Mutation';
  deactivateCreator: string;
};

export type ForgotCreatorPasswordMutationVariables = Exact<{
  input: ForgotCreatorPasswordInput;
}>;

export type ForgotCreatorPasswordMutation = {
  __typename: 'Mutation';
  forgotCreatorPassword: string;
};

export type CreatorProfileQueryVariables = Exact<{ [key: string]: never }>;

export type CreatorProfileQuery = {
  __typename: 'Query';
  whoAmI:
    | {
        __typename: 'Creator';
        locale: string;
        id: string;
        username: string;
        description?: string | null;
        channels: Array<{
          __typename: 'Channel';
          id: string;
          isAccessTokenValid: boolean;
          channelableType: ChannelType;
          channelable:
            | {
                __typename: 'InstagramChannel';
                id: string;
                username: string;
                url: string;
                followersCount: number;
                averageEngagementRate?: number | null;
                averageReach?: number | null;
                mediaCount: number;
              }
            | {
                __typename: 'TiktokChannel';
                id: string;
                displayName: string;
                url: string;
                followerCount: number;
                averageEngagementRate?: number | null;
                averageReach?: number | null;
                videoCount: number;
              }
            | {
                __typename: 'YoutubeChannel';
                id: string;
                title: string;
                url: string;
                subscriberCount: number;
                averageEngagementRate?: number | null;
                averageReach?: number | null;
                videoCount: number;
              };
        }>;
        profilePicture?: {
          __typename: 'Download';
          uri: string;
          hash?: string | null;
        } | null;
      }
    | { __typename: 'Manager' };
};

export type RecoverCreatorPasswordMutationVariables = Exact<{
  input: RecoverCreatorPasswordInput;
}>;

export type RecoverCreatorPasswordMutation = {
  __typename: 'Mutation';
  recoverCreatorPassword: string;
};

export type VerifyCreatorEmailMutationVariables = Exact<{
  input: VerifyCreatorEmailInput;
}>;

export type VerifyCreatorEmailMutation = {
  __typename: 'Mutation';
  verifyCreatorEmail: string;
};

export type ResendCreatorVerificationEmailMutationVariables = Exact<{
  input: ResendCreatorVerificationEmailInput;
}>;

export type ResendCreatorVerificationEmailMutation = {
  __typename: 'Mutation';
  resendCreatorVerificationEmail: string;
};

export type RequestVerificationOfNewCreatorEmailMutationVariables = Exact<{
  input: RequestVerificationOfNewCreatorEmailInput;
}>;

export type RequestVerificationOfNewCreatorEmailMutation = {
  __typename: 'Mutation';
  requestVerificationOfNewCreatorEmail: string;
};

export type VerifyAndUpdateCreatorEmailMutationVariables = Exact<{
  input: VerifyCreatorEmailInput;
}>;

export type VerifyAndUpdateCreatorEmailMutation = {
  __typename: 'Mutation';
  verifyAndUpdateCreatorEmail: { __typename: 'Creator'; id: string };
};

export type UpdateCreatorProfileMutationVariables = Exact<{
  input: UpdateCreatorProfileInput;
}>;

export type UpdateCreatorProfileMutation = {
  __typename: 'Mutation';
  updateCreatorProfile: {
    __typename: 'Creator';
    id: string;
    username: string;
    description?: string | null;
    profilePicture?: {
      __typename: 'Download';
      uri: string;
      hash?: string | null;
    } | null;
  };
};

export type UpdateCreatorLocaleMutationVariables = Exact<{
  input: UpdateCreatorLocaleInput;
}>;

export type UpdateCreatorLocaleMutation = {
  __typename: 'Mutation';
  updateCreatorLocale: { __typename: 'Creator'; id: string; locale: string };
};

export type UpdateCreatorPasswordMutationVariables = Exact<{
  input: UpdateCreatorPasswordInput;
}>;

export type UpdateCreatorPasswordMutation = {
  __typename: 'Mutation';
  updateCreatorPassword: string;
};

export type UploadCreatorProfilePictureMutationVariables = Exact<{
  input: UploadCreatorProfilePictureInput;
}>;

export type UploadCreatorProfilePictureMutation = {
  __typename: 'Mutation';
  uploadCreatorProfilePicture: {
    __typename: 'Creator';
    id: string;
    profilePicture?: {
      __typename: 'Download';
      uri: string;
      hash?: string | null;
    } | null;
  };
};

export type CreatorContextQueryVariables = Exact<{ [key: string]: never }>;

export type CreatorContextQuery = {
  __typename: 'Query';
  whoAmI:
    | {
        __typename: 'Creator';
        locale: string;
        hasSSOLogin: boolean;
        id: string;
        username: string;
        description?: string | null;
        channels: Array<{
          __typename: 'Channel';
          id: string;
          isAccessTokenValid: boolean;
          channelableType: ChannelType;
          channelable:
            | {
                __typename: 'InstagramChannel';
                id: string;
                username: string;
                url: string;
                followersCount: number;
                averageEngagementRate?: number | null;
                averageReach?: number | null;
                mediaCount: number;
              }
            | {
                __typename: 'TiktokChannel';
                id: string;
                displayName: string;
                url: string;
                followerCount: number;
                averageEngagementRate?: number | null;
                averageReach?: number | null;
                videoCount: number;
              }
            | {
                __typename: 'YoutubeChannel';
                id: string;
                title: string;
                url: string;
                subscriberCount: number;
                averageEngagementRate?: number | null;
                averageReach?: number | null;
                videoCount: number;
              };
        }>;
        profilePicture?: {
          __typename: 'Download';
          uri: string;
          hash?: string | null;
        } | null;
      }
    | { __typename: 'Manager' };
};

export type LoginCreatorMutationVariables = Exact<{
  input: LoginCreatorInput;
}>;

export type LoginCreatorMutation = {
  __typename: 'Mutation';
  loginCreator: string;
};

export type LogoutCreatorMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutCreatorMutation = {
  __typename: 'Mutation';
  logoutCreator: string;
};

export type SocialLoginCreatorMutationVariables = Exact<{
  input: SocialLoginCreatorInput;
}>;

export type SocialLoginCreatorMutation = {
  __typename: 'Mutation';
  socialLoginCreator: string;
};

export type SetCreatorExpoPushTokenMutationVariables = Exact<{
  input: SetCreatorExpoPushTokenInput;
}>;

export type SetCreatorExpoPushTokenMutation = {
  __typename: 'Mutation';
  setCreatorExpoPushToken: string;
};

export type CreatorsQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CreatorsQuery = {
  __typename: 'Query';
  creators: {
    __typename: 'CreatorPaginator';
    data: Array<{ __typename: 'Creator'; id: string; username: string }>;
    paginatorInfo: { __typename: 'PaginatorInfo'; total: number };
  };
};

export type ImpersonateCreatorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ImpersonateCreatorMutation = {
  __typename: 'Mutation';
  impersonateCreator: string;
};

export type ApplyForCampaignMutationVariables = Exact<{
  input: ApplyForCampaignInput;
}>;

export type ApplyForCampaignMutation = {
  __typename: 'Mutation';
  applyForCampaign: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
    rewardValue?: number | null;
    campaign: {
      __typename: 'Campaign';
      id: string;
      creatorHasCampaign?: {
        __typename: 'CreatorHasCampaign';
        id: string;
      } | null;
    };
  };
};

export type UnapplyFromCampaignMutationVariables = Exact<{
  creatorHasCampaignId: Scalars['ID']['input'];
}>;

export type UnapplyFromCampaignMutation = {
  __typename: 'Mutation';
  unapplyFromCampaign: string;
};

export type MyCreatorHasCampaignsQueryVariables = Exact<{
  input: MyCreatorHasCampaignsInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type MyCreatorHasCampaignsQuery = {
  __typename: 'Query';
  myCreatorHasCampaigns: {
    __typename: 'CreatorHasCampaignPaginator';
    data: Array<{
      __typename: 'CreatorHasCampaign';
      id: string;
      status: CreatorHasCampaignStatus;
      campaign: {
        __typename: 'Campaign';
        id: string;
        name: string;
        headerImage: {
          __typename: 'Download';
          uri: string;
          hash?: string | null;
        };
      };
    }>;
    paginatorInfo: {
      __typename: 'PaginatorInfo';
      hasMorePages: boolean;
      currentPage: number;
    };
  };
};

export type SubmitCampaignGoalMutationVariables = Exact<{
  input: SubmitCampaignGoalInput;
}>;

export type SubmitCampaignGoalMutation = {
  __typename: 'Mutation';
  submitCampaignGoal: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
    contentUrl?: string | null;
  };
};

export type CreatorHasCampaignChannelWithCampaignTypeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CreatorHasCampaignChannelWithCampaignTypeQuery = {
  __typename: 'Query';
  creatorHasCampaign: {
    __typename: 'CreatorHasCampaign';
    id: string;
    channel?: {
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    } | null;
    campaign: {
      __typename: 'Campaign';
      id: string;
      includeContentFileOnSubmission: boolean;
      typeable:
        | {
            __typename: 'InstagramCampaign';
            id: string;
            channelType: ChannelType;
            instagramContentType: InstagramContentType;
          }
        | {
            __typename: 'TiktokCampaign';
            id: string;
            channelType: ChannelType;
            tiktokContentType: TiktokContentType;
          }
        | {
            __typename: 'YoutubeCampaign';
            id: string;
            channelType: ChannelType;
            youtubeContentType: YoutubeContentType;
          };
    };
  };
};

export type CreatorsOfCampaignQueryVariables = Exact<{
  input: CreatorsOfCampaignInput;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CreatorsOfCampaignQuery = {
  __typename: 'Query';
  creatorsOfCampaign: {
    __typename: 'CreatorHasCampaignPaginator';
    data: Array<{
      __typename: 'CreatorHasCampaign';
      id: string;
      status: CreatorHasCampaignStatus;
      price: number;
      channel?: {
        __typename: 'Channel';
        id: string;
        channelableType: ChannelType;
        channelable:
          | {
              __typename: 'InstagramChannel';
              id: string;
              username: string;
              url: string;
              followersCount: number;
              averageEngagementRate?: number | null;
              averageReach?: number | null;
              mediaCount: number;
            }
          | {
              __typename: 'TiktokChannel';
              id: string;
              displayName: string;
              url: string;
              followerCount: number;
              averageEngagementRate?: number | null;
              averageReach?: number | null;
              videoCount: number;
            }
          | {
              __typename: 'YoutubeChannel';
              id: string;
              title: string;
              url: string;
              subscriberCount: number;
              averageEngagementRate?: number | null;
              averageReach?: number | null;
              videoCount: number;
            };
      } | null;
      creator: {
        __typename: 'Creator';
        id: string;
        username: string;
        isUnreliable: boolean;
        profilePicture?: {
          __typename: 'Download';
          uri: string;
          hash?: string | null;
        } | null;
      };
      campaign: {
        __typename: 'Campaign';
        id: string;
        statistics?: {
          __typename: 'CampaignStatistics';
          id: string;
          reviewApplicationBefore?: string | null;
          reviewSubmissionBefore?: string | null;
        } | null;
      };
    }>;
    paginatorInfo: {
      __typename: 'PaginatorInfo';
      currentPage: number;
      hasMorePages: boolean;
    };
  };
};

export type CreatorHasCampaignQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CreatorHasCampaignQuery = {
  __typename: 'Query';
  creatorHasCampaign: {
    __typename: 'CreatorHasCampaign';
    id: string;
    price: number;
    status: CreatorHasCampaignStatus;
    reason?: string | null;
    contentUrl?: string | null;
    submitUntil: string;
    rewardValue?: number | null;
    channel?: {
      __typename: 'Channel';
      id: string;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
            ageFollowerDemographics: Array<{
              __typename: 'AgeFollowerDemoGraphics';
              id: string;
              age: string;
              percentage: number;
            }>;
            countryFollowerDemographics: Array<{
              __typename: 'CountryFollowerDemoGraphics';
              id: string;
              country: string;
              percentage: number;
            }>;
            genderFollowerDemographics: Array<{
              __typename: 'GenderFollowerDemoGraphics';
              id: string;
              gender: string;
              percentage: number;
            }>;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    } | null;
    campaignGiftCodes: Array<{
      __typename: 'CampaignGiftCode';
      id: string;
      code: string;
      gift: {
        __typename: 'CampaignGift';
        id: string;
        type: CampaignGiftType;
        name: string;
      };
    }>;
    answers: Array<{
      __typename: 'Answer';
      id: string;
      answer: string;
      question: { __typename: 'Question'; id: string; question: string };
    }>;
    deliveryAddress?: {
      __typename: 'DeliveryAddress';
      id: string;
      recipientName: string;
      streetAddressLine1: string;
      streetAddressLine2?: string | null;
      postcode: string;
      city: string;
      country: string;
    } | null;
    creator: {
      __typename: 'Creator';
      id: string;
      username: string;
      description?: string | null;
      isUnreliable: boolean;
      profilePicture?: {
        __typename: 'Download';
        uri: string;
        hash?: string | null;
      } | null;
    };
    campaign: {
      __typename: 'Campaign';
      id: string;
      name: string;
      includeContentFileOnSubmission: boolean;
      submitUntil: string;
      gifts: Array<{
        __typename: 'CampaignGift';
        id: string;
        type: CampaignGiftType;
        name: string;
      }>;
      questions: Array<{
        __typename: 'Question';
        id: string;
        question: string;
      }>;
      typeable:
        | {
            __typename: 'InstagramCampaign';
            id: string;
            channelType: ChannelType;
            instagramContentType: InstagramContentType;
          }
        | {
            __typename: 'TiktokCampaign';
            id: string;
            channelType: ChannelType;
            tiktokContentType: TiktokContentType;
          }
        | {
            __typename: 'YoutubeCampaign';
            id: string;
            channelType: ChannelType;
            youtubeContentType: YoutubeContentType;
          };
    };
  };
};

export type AcceptCampaignApplicationMutationVariables = Exact<{
  input: AcceptCampaignApplicationInput;
}>;

export type AcceptCampaignApplicationMutation = {
  __typename: 'Mutation';
  acceptCampaignApplication: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
    submitUntil: string;
    campaignGiftCodes: Array<{
      __typename: 'CampaignGiftCode';
      id: string;
      code: string;
      gift: {
        __typename: 'CampaignGift';
        id: string;
        type: CampaignGiftType;
        name: string;
        description: string;
      };
    }>;
  };
};

export type DeclineCampaignApplicationMutationVariables = Exact<{
  creatorHasCampaignId: Scalars['ID']['input'];
}>;

export type DeclineCampaignApplicationMutation = {
  __typename: 'Mutation';
  declineCampaignApplication: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
  };
};

export type AcceptCampaignGoalSubmissionMutationVariables = Exact<{
  creatorHasCampaignId: Scalars['ID']['input'];
}>;

export type AcceptCampaignGoalSubmissionMutation = {
  __typename: 'Mutation';
  acceptCampaignGoalSubmission: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
  };
};

export type RetryCampaignGoalSubmissionMutationVariables = Exact<{
  input: RetryCampaignGoalSubmissionInput;
}>;

export type RetryCampaignGoalSubmissionMutation = {
  __typename: 'Mutation';
  retryCampaignGoalSubmission: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
    submitUntil: string;
    reason?: string | null;
  };
};

export type FailCampaignGoalSubmissionMutationVariables = Exact<{
  input: FailCampaignGoalSubmissionInput;
}>;

export type FailCampaignGoalSubmissionMutation = {
  __typename: 'Mutation';
  failCampaignGoalSubmission: {
    __typename: 'CreatorHasCampaign';
    id: string;
    status: CreatorHasCampaignStatus;
    reason?: string | null;
  };
};

export type CreatorHasCampaignContentFileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CreatorHasCampaignContentFileQuery = {
  __typename: 'Query';
  creatorHasCampaignContentFile: {
    __typename: 'Download';
    uri: string;
    hash?: string | null;
  };
};

export type ExtendCampaignDeadlinesMutationVariables = Exact<{
  input: ExtendCampaignDeadlinesInput;
}>;

export type ExtendCampaignDeadlinesMutation = {
  __typename: 'Mutation';
  extendCampaignDeadlines: {
    __typename: 'Campaign';
    id: string;
    applyUntil: string;
    submitUntil: string;
  };
};

export type CreatorNotificationSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CreatorNotificationSettingsQuery = {
  __typename: 'Query';
  whoAmI:
    | {
        __typename: 'Creator';
        id: string;
        notificationSettings: {
          __typename: 'CreatorNotificationSettings';
          id: string;
          newMessage: Array<CreatorNotificationChannel>;
          newCampaignAvailable: Array<CreatorNotificationChannel>;
          campaignApplicationAccepted: Array<CreatorNotificationChannel>;
          campaignApplicationDeclined: Array<CreatorNotificationChannel>;
          campaignGoalSubmissionAccepted: Array<CreatorNotificationChannel>;
          campaignGoalSubmissionFailed: Array<CreatorNotificationChannel>;
          campaignGoalSubmissionReminder: Array<CreatorNotificationChannel>;
          campaignGoalSubmissionRetried: Array<CreatorNotificationChannel>;
          creatorHasCampaignCancelled: Array<CreatorNotificationChannel>;
          connectChannelReminder: Array<CreatorNotificationChannel>;
          newCampaignAvailableCategories: Array<{
            __typename: 'Category';
            id: string;
            name: string;
          }>;
        };
      }
    | { __typename: 'Manager' };
};

export type UpdateCreatorNotificationSettingMutationVariables = Exact<{
  input: UpdateCreatorNotificationSettingInput;
}>;

export type UpdateCreatorNotificationSettingMutation = {
  __typename: 'Mutation';
  updateCreatorNotificationSetting: {
    __typename: 'CreatorNotificationSettings';
    id: string;
    newMessage: Array<CreatorNotificationChannel>;
    newCampaignAvailable: Array<CreatorNotificationChannel>;
    campaignApplicationAccepted: Array<CreatorNotificationChannel>;
    campaignApplicationDeclined: Array<CreatorNotificationChannel>;
    campaignGoalSubmissionAccepted: Array<CreatorNotificationChannel>;
    campaignGoalSubmissionFailed: Array<CreatorNotificationChannel>;
    campaignGoalSubmissionReminder: Array<CreatorNotificationChannel>;
    campaignGoalSubmissionRetried: Array<CreatorNotificationChannel>;
    creatorHasCampaignCancelled: Array<CreatorNotificationChannel>;
    connectChannelReminder: Array<CreatorNotificationChannel>;
    newCampaignAvailableCategories: Array<{
      __typename: 'Category';
      id: string;
      name: string;
    }>;
  };
};

export type UpdateNewCampaignAvailableNotificationCategoriesMutationVariables =
  Exact<{
    input: UpdateNewCampaignAvailableNotificationCategoriesInput;
  }>;

export type UpdateNewCampaignAvailableNotificationCategoriesMutation = {
  __typename: 'Mutation';
  updateNewCampaignAvailableNotificationCategories: {
    __typename: 'CreatorNotificationSettings';
    id: string;
    newCampaignAvailableCategories: Array<{
      __typename: 'Category';
      id: string;
      name: string;
    }>;
  };
};

export type LatestInstagramMediaQueryVariables = Exact<{
  instagramChannelId: Scalars['ID']['input'];
}>;

export type LatestInstagramMediaQuery = {
  __typename: 'Query';
  latestInstagramMedia: Array<{
    __typename: 'InstagramMedia';
    id: string;
    mediaType: InstagramMediaType;
    mediaProductType: InstagramMediaProductType;
    thumbnailUrl?: string | null;
    mediaUrl?: string | null;
    caption?: string | null;
    timestamp: string;
  }>;
};

export type LatestInstagramStoriesQueryVariables = Exact<{
  instagramChannelId: Scalars['ID']['input'];
}>;

export type LatestInstagramStoriesQuery = {
  __typename: 'Query';
  latestInstagramStories: Array<{
    __typename: 'InstagramMedia';
    id: string;
    mediaType: InstagramMediaType;
    mediaProductType: InstagramMediaProductType;
    thumbnailUrl?: string | null;
    mediaUrl?: string | null;
    caption?: string | null;
    timestamp: string;
  }>;
};

export type AddInstagramChannelMutationVariables = Exact<{
  input: AddInstagramChannelInput;
}>;

export type AddInstagramChannelMutation = {
  __typename: 'Mutation';
  addInstagramChannel: {
    __typename: 'Creator';
    id: string;
    channels: Array<{
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    }>;
  };
};

export type UpdateAccessTokenForInstagramChannelMutationVariables = Exact<{
  input: UpdateAccessTokenForInstagramChannelInput;
}>;

export type UpdateAccessTokenForInstagramChannelMutation = {
  __typename: 'Mutation';
  updateAccessTokenForInstagramChannel: {
    __typename: 'Creator';
    id: string;
    channels: Array<{
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    }>;
  };
};

export type BrandUsageQueryVariables = Exact<{ [key: string]: never }>;

export type BrandUsageQuery = {
  __typename: 'Query';
  whoAmI:
    | { __typename: 'Creator' }
    | {
        __typename: 'Manager';
        id: string;
        company: {
          __typename: 'Company';
          id: string;
          currentBrandCount: number;
          currentSubscription?: {
            __typename: 'SubscriptionObject';
            id: string;
            subscriptionPlan?: {
              __typename: 'SubscriptionPlan';
              id: string;
              maxBrandCount?: number | null;
            } | null;
          } | null;
        };
      };
};

export type CampaignUsageQueryVariables = Exact<{ [key: string]: never }>;

export type CampaignUsageQuery = {
  __typename: 'Query';
  whoAmI:
    | { __typename: 'Creator' }
    | {
        __typename: 'Manager';
        id: string;
        company: {
          __typename: 'Company';
          id: string;
          currentActiveCampaignCount: number;
          currentSubscription?: {
            __typename: 'SubscriptionObject';
            id: string;
            subscriptionPlan?: {
              __typename: 'SubscriptionPlan';
              id: string;
              maxCampaignCount?: number | null;
            } | null;
          } | null;
        };
      };
};

export type StorageSpaceUsageQueryVariables = Exact<{ [key: string]: never }>;

export type StorageSpaceUsageQuery = {
  __typename: 'Query';
  whoAmI:
    | { __typename: 'Creator' }
    | {
        __typename: 'Manager';
        id: string;
        company: {
          __typename: 'Company';
          id: string;
          usedStorageSpace: number;
          currentSubscription?: {
            __typename: 'SubscriptionObject';
            id: string;
            subscriptionPlan?: {
              __typename: 'SubscriptionPlan';
              id: string;
              storageSpaceLimit: number;
            } | null;
          } | null;
        };
      };
};

export type DeleteManagerMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteManagerMutation = {
  __typename: 'Mutation';
  deleteManager: string;
};

export type LogoutManagerMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutManagerMutation = {
  __typename: 'Mutation';
  logoutManager: string;
};

export type ManagerLayoutQueryVariables = Exact<{ [key: string]: never }>;

export type ManagerLayoutQuery = {
  __typename: 'Query';
  whoAmI:
    | { __typename: 'Creator' }
    | {
        __typename: 'Manager';
        id: string;
        email: string;
        locale: string;
        company: {
          __typename: 'Company';
          id: string;
          name: string;
          currentSubscription?: {
            __typename: 'SubscriptionObject';
            id: string;
            status: string;
            subscriptionPlan?: {
              __typename: 'SubscriptionPlan';
              id: string;
            } | null;
          } | null;
          brands: Array<{ __typename: 'Brand'; id: string }>;
        };
      };
};

export type AdminQueryVariables = Exact<{ [key: string]: never }>;

export type AdminQuery = {
  __typename: 'Query';
  whoAmI:
    | { __typename: 'Creator' }
    | { __typename: 'Manager'; id: string; isAdmin: boolean };
};

export type ManagersQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ManagersQuery = {
  __typename: 'Query';
  managers: {
    __typename: 'ManagerPaginator';
    data: Array<{
      __typename: 'Manager';
      id: string;
      email: string;
      company: { __typename: 'Company'; id: string; name: string };
    }>;
    paginatorInfo: { __typename: 'PaginatorInfo'; total: number };
  };
};

export type ImpersonateManagerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ImpersonateManagerMutation = {
  __typename: 'Mutation';
  impersonateManager: string;
};

export type ForgotManagerPasswordMutationVariables = Exact<{
  input: ForgotManagerPasswordInput;
}>;

export type ForgotManagerPasswordMutation = {
  __typename: 'Mutation';
  forgotManagerPassword: string;
};

export type LoginManagerMutationVariables = Exact<{
  input: LoginManagerInput;
}>;

export type LoginManagerMutation = {
  __typename: 'Mutation';
  loginManager: string;
};

export type ManagerDocumentQueryVariables = Exact<{ [key: string]: never }>;

export type ManagerDocumentQuery = {
  __typename: 'Query';
  whoAmI:
    | { __typename: 'Creator' }
    | {
        __typename: 'Manager';
        id: string;
        email: string;
        company: {
          __typename: 'Company';
          id: string;
          managers: Array<{ __typename: 'Manager'; id: string; email: string }>;
        };
      };
};

export type UpdateManagerPasswordMutationVariables = Exact<{
  input: UpdateManagerPasswordInput;
}>;

export type UpdateManagerPasswordMutation = {
  __typename: 'Mutation';
  updateManagerPassword: string;
};

export type RequestVerificationOfNewManagerEmailMutationVariables = Exact<{
  input: RequestVerificationOfNewManagerEmailInput;
}>;

export type RequestVerificationOfNewManagerEmailMutation = {
  __typename: 'Mutation';
  requestVerificationOfNewManagerEmail: string;
};

export type RecoverManagerPasswordMutationVariables = Exact<{
  input: RecoverManagerPasswordInput;
}>;

export type RecoverManagerPasswordMutation = {
  __typename: 'Mutation';
  recoverManagerPassword: string;
};

export type SettingsQueryVariables = Exact<{ [key: string]: never }>;

export type SettingsQuery = {
  __typename: 'Query';
  whoAmI:
    | { __typename: 'Creator' }
    | {
        __typename: 'Manager';
        id: string;
        company: {
          __typename: 'Company';
          id: string;
          currentSubscription?: {
            __typename: 'SubscriptionObject';
            id: string;
            subscriptionPlan?: {
              __typename: 'SubscriptionPlan';
              id: string;
              name: string;
              bookingFeePercentage: number;
              minBookingFee: number;
              maxCampaignCount?: number | null;
              maxBrandCount?: number | null;
              storageSpaceLimit: number;
            } | null;
          } | null;
        };
      };
};

export type UpdateManagerLocaleMutationVariables = Exact<{
  input: UpdateManagerLocaleInput;
}>;

export type UpdateManagerLocaleMutation = {
  __typename: 'Mutation';
  updateManagerLocale: { __typename: 'Manager'; id: string; locale: string };
};

export type VerifyManagerEmailMutationVariables = Exact<{
  input: VerifyManagerEmailInput;
}>;

export type VerifyManagerEmailMutation = {
  __typename: 'Mutation';
  verifyManagerEmail: string;
};

export type ResendManagerVerificationEmailMutationVariables = Exact<{
  input: ResendManagerVerificationEmailInput;
}>;

export type ResendManagerVerificationEmailMutation = {
  __typename: 'Mutation';
  resendManagerVerificationEmail: string;
};

export type VerifyAndUpdateManagerEmailMutationVariables = Exact<{
  input: VerifyManagerEmailInput;
}>;

export type VerifyAndUpdateManagerEmailMutation = {
  __typename: 'Mutation';
  verifyAndUpdateManagerEmail: {
    __typename: 'Manager';
    id: string;
    email: string;
  };
};

export type MessagesQueryVariables = Exact<{
  creatorHasCampaignId: Scalars['ID']['input'];
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type MessagesQuery = {
  __typename: 'Query';
  messages: {
    __typename: 'MessagePaginator';
    data: Array<{
      __typename: 'Message';
      id: string;
      sender: MessageSender;
      text: string;
      createdAt: string;
    }>;
    paginatorInfo: {
      __typename: 'PaginatorInfo';
      currentPage: number;
      hasMorePages: boolean;
    };
  };
};

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInput;
}>;

export type CreateMessageMutation = {
  __typename: 'Mutation';
  createMessage: {
    __typename: 'Message';
    id: string;
    sender: MessageSender;
    text: string;
    createdAt: string;
  };
};

export type SubscriptionPlansQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionPlansQuery = {
  __typename: 'Query';
  subscriptionPlans: Array<{
    __typename: 'SubscriptionPlan';
    id: string;
    name: string;
    bookingFeePercentage: number;
    minBookingFee: number;
    maxBrandCount?: number | null;
    maxCampaignCount?: number | null;
    storageSpaceLimit: number;
    prices: Array<{
      __typename: 'SubscriptionPrice';
      id: string;
      amount: number;
      recurringInterval: RecurringIntervalPrice;
      lookupKey: SubscriptionPriceLookupKey;
    }>;
  }>;
};

export type LatestTiktokVideosQueryVariables = Exact<{
  tiktokChannelId: Scalars['ID']['input'];
}>;

export type LatestTiktokVideosQuery = {
  __typename: 'Query';
  latestTiktokVideos: Array<{
    __typename: 'TiktokVideo';
    id: string;
    coverImageUrl: string;
    title?: string | null;
  }>;
};

export type AddTiktokChannelMutationVariables = Exact<{
  input: AddTiktokChannelInput;
}>;

export type AddTiktokChannelMutation = {
  __typename: 'Mutation';
  addTiktokChannel: {
    __typename: 'Creator';
    id: string;
    channels: Array<{
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    }>;
  };
};

export type UpdateAccessTokenForTiktokChannelMutationVariables = Exact<{
  input: UpdateAccessTokenForTiktokChannelInput;
}>;

export type UpdateAccessTokenForTiktokChannelMutation = {
  __typename: 'Mutation';
  updateAccessTokenForTiktokChannel: {
    __typename: 'Creator';
    id: string;
    channels: Array<{
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    }>;
  };
};

export type LatestYoutubeVideosQueryVariables = Exact<{
  youtubeChannelId: Scalars['ID']['input'];
}>;

export type LatestYoutubeVideosQuery = {
  __typename: 'Query';
  latestYoutubeVideos: Array<{
    __typename: 'YoutubeVideo';
    id: string;
    coverImageUrl: string;
    title: string;
  }>;
};

export type AddYoutubeChannelMutationVariables = Exact<{
  input: AddYoutubeChannelInput;
}>;

export type AddYoutubeChannelMutation = {
  __typename: 'Mutation';
  addYoutubeChannel: {
    __typename: 'Creator';
    id: string;
    channels: Array<{
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    }>;
  };
};

export type UpdateAccessTokenForYoutubeChannelMutationVariables = Exact<{
  input: UpdateAccessTokenForYoutubeChannelInput;
}>;

export type UpdateAccessTokenForYoutubeChannelMutation = {
  __typename: 'Mutation';
  updateAccessTokenForYoutubeChannel: {
    __typename: 'Creator';
    id: string;
    channels: Array<{
      __typename: 'Channel';
      id: string;
      isAccessTokenValid: boolean;
      channelableType: ChannelType;
      channelable:
        | {
            __typename: 'InstagramChannel';
            id: string;
            username: string;
            url: string;
            followersCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            mediaCount: number;
          }
        | {
            __typename: 'TiktokChannel';
            id: string;
            displayName: string;
            url: string;
            followerCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          }
        | {
            __typename: 'YoutubeChannel';
            id: string;
            title: string;
            url: string;
            subscriberCount: number;
            averageEngagementRate?: number | null;
            averageReach?: number | null;
            videoCount: number;
          };
    }>;
  };
};

export type BrandFragment = {
  __typename: 'Brand';
  id: string;
  name: string;
  logo: { __typename: 'Download'; uri: string; hash?: string | null };
};

export type CampaignFragment = {
  __typename: 'Campaign';
  id: string;
  name: string;
  description: string;
  status: CampaignStatus;
  rewardType: CampaignRewardType;
  fixedMoneyRewardValue?: number | null;
  applyUntil: string;
  submitUntil: string;
  address?: string | null;
  includeContentFileOnSubmission: boolean;
  taskItems: Array<{
    __typename: 'TaskItem';
    id: string;
    type: TaskItemType;
    taskable:
      | { __typename: 'FreeTextTask'; id: string; description: string }
      | { __typename: 'HashtagTask'; id: string; hashtags: Array<string> }
      | { __typename: 'WebsiteTask'; id: string; url: string };
  }>;
  headerImage: { __typename: 'Download'; uri: string; hash?: string | null };
  brand: {
    __typename: 'Brand';
    id: string;
    name: string;
    logo: { __typename: 'Download'; uri: string; hash?: string | null };
  };
  category: { __typename: 'Category'; id: string; name: string };
  gifts: Array<{
    __typename: 'CampaignGift';
    id: string;
    type: CampaignGiftType;
    name: string;
    description: string;
  }>;
  questions: Array<{ __typename: 'Question'; id: string; question: string }>;
  typeable:
    | {
        __typename: 'InstagramCampaign';
        id: string;
        channelType: ChannelType;
        instagramContentType: InstagramContentType;
      }
    | {
        __typename: 'TiktokCampaign';
        id: string;
        channelType: ChannelType;
        tiktokContentType: TiktokContentType;
      }
    | {
        __typename: 'YoutubeCampaign';
        id: string;
        channelType: ChannelType;
        youtubeContentType: YoutubeContentType;
      };
};

export type CampaignTypeableFragment = {
  __typename: 'Campaign';
  typeable:
    | {
        __typename: 'InstagramCampaign';
        id: string;
        channelType: ChannelType;
        instagramContentType: InstagramContentType;
      }
    | {
        __typename: 'TiktokCampaign';
        id: string;
        channelType: ChannelType;
        tiktokContentType: TiktokContentType;
      }
    | {
        __typename: 'YoutubeCampaign';
        id: string;
        channelType: ChannelType;
        youtubeContentType: YoutubeContentType;
      };
};

export type ChannelWithAccessTokenValidFragment = {
  __typename: 'Channel';
  id: string;
  isAccessTokenValid: boolean;
  channelableType: ChannelType;
  channelable:
    | {
        __typename: 'InstagramChannel';
        id: string;
        username: string;
        url: string;
        followersCount: number;
        averageEngagementRate?: number | null;
        averageReach?: number | null;
        mediaCount: number;
      }
    | {
        __typename: 'TiktokChannel';
        id: string;
        displayName: string;
        url: string;
        followerCount: number;
        averageEngagementRate?: number | null;
        averageReach?: number | null;
        videoCount: number;
      }
    | {
        __typename: 'YoutubeChannel';
        id: string;
        title: string;
        url: string;
        subscriberCount: number;
        averageEngagementRate?: number | null;
        averageReach?: number | null;
        videoCount: number;
      };
};

export type ChannelFragment = {
  __typename: 'Channel';
  id: string;
  channelableType: ChannelType;
  channelable:
    | {
        __typename: 'InstagramChannel';
        id: string;
        username: string;
        url: string;
        followersCount: number;
        averageEngagementRate?: number | null;
        averageReach?: number | null;
        mediaCount: number;
      }
    | {
        __typename: 'TiktokChannel';
        id: string;
        displayName: string;
        url: string;
        followerCount: number;
        averageEngagementRate?: number | null;
        averageReach?: number | null;
        videoCount: number;
      }
    | {
        __typename: 'YoutubeChannel';
        id: string;
        title: string;
        url: string;
        subscriberCount: number;
        averageEngagementRate?: number | null;
        averageReach?: number | null;
        videoCount: number;
      };
};

export type InstagramChannelFragment = {
  __typename: 'InstagramChannel';
  id: string;
  username: string;
  url: string;
  followersCount: number;
  averageEngagementRate?: number | null;
  averageReach?: number | null;
  mediaCount: number;
};

export type TiktokChannelFragment = {
  __typename: 'TiktokChannel';
  id: string;
  displayName: string;
  url: string;
  followerCount: number;
  averageEngagementRate?: number | null;
  averageReach?: number | null;
  videoCount: number;
};

export type YoutubeChannelFragment = {
  __typename: 'YoutubeChannel';
  id: string;
  title: string;
  url: string;
  subscriberCount: number;
  averageEngagementRate?: number | null;
  averageReach?: number | null;
  videoCount: number;
};

export type CreatorFragment = {
  __typename: 'Creator';
  id: string;
  username: string;
  description?: string | null;
  profilePicture?: {
    __typename: 'Download';
    uri: string;
    hash?: string | null;
  } | null;
};

export type CreatorNotificationSettingsFragment = {
  __typename: 'CreatorNotificationSettings';
  id: string;
  newMessage: Array<CreatorNotificationChannel>;
  newCampaignAvailable: Array<CreatorNotificationChannel>;
  campaignApplicationAccepted: Array<CreatorNotificationChannel>;
  campaignApplicationDeclined: Array<CreatorNotificationChannel>;
  campaignGoalSubmissionAccepted: Array<CreatorNotificationChannel>;
  campaignGoalSubmissionFailed: Array<CreatorNotificationChannel>;
  campaignGoalSubmissionReminder: Array<CreatorNotificationChannel>;
  campaignGoalSubmissionRetried: Array<CreatorNotificationChannel>;
  creatorHasCampaignCancelled: Array<CreatorNotificationChannel>;
  connectChannelReminder: Array<CreatorNotificationChannel>;
  newCampaignAvailableCategories: Array<{
    __typename: 'Category';
    id: string;
    name: string;
  }>;
};

export type InstagramMediaFragment = {
  __typename: 'InstagramMedia';
  id: string;
  mediaType: InstagramMediaType;
  mediaProductType: InstagramMediaProductType;
  thumbnailUrl?: string | null;
  mediaUrl?: string | null;
  caption?: string | null;
  timestamp: string;
};

export const CampaignTypeableFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignTypeableFragment, unknown>;
export const BrandFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BrandFragment, unknown>;
export const CampaignFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submitUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includeContentFileOnSubmission' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'campaignTypeable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'WebsiteTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'HashtagTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hashtags' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FreeTextTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headerImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gifts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignFragment, unknown>;
export const InstagramChannelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstagramChannelFragment, unknown>;
export const TiktokChannelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TiktokChannelFragment, unknown>;
export const YoutubeChannelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<YoutubeChannelFragment, unknown>;
export const ChannelWithAccessTokenValidFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channelWithAccessTokenValid' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChannelWithAccessTokenValidFragment, unknown>;
export const ChannelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChannelFragment, unknown>;
export const CreatorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Creator' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatorFragment, unknown>;
export const CreatorNotificationSettingsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creatorNotificationSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreatorNotificationSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newCampaignAvailable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignApplicationAccepted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignApplicationDeclined' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignGoalSubmissionAccepted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignGoalSubmissionFailed' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignGoalSubmissionReminder' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignGoalSubmissionRetried' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorHasCampaignCancelled' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectChannelReminder' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newCampaignAvailableCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatorNotificationSettingsFragment, unknown>;
export const InstagramMediaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaProductType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstagramMediaFragment, unknown>;
export const BrandsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'brands' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Manager' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'brands' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'brand' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BrandsQuery, BrandsQueryVariables>;
export const BrandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'brand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BrandQuery, BrandQueryVariables>;
export const CreateBrandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBrand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBrandInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBrand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBrandMutation, CreateBrandMutationVariables>;
export const UpdateBrandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBrand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBrandInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBrand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBrandMutation, UpdateBrandMutationVariables>;
export const UpdateBrandLogoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBrandLogo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBrandLogoInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBrandLogo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBrandLogoMutation,
  UpdateBrandLogoMutationVariables
>;
export const CampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaign' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submitUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includeContentFileOnSubmission' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'campaignTypeable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'WebsiteTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'HashtagTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hashtags' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FreeTextTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headerImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gifts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignQuery, CampaignQueryVariables>;
export const CampaignWithCreatorHasCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignWithCreatorHasCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creatorHasCampaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contentUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reason' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submitUntil' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rewardValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'answer' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'question' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submitUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includeContentFileOnSubmission' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'campaignTypeable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'WebsiteTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'HashtagTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hashtags' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FreeTextTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headerImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gifts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CampaignWithCreatorHasCampaignQuery,
  CampaignWithCreatorHasCampaignQueryVariables
>;
export const DynamicMoneyRewardValueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dynamicMoneyRewardValue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dynamicMoneyRewardValue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DynamicMoneyRewardValueQuery,
  DynamicMoneyRewardValueQueryVariables
>;
export const CampaignYouAreApplyingForDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignYouAreApplyingFor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headerImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaignTypeable' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'question' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'brand' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gifts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CampaignYouAreApplyingForQuery,
  CampaignYouAreApplyingForQueryVariables
>;
export const CampaignGiftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignGift' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gifts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creatorHasCampaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaignGiftCodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gift' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignGiftQuery, CampaignGiftQueryVariables>;
export const DiscoverCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'discoverCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DiscoverCampaignsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discoverCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'campaignTypeable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headerImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hash' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brand' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'brand' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMorePages' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DiscoverCampaignsQuery,
  DiscoverCampaignsQueryVariables
>;
export const CampaignWithStatisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignWithStatistics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'statistics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applications' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicationsAccepted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submissions' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submissionsAccepted' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submitUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includeContentFileOnSubmission' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'campaignTypeable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'WebsiteTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'HashtagTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hashtags' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FreeTextTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headerImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gifts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CampaignWithStatisticsQuery,
  CampaignWithStatisticsQueryVariables
>;
export const CampaignBriefingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignBriefing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaign' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submitUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includeContentFileOnSubmission' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'campaignTypeable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'WebsiteTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'HashtagTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hashtags' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FreeTextTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headerImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gifts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CampaignBriefingQuery,
  CampaignBriefingQueryVariables
>;
export const PublishCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'publishCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PublishCampaignMutation,
  PublishCampaignMutationVariables
>;
export const DeleteCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCampaignMutation,
  DeleteCampaignMutationVariables
>;
export const CampaignReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'brand' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaignTypeable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'InstagramReelCampaignReport',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'completedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'followerCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'plays' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'likes' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comments' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shares' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reach' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'engagementRate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cpv' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'creatorHasCampaignReports',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'creatorHasCampaign',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'creator',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'username',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'followerCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'plays' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'likes' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comments' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'shares' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'reach' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'engagementRate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'InstagramPostCampaignReport',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'completedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'followerCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'views' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'saves' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'engagementRate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cpv' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'creatorHasCampaignReports',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'creatorHasCampaign',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'creator',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'username',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'followerCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'views' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'saves' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'engagementRate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'InstagramStoryCampaignReport',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'completedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'followerCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'views' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'engagementRate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cpv' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'creatorHasCampaignReports',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'creatorHasCampaign',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'creator',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'username',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'followerCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'views' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'engagementRate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'TiktokVideoCampaignReport',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'completedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'followerCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'views' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'likes' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comments' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shares' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'engagementRate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cpv' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'creatorHasCampaignReports',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'creatorHasCampaign',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'creator',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'username',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'followerCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'views' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'likes' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comments' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'shares' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'engagementRate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'YoutubeVideoCampaignReport',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'completedCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subscriberCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'views' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'likes' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comments' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'favorites' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'engagementRate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cpv' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'creatorHasCampaignReports',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'creatorHasCampaign',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'creator',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'username',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subscriberCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'views' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'likes' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comments' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'favorites' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'engagementRate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignReportQuery, CampaignReportQueryVariables>;
export const CampaignReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'brandIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'brandIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'brandIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'ACTIVE' },
                          { kind: 'EnumValue', value: 'COMPLETED' },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportable' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'InstagramReelCampaignReport',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'completedCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'followerCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'InstagramPostCampaignReport',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'completedCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'followerCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'InstagramStoryCampaignReport',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'completedCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'followerCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'TiktokVideoCampaignReport',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'completedCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'followerCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'YoutubeVideoCampaignReport',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'completedCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subscriberCount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cpv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMorePages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CampaignReportsQuery,
  CampaignReportsQueryVariables
>;
export const CampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CampaignsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headerImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hash' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brand' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'brand' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statistics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'reviewApplicationBefore',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'reviewSubmissionBefore',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMorePages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignsQuery, CampaignsQueryVariables>;
export const DashboardCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dashboardCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CampaignsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headerImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hash' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brand' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'brand' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statistics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'reviewApplicationBefore',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'reviewSubmissionBefore',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMorePages' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardCampaignsQuery,
  DashboardCampaignsQueryVariables
>;
export const CreateCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaign' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submitUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includeContentFileOnSubmission' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'campaignTypeable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'WebsiteTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'HashtagTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hashtags' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FreeTextTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headerImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gifts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;
export const UpdateCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaign' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submitUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includeContentFileOnSubmission' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'campaignTypeable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'WebsiteTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'HashtagTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hashtags' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FreeTextTask' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headerImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'brand' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gifts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables
>;
export const UpdateCampaignHeaderImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCampaignHeaderImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCampaignHeaderImageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCampaignHeaderImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headerImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCampaignHeaderImageMutation,
  UpdateCampaignHeaderImageMutationVariables
>;
export const CompleteCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'completeCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompleteCampaignMutation,
  CompleteCampaignMutationVariables
>;
export const CampaignPreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignPreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submitUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaignTypeable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headerImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'brand' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gifts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taskable' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'WebsiteTask' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'HashtagTask' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hashtags' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'FreeTextTask' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CampaignPreviewQuery,
  CampaignPreviewQueryVariables
>;
export const InstagramPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'instagramPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rewardType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fixedMoneyRewardValue' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'campaignTypeable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headerImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gifts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstagramPostQuery, InstagramPostQueryVariables>;
export const DiscoverCampaignsForSitemapDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'discoverCampaignsForSitemap' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DiscoverCampaignsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discoverCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DiscoverCampaignsForSitemapQuery,
  DiscoverCampaignsForSitemapQueryVariables
>;
export const DiscoverCampaignsForInstagramPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'discoverCampaignsForInstagramPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DiscoverCampaignsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discoverCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DiscoverCampaignsForInstagramPostQuery,
  DiscoverCampaignsForInstagramPostQueryVariables
>;
export const PublicDiscoverCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'publicDiscoverCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DiscoverCampaignsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discoverCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'campaignTypeable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headerImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hash' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brand' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'brand' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'brand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Brand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PublicDiscoverCampaignsQuery,
  PublicDiscoverCampaignsQueryVariables
>;
export const CategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'categories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CategoriesQuery, CategoriesQueryVariables>;
export const ChannelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'channels' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Creator' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'channelWithAccessTokenValid',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channelWithAccessTokenValid' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChannelsQuery, ChannelsQueryVariables>;
export const DeleteChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'channelWithAccessTokenValid',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channelWithAccessTokenValid' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteChannelMutation,
  DeleteChannelMutationVariables
>;
export const StripeCustomerPortalUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'stripeCustomerPortalUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StripeCustomerPortalUrlInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeCustomerPortalUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StripeCustomerPortalUrlQuery,
  StripeCustomerPortalUrlQueryVariables
>;
export const RegisterCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'registerCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RegisterCompanyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterCompanyMutation,
  RegisterCompanyMutationVariables
>;
export const CheckoutSubscriptionForCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checkoutSubscriptionForCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CheckoutSubscriptionForCompanyInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkoutSubscriptionForCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutSubscriptionForCompanyMutation,
  CheckoutSubscriptionForCompanyMutationVariables
>;
export const RegisterCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'registerCreator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RegisterCreatorInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerCreator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'creator' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasSSOLogin' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Creator' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterCreatorMutation,
  RegisterCreatorMutationVariables
>;
export const SocialRegisterCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'socialRegisterCreator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SocialRegisterCreatorInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialRegisterCreator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SocialRegisterCreatorMutation,
  SocialRegisterCreatorMutationVariables
>;
export const DeactivateCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deactivateCreator' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'deactivateCreator' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeactivateCreatorMutation,
  DeactivateCreatorMutationVariables
>;
export const ForgotCreatorPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'forgotCreatorPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ForgotCreatorPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forgotCreatorPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ForgotCreatorPasswordMutation,
  ForgotCreatorPasswordMutationVariables
>;
export const CreatorProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'creatorProfile' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Creator' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'creator' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'channelWithAccessTokenValid',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Creator' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channelWithAccessTokenValid' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatorProfileQuery, CreatorProfileQueryVariables>;
export const RecoverCreatorPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'recoverCreatorPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RecoverCreatorPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recoverCreatorPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecoverCreatorPasswordMutation,
  RecoverCreatorPasswordMutationVariables
>;
export const VerifyCreatorEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'verifyCreatorEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerifyCreatorEmailInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyCreatorEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyCreatorEmailMutation,
  VerifyCreatorEmailMutationVariables
>;
export const ResendCreatorVerificationEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resendCreatorVerificationEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ResendCreatorVerificationEmailInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendCreatorVerificationEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResendCreatorVerificationEmailMutation,
  ResendCreatorVerificationEmailMutationVariables
>;
export const RequestVerificationOfNewCreatorEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestVerificationOfNewCreatorEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RequestVerificationOfNewCreatorEmailInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'requestVerificationOfNewCreatorEmail',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestVerificationOfNewCreatorEmailMutation,
  RequestVerificationOfNewCreatorEmailMutationVariables
>;
export const VerifyAndUpdateCreatorEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'verifyAndUpdateCreatorEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerifyCreatorEmailInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyAndUpdateCreatorEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyAndUpdateCreatorEmailMutation,
  VerifyAndUpdateCreatorEmailMutationVariables
>;
export const UpdateCreatorProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCreatorProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCreatorProfileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCreatorProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'creator' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Creator' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCreatorProfileMutation,
  UpdateCreatorProfileMutationVariables
>;
export const UpdateCreatorLocaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCreatorLocale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCreatorLocaleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCreatorLocale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCreatorLocaleMutation,
  UpdateCreatorLocaleMutationVariables
>;
export const UpdateCreatorPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCreatorPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCreatorPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCreatorPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCreatorPasswordMutation,
  UpdateCreatorPasswordMutationVariables
>;
export const UploadCreatorProfilePictureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'uploadCreatorProfilePicture' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UploadCreatorProfilePictureInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadCreatorProfilePicture' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadCreatorProfilePictureMutation,
  UploadCreatorProfilePictureMutationVariables
>;
export const CreatorContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'creatorContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Creator' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'creator' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasSSOLogin' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'channelWithAccessTokenValid',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Creator' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channelWithAccessTokenValid' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatorContextQuery, CreatorContextQueryVariables>;
export const LoginCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'loginCreator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoginCreatorInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginCreator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoginCreatorMutation,
  LoginCreatorMutationVariables
>;
export const LogoutCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'logoutCreator' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'logoutCreator' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LogoutCreatorMutation,
  LogoutCreatorMutationVariables
>;
export const SocialLoginCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'socialLoginCreator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SocialLoginCreatorInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialLoginCreator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SocialLoginCreatorMutation,
  SocialLoginCreatorMutationVariables
>;
export const SetCreatorExpoPushTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setCreatorExpoPushToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SetCreatorExpoPushTokenInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setCreatorExpoPushToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetCreatorExpoPushTokenMutation,
  SetCreatorExpoPushTokenMutationVariables
>;
export const CreatorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'creators' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creators' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatorsQuery, CreatorsQueryVariables>;
export const ImpersonateCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'impersonateCreator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'impersonateCreator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ImpersonateCreatorMutation,
  ImpersonateCreatorMutationVariables
>;
export const ApplyForCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'applyForCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApplyForCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyForCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rewardValue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creatorHasCampaign' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplyForCampaignMutation,
  ApplyForCampaignMutationVariables
>;
export const UnapplyFromCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'unapplyFromCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creatorHasCampaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unapplyFromCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creatorHasCampaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'creatorHasCampaignId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnapplyFromCampaignMutation,
  UnapplyFromCampaignMutationVariables
>;
export const MyCreatorHasCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myCreatorHasCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MyCreatorHasCampaignsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCreatorHasCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaign' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'headerImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMorePages' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyCreatorHasCampaignsQuery,
  MyCreatorHasCampaignsQueryVariables
>;
export const SubmitCampaignGoalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'submitCampaignGoal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubmitCampaignGoalInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitCampaignGoal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitCampaignGoalMutation,
  SubmitCampaignGoalMutationVariables
>;
export const CreatorHasCampaignChannelWithCampaignTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'creatorHasCampaignChannelWithCampaignType',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorHasCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'channelWithAccessTokenValid',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'includeContentFileOnSubmission',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'campaignTypeable' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channelWithAccessTokenValid' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatorHasCampaignChannelWithCampaignTypeQuery,
  CreatorHasCampaignChannelWithCampaignTypeQueryVariables
>;
export const CreatorsOfCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'creatorsOfCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatorsOfCampaignInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorsOfCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channel' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'channel' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'username' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isUnreliable' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profilePicture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaign' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statistics' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'reviewApplicationBefore',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'reviewSubmissionBefore',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMorePages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatorsOfCampaignQuery,
  CreatorsOfCampaignQueryVariables
>;
export const CreatorHasCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'creatorHasCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorHasCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submitUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rewardValue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'channel' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelable' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'InstagramChannel',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ageFollowerDemographics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'age' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'percentage',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'countryFollowerDemographics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'country',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'percentage',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'genderFollowerDemographics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'gender',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'percentage',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignGiftCodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gift' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answer' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'question' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'streetAddressLine1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'streetAddressLine2' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postcode' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isUnreliable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hash' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'includeContentFileOnSubmission',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'campaignTypeable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submitUntil' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gifts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'campaignTypeable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typeable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstagramCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'YoutubeCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtubeContentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TiktokCampaign' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiktokContentType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatorHasCampaignQuery,
  CreatorHasCampaignQueryVariables
>;
export const AcceptCampaignApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'acceptCampaignApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AcceptCampaignApplicationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptCampaignApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submitUntil' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignGiftCodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gift' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptCampaignApplicationMutation,
  AcceptCampaignApplicationMutationVariables
>;
export const DeclineCampaignApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'declineCampaignApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creatorHasCampaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'declineCampaignApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creatorHasCampaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'creatorHasCampaignId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeclineCampaignApplicationMutation,
  DeclineCampaignApplicationMutationVariables
>;
export const AcceptCampaignGoalSubmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'acceptCampaignGoalSubmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creatorHasCampaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptCampaignGoalSubmission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creatorHasCampaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'creatorHasCampaignId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptCampaignGoalSubmissionMutation,
  AcceptCampaignGoalSubmissionMutationVariables
>;
export const RetryCampaignGoalSubmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'retryCampaignGoalSubmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RetryCampaignGoalSubmissionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retryCampaignGoalSubmission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submitUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RetryCampaignGoalSubmissionMutation,
  RetryCampaignGoalSubmissionMutationVariables
>;
export const FailCampaignGoalSubmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'failCampaignGoalSubmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FailCampaignGoalSubmissionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'failCampaignGoalSubmission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FailCampaignGoalSubmissionMutation,
  FailCampaignGoalSubmissionMutationVariables
>;
export const CreatorHasCampaignContentFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'creatorHasCampaignContentFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorHasCampaignContentFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatorHasCampaignContentFileQuery,
  CreatorHasCampaignContentFileQueryVariables
>;
export const ExtendCampaignDeadlinesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'extendCampaignDeadlines' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExtendCampaignDeadlinesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extendCampaignDeadlines' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applyUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submitUntil' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExtendCampaignDeadlinesMutation,
  ExtendCampaignDeadlinesMutationVariables
>;
export const CreatorNotificationSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'creatorNotificationSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Creator' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'creatorNotificationSettings',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creatorNotificationSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreatorNotificationSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newCampaignAvailable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignApplicationAccepted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignApplicationDeclined' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignGoalSubmissionAccepted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignGoalSubmissionFailed' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignGoalSubmissionReminder' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignGoalSubmissionRetried' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorHasCampaignCancelled' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectChannelReminder' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newCampaignAvailableCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatorNotificationSettingsQuery,
  CreatorNotificationSettingsQueryVariables
>;
export const UpdateCreatorNotificationSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCreatorNotificationSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateCreatorNotificationSettingInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCreatorNotificationSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'creatorNotificationSettings' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'creatorNotificationSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreatorNotificationSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newCampaignAvailable' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignApplicationAccepted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignApplicationDeclined' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignGoalSubmissionAccepted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignGoalSubmissionFailed' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignGoalSubmissionReminder' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignGoalSubmissionRetried' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorHasCampaignCancelled' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectChannelReminder' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newCampaignAvailableCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCreatorNotificationSettingMutation,
  UpdateCreatorNotificationSettingMutationVariables
>;
export const UpdateNewCampaignAvailableNotificationCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updateNewCampaignAvailableNotificationCategories',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateNewCampaignAvailableNotificationCategoriesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updateNewCampaignAvailableNotificationCategories',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'newCampaignAvailableCategories',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNewCampaignAvailableNotificationCategoriesMutation,
  UpdateNewCampaignAvailableNotificationCategoriesMutationVariables
>;
export const LatestInstagramMediaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'latestInstagramMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'instagramChannelId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestInstagramMedia' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instagramChannelId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'instagramChannelId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaProductType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LatestInstagramMediaQuery,
  LatestInstagramMediaQueryVariables
>;
export const LatestInstagramStoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'latestInstagramStories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'instagramChannelId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestInstagramStories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instagramChannelId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'instagramChannelId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaProductType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LatestInstagramStoriesQuery,
  LatestInstagramStoriesQueryVariables
>;
export const AddInstagramChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addInstagramChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddInstagramChannelInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addInstagramChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'channelWithAccessTokenValid',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channelWithAccessTokenValid' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddInstagramChannelMutation,
  AddInstagramChannelMutationVariables
>;
export const UpdateAccessTokenForInstagramChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAccessTokenForInstagramChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateAccessTokenForInstagramChannelInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updateAccessTokenForInstagramChannel',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'channelWithAccessTokenValid',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channelWithAccessTokenValid' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAccessTokenForInstagramChannelMutation,
  UpdateAccessTokenForInstagramChannelMutationVariables
>;
export const BrandUsageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'brandUsage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Manager' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'currentBrandCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'currentSubscription',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subscriptionPlan',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'maxBrandCount',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BrandUsageQuery, BrandUsageQueryVariables>;
export const CampaignUsageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'campaignUsage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Manager' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'currentActiveCampaignCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'currentSubscription',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subscriptionPlan',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'maxCampaignCount',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignUsageQuery, CampaignUsageQueryVariables>;
export const StorageSpaceUsageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'storageSpaceUsage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Manager' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'usedStorageSpace' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'currentSubscription',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subscriptionPlan',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'storageSpaceLimit',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StorageSpaceUsageQuery,
  StorageSpaceUsageQueryVariables
>;
export const DeleteManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteManager' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'deleteManager' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteManagerMutation,
  DeleteManagerMutationVariables
>;
export const LogoutManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'logoutManager' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'logoutManager' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LogoutManagerMutation,
  LogoutManagerMutationVariables
>;
export const ManagerLayoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'managerLayout' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Manager' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'currentSubscription',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subscriptionPlan',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'brands' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManagerLayoutQuery, ManagerLayoutQueryVariables>;
export const AdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'admin' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Manager' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isAdmin' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminQuery, AdminQueryVariables>;
export const ManagersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'managers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManagersQuery, ManagersQueryVariables>;
export const ImpersonateManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'impersonateManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'impersonateManager' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ImpersonateManagerMutation,
  ImpersonateManagerMutationVariables
>;
export const ForgotManagerPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'forgotManagerPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ForgotManagerPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forgotManagerPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ForgotManagerPasswordMutation,
  ForgotManagerPasswordMutationVariables
>;
export const LoginManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'loginManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoginManagerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginManager' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoginManagerMutation,
  LoginManagerMutationVariables
>;
export const ManagerDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'managerDocument' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Manager' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'managers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ManagerDocumentQuery,
  ManagerDocumentQueryVariables
>;
export const UpdateManagerPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateManagerPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateManagerPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateManagerPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateManagerPasswordMutation,
  UpdateManagerPasswordMutationVariables
>;
export const RequestVerificationOfNewManagerEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestVerificationOfNewManagerEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RequestVerificationOfNewManagerEmailInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'requestVerificationOfNewManagerEmail',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestVerificationOfNewManagerEmailMutation,
  RequestVerificationOfNewManagerEmailMutationVariables
>;
export const RecoverManagerPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'recoverManagerPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RecoverManagerPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recoverManagerPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecoverManagerPasswordMutation,
  RecoverManagerPasswordMutationVariables
>;
export const SettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'settings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoAmI' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Manager' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'currentSubscription',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subscriptionPlan',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'bookingFeePercentage',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'minBookingFee',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'maxCampaignCount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'maxBrandCount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'storageSpaceLimit',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SettingsQuery, SettingsQueryVariables>;
export const UpdateManagerLocaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateManagerLocale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateManagerLocaleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateManagerLocale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateManagerLocaleMutation,
  UpdateManagerLocaleMutationVariables
>;
export const VerifyManagerEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'verifyManagerEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerifyManagerEmailInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyManagerEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyManagerEmailMutation,
  VerifyManagerEmailMutationVariables
>;
export const ResendManagerVerificationEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resendManagerVerificationEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ResendManagerVerificationEmailInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendManagerVerificationEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResendManagerVerificationEmailMutation,
  ResendManagerVerificationEmailMutationVariables
>;
export const VerifyAndUpdateManagerEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'verifyAndUpdateManagerEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerifyManagerEmailInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyAndUpdateManagerEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyAndUpdateManagerEmailMutation,
  VerifyAndUpdateManagerEmailMutationVariables
>;
export const MessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'messages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creatorHasCampaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creatorHasCampaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'creatorHasCampaignId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sender' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginatorInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMorePages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessagesQuery, MessagesQueryVariables>;
export const CreateMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMessageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;
export const SubscriptionPlansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'subscriptionPlans' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurringInterval' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lookupKey' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookingFeePercentage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minBookingFee' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxBrandCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxCampaignCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storageSpaceLimit' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscriptionPlansQuery,
  SubscriptionPlansQueryVariables
>;
export const LatestTiktokVideosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'latestTiktokVideos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tiktokChannelId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestTiktokVideos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tiktokChannelId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tiktokChannelId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coverImageUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LatestTiktokVideosQuery,
  LatestTiktokVideosQueryVariables
>;
export const AddTiktokChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addTiktokChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddTiktokChannelInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTiktokChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'channelWithAccessTokenValid',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channelWithAccessTokenValid' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddTiktokChannelMutation,
  AddTiktokChannelMutationVariables
>;
export const UpdateAccessTokenForTiktokChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAccessTokenForTiktokChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateAccessTokenForTiktokChannelInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccessTokenForTiktokChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'channelWithAccessTokenValid',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channelWithAccessTokenValid' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAccessTokenForTiktokChannelMutation,
  UpdateAccessTokenForTiktokChannelMutationVariables
>;
export const LatestYoutubeVideosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'latestYoutubeVideos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'youtubeChannelId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestYoutubeVideos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'youtubeChannelId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'youtubeChannelId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coverImageUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LatestYoutubeVideosQuery,
  LatestYoutubeVideosQueryVariables
>;
export const AddYoutubeChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addYoutubeChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddYoutubeChannelInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addYoutubeChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'channelWithAccessTokenValid',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channelWithAccessTokenValid' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddYoutubeChannelMutation,
  AddYoutubeChannelMutationVariables
>;
export const UpdateAccessTokenForYoutubeChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAccessTokenForYoutubeChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateAccessTokenForYoutubeChannelInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccessTokenForYoutubeChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'channelWithAccessTokenValid',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'instagramChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstagramChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tiktokChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TiktokChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followerCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'youtubeChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'YoutubeChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageEngagementRate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageReach' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'channelWithAccessTokenValid' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Channel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAccessTokenValid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'channelableType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'instagramChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'tiktokChannel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'youtubeChannel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAccessTokenForYoutubeChannelMutation,
  UpdateAccessTokenForYoutubeChannelMutationVariables
>;
