import { graphql } from '../gql';

export const messagesDocument = graphql(`
  query messages($creatorHasCampaignId: ID!, $first: Int!, $page: Int) {
    messages(
      creatorHasCampaignId: $creatorHasCampaignId
      first: $first
      page: $page
    ) {
      data {
        id
        sender
        text
        createdAt
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`);

export const createMessageDocument = graphql(`
  mutation createMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
      sender
      text
      createdAt
    }
  }
`);
