import { Stack } from '@mui/material';
import { faMessage } from '@fortawesome/pro-thin-svg-icons/faMessage';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { messagesDocument } from '@social-garden/api/documents/message.ts';
import MessageListItem, {
  MessageListItemData,
} from '../../components/MessageListItem.tsx';
import CreatorHasCampaignSendMessage from './CreatorHasCampaignSendMessage.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import SuspenseFallback from '../../components/SuspenseFallback.tsx';
import StackList from '../../components/StackList.tsx';

interface CreatorHasCampaignMessagesProps {
  creatorHasCampaign: {
    id: string;
  };
}

export default function CreatorHasCampaignMessages({
  creatorHasCampaign,
}: CreatorHasCampaignMessagesProps) {
  const { t } = useTranslation(['manager']);

  const { data, loading, fetchMore } = useQuery(messagesDocument, {
    variables: {
      creatorHasCampaignId: creatorHasCampaign.id,
      first: 25,
      page: 1,
    },
    pollInterval: 60000,
  });

  const renderItem = useCallback(
    (messageData: MessageListItemData) => (
      <MessageListItem key={messageData.id} data={messageData} />
    ),
    [],
  );

  return (
    <Stack spacing={2}>
      <CreatorHasCampaignSendMessage creatorHasCampaign={creatorHasCampaign} />
      {data ? (
        <StackList<MessageListItemData>
          paginator={data.messages}
          fetchMore={fetchMore}
          renderItem={renderItem}
          loading={loading}
          spacing={2}
          ListEmptyComponent={
            <EmptyState
              icon={faMessage}
              text={t('manager:creatorHasCampaignMessages.messageList.empty')}
            />
          }
        />
      ) : (
        <SuspenseFallback minHeight={300} />
      )}
    </Stack>
  );
}
