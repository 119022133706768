import {
  ThemeProvider as EMOThemeProvider,
  ThemeProviderProps as EMOThemeProviderProps,
} from '@emotion/react';
import useCreateTheme from '../hooks/useCreateTheme.ts';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

type ThemeProviderProps = Optional<EMOThemeProviderProps, 'theme'>;

export default function ThemeProvider({
  theme: themeProp,
  children,
}: ThemeProviderProps) {
  const theme = useCreateTheme();

  return (
    <EMOThemeProvider theme={themeProp || theme}>{children}</EMOThemeProvider>
  );
}
