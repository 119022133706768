import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import './index.css';
import I18n from './lib/I18n.ts';
import Logger from './lib/Logger.ts';
import App from './App.tsx';
import { persistor } from './utils/apolloInMemoryCache.ts';

Logger.init();
await Promise.all([I18n.init(), persistor.restore()]);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
