import { useState } from 'react';
import { GridPaginationModel } from '@mui/x-data-grid';
import { pageSizeOptions } from '../constants/PageSize.ts';

interface PaginationModelOptions {
  pageSize?: (typeof pageSizeOptions)[number];
  page?: number;
}

export default function usePaginationModel(options?: PaginationModelOptions) {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: options?.pageSize ?? pageSizeOptions[0],
    page: options?.page ?? 0,
  });

  return [paginationModel, setPaginationModel] as const;
}
