import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import useLogoutManager from '../../hooks/useLogoutManager.ts';

export default function Logout() {
  const { t } = useTranslation(['manager']);
  const [logout] = useLogoutManager();

  useEffect(() => {
    const timer = setTimeout(logout, 2500);
    return () => {
      clearTimeout(timer);
    };
  }, [logout]);

  return (
    <Container
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: 3,
      }}>
      <Typography variant="h2" align="center">
        {t('logout.header')}
      </Typography>
      <Typography variant="h5" align="center">
        {t('logout.description')}
      </Typography>
    </Container>
  );
}
