import { Chip, Grid2 } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface Statistics {
  applications: number;
  applicationsAccepted: number;
  submissions: number;
  submissionsAccepted: number;
}

interface CampaignStatisticsProps {
  statistics: Statistics;
}

export default function CampaignStatistics({
  statistics,
}: CampaignStatisticsProps) {
  const { t } = useTranslation(['manager']);

  return (
    <Grid2 container spacing={1}>
      <Grid2 container size={12}>
        <Grid2 size="auto">
          <Chip
            label={t('campaign.statistics.applications', {
              count: statistics.applications,
            })}
            color="applied"
            variant="filled"
            size="small"
          />
        </Grid2>
        <Grid2 size="auto">
          <Chip
            label={t('campaign.statistics.applicationsAccepted', {
              count: statistics.applicationsAccepted,
            })}
            color="submissionPending"
            variant="filled"
            size="small"
          />
        </Grid2>
      </Grid2>
      <Grid2 container size={12}>
        <Grid2 size="auto">
          <Chip
            label={t('campaign.statistics.submissions', {
              count: statistics.submissions,
            })}
            color="submitted"
            variant="filled"
            size="small"
          />
        </Grid2>
        <Grid2 size="auto">
          <Chip
            label={t('campaign.statistics.submissionsAccepted', {
              count: statistics.submissionsAccepted,
            })}
            color="submissionAccepted"
            variant="filled"
            size="small"
          />
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
