import { MenuItem, Select, SelectProps } from '@mui/material';
import { CreatorHasCampaignStatus } from '@social-garden/utils/types';
import useCreatorHasCampaignStatus from '../hooks/useCreatorHasCampaignStatus.ts';

export default function CreatorHasCampaignStatusSelect<
  T extends CreatorHasCampaignStatus | CreatorHasCampaignStatus[],
>(props: Omit<SelectProps<T>, 'children'>) {
  const { getCreatorHasCampaignStatusName } = useCreatorHasCampaignStatus();

  return (
    <Select<T> {...props}>
      {Object.values(CreatorHasCampaignStatus).map((value) => (
        <MenuItem key={value} value={value}>
          {getCreatorHasCampaignStatusName(value)}
        </MenuItem>
      ))}
    </Select>
  );
}
