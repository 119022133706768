import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@apollo/client';
import { extendCampaignDeadlinesDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import { useCallback, useState } from 'react';
import { formatISO } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimelineArrow } from '@fortawesome/pro-regular-svg-icons/faTimelineArrow';
import { CampaignStatus } from '@social-garden/utils/types.ts';
import {
  ExtendCampaignDeadlinesFieldValues,
  ExtendCampaignDeadlinesSchema,
} from '../../constants/ValidationSchema.ts';
import { FormFieldProps } from '../../utils/types.ts';

interface ExtendCampaignDeadlinesMenuItemProps {
  campaign: {
    id: string;
    status: CampaignStatus;
    applyUntil: string;
    submitUntil: string;
  };
}

export default function ExtendCampaignDeadlinesMenuItem({
  campaign,
}: ExtendCampaignDeadlinesMenuItemProps) {
  const { t } = useTranslation(['common', 'manager']);
  const [open, setOpen] = useState<boolean>(false);

  const [extendCampaignDeadlines, { loading }] = useMutation(
    extendCampaignDeadlinesDocument,
  );

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<ExtendCampaignDeadlinesFieldValues>({
    resolver: zodResolver(ExtendCampaignDeadlinesSchema),
    mode: 'all',
    defaultValues: {
      applyUntil: new Date(
        new Date(campaign.applyUntil).setUTCHours(0, 0, 0, 0),
      ),
      submitUntil: new Date(
        new Date(campaign.submitUntil).setUTCHours(0, 0, 0, 0),
      ),
    },
  });

  const handleOnOpen = useCallback(() => setOpen(true), []);

  const handleOnClose = useCallback(() => setOpen(false), []);

  const renderApplyUntil = useCallback(
    ({
      field: { value, onChange },
      fieldState: { invalid, error },
      formState: { defaultValues },
    }: FormFieldProps<ExtendCampaignDeadlinesFieldValues, 'applyUntil'>) => (
      <DatePicker
        label={t('common:campaign.applyUntil')}
        defaultValue={value}
        timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        minDate={defaultValues?.applyUntil}
        slotProps={{
          textField: {
            fullWidth: true,
            required: true,
            error: invalid,
            helperText: error?.message,
          },
        }}
        onChange={onChange}
      />
    ),
    [t],
  );

  const renderSubmitUntil = useCallback(
    ({
      field: { value, onChange },
      fieldState: { invalid, error },
      formState: { defaultValues },
    }: FormFieldProps<ExtendCampaignDeadlinesFieldValues, 'submitUntil'>) => (
      <DatePicker
        label={t('common:campaign.submitUntil')}
        defaultValue={value}
        timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        minDate={defaultValues?.submitUntil}
        slotProps={{
          textField: {
            fullWidth: true,
            required: true,
            error: invalid,
            helperText: error?.message,
          },
        }}
        onChange={onChange}
      />
    ),
    [t],
  );

  const handleOnExtendCampaignDeadlines = useCallback(
    async ({ applyUntil, submitUntil }: ExtendCampaignDeadlinesFieldValues) => {
      if (campaign.status !== CampaignStatus.ACTIVE) {
        return;
      }
      const response = await extendCampaignDeadlines({
        variables: {
          input: {
            id: campaign.id,
            applyUntil: formatISO(applyUntil, { representation: 'date' }),
            submitUntil: formatISO(submitUntil, {
              representation: 'date',
            }),
          },
        },
      });
      if (response.data) {
        setOpen(false);
      }
    },
    [campaign.id, campaign.status, extendCampaignDeadlines],
  );

  if (campaign.status !== CampaignStatus.ACTIVE) {
    return null;
  }

  return (
    <>
      <MenuItem onClick={handleOnOpen}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faTimelineArrow} />
        </ListItemIcon>
        <ListItemText>
          {t('manager:campaign.extendDeadlines.menuItem')}
        </ListItemText>
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleOnClose}
        PaperProps={{
          component: 'form',
          noValidate: true,
          onSubmit: handleSubmit(handleOnExtendCampaignDeadlines),
        }}>
        <DialogTitle>{t('manager:campaign.extendDeadlines.title')}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              {t('manager:campaign.extendDeadlines.description')}
            </DialogContentText>
            <Alert severity="info">
              {t('manager:campaign.extendDeadlines.exception')}
            </Alert>
            <Stack pt={1} direction="row" spacing={2}>
              <Controller<ExtendCampaignDeadlinesFieldValues, 'applyUntil'>
                name="applyUntil"
                control={control}
                render={renderApplyUntil}
              />
              <Controller<ExtendCampaignDeadlinesFieldValues, 'submitUntil'>
                name="submitUntil"
                control={control}
                render={renderSubmitUntil}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleOnClose}>
            {t('common:cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!isValid || loading}>
            {t('common:update')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
