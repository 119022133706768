import { getFeedback } from '@sentry/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/pro-regular-svg-icons/faBullhorn';
import { IconButton, IconButtonProps } from '@mui/material';
import { useCallback } from 'react';

export default function FeedbackButton({
  sx,
  style,
  size,
  color,
}: Pick<IconButtonProps, 'sx' | 'style' | 'size' | 'color'>) {
  const feedback = getFeedback();

  const handleOnClick = useCallback(async () => {
    if (feedback) {
      const form = await feedback.createForm();
      form.appendToDom();
      form.open();
    }
  }, [feedback]);

  if (!feedback) {
    return null;
  }

  return (
    <IconButton
      sx={sx}
      style={style}
      size={size}
      color={color}
      aria-label="Feedback button"
      onClick={handleOnClick}>
      <FontAwesomeIcon icon={faBullhorn} />
    </IconButton>
  );
}
