import { Container } from '@mui/material';
import { useSuspenseQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { campaignWithStatisticsDocument } from '@social-garden/api/documents/campaign.ts';
import CampaignDetailsHeader from './CampaignDetailsHeader.tsx';
import CreatorsOfCampaign from './CreatorsOfCampaign.tsx';

export default function CampaignDetails() {
  const { id } = useParams();

  const { data } = useSuspenseQuery(campaignWithStatisticsDocument, {
    variables: {
      id: id ?? '',
    },
  });

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
      }}>
      <CampaignDetailsHeader campaign={data.campaign} />
      <CreatorsOfCampaign campaignId={data.campaign.id} />
    </Container>
  );
}
