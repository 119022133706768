import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CategorySelect from './CategorySelect.tsx';
import { FormFieldProps } from '../utils/types.ts';

export default function CategoryField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  field: { name, value, onChange },
  fieldState: { error, invalid },
}: FormFieldProps<TFieldValues, TName>) {
  const { t } = useTranslation(['common']);
  return (
    <FormControl required fullWidth>
      <InputLabel id="category-label">{t('common:category')}</InputLabel>
      <CategorySelect
        variant="outlined"
        labelId="category-label"
        fullWidth
        label={t('common:category')}
        name={name}
        value={value ?? ''}
        error={invalid}
        onChange={onChange}
      />
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
}
