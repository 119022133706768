import { createTheme, responsiveFontSizes, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BLUE,
  TEAL,
  GREEN,
  PURPLE,
  RED,
  GRAY,
} from '@social-garden/utils/colors.ts';
import { ThemeOptions } from '@mui/material/styles';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { enUS as datePickerEnUS } from '@mui/x-date-pickers/locales/enUS';
import { enUS as dataGridEnUS } from '@mui/x-data-grid/locales/enUS';
import { deDE as coreDeDE, enUS as coreEnUS } from '@mui/material/locale';
import { deDE as datePickerDeDE } from '@mui/x-date-pickers/locales/deDE';
import { deDE as dataGridDeDE } from '@mui/x-data-grid/locales/deDE';
import useColorMode from './useColorMode.ts';
import {
  darkThemeOptions,
  lightThemeOptions,
} from '../constants/ThemeOptions.ts';

const MUI_RESOURCES: { [key: string]: object[] } = {
  en: [datePickerEnUS, dataGridEnUS, coreEnUS],
  de: [datePickerDeDE, dataGridDeDE, coreDeDE],
};

export default function useCreateTheme() {
  const {
    i18n: { resolvedLanguage, languages },
  } = useTranslation(['manager']);
  const { mode } = useColorMode();
  const systemPrefersDarkColor = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(() => {
    let colorThemeOptions: ThemeOptions;

    switch (mode) {
      case 'light':
        colorThemeOptions = lightThemeOptions;
        break;
      case 'dark':
        colorThemeOptions = darkThemeOptions;
        break;
      case 'system':
        colorThemeOptions = systemPrefersDarkColor
          ? darkThemeOptions
          : lightThemeOptions;
        break;
      default:
        assertUnreachable(mode);
    }

    return createTheme(
      colorThemeOptions,
      ...MUI_RESOURCES[resolvedLanguage ?? languages[0]],
    );
  }, [mode, resolvedLanguage, languages, systemPrefersDarkColor]);

  return useMemo(
    () =>
      responsiveFontSizes(
        createTheme(theme, {
          palette: {
            applied: theme.palette.augmentColor({
              color: {
                ...BLUE,
                main: BLUE['600'],
              },
              name: 'applied',
            }),
            submissionPending: theme.palette.augmentColor({
              color: {
                ...TEAL,
                main: TEAL['600'],
              },
              name: 'submissionPending',
            }),
            submitted: theme.palette.augmentColor({
              color: {
                ...PURPLE,
                main: PURPLE['600'],
              },
              name: 'submitted',
            }),
            submissionAccepted: theme.palette.augmentColor({
              color: {
                ...GREEN,
                main: GREEN['600'],
              },
              name: 'submissionAccepted',
            }),
            reportingCompleted: theme.palette.augmentColor({
              color: {
                ...GREEN,
                main: GREEN['700'],
              },
              name: 'reportingCompleted',
            }),
            declined: theme.palette.augmentColor({
              color: {
                ...RED,
                main: RED['600'],
              },
              name: 'declined',
            }),
            cancelled: theme.palette.augmentColor({
              color: {
                ...GRAY,
                main: GRAY['600'],
              },
              name: 'cancelled',
            }),
            failed: theme.palette.augmentColor({
              color: {
                ...RED,
                main: RED['600'],
              },
              name: 'failed',
            }),
            reportingFailed: theme.palette.augmentColor({
              color: {
                ...RED,
                main: RED['600'],
              },
              name: 'reportingFailed',
            }),
          },
        }),
      ),
    [theme],
  );
}
