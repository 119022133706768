import { Stack, StackProps, Typography } from '@mui/material';

interface SectionProps extends StackProps {
  header: string;
  subHeader?: string;
}

export default function Section({
  header,
  subHeader,
  children,
  spacing = 2,
  ...rest
}: SectionProps) {
  return (
    <Stack spacing={spacing} {...rest}>
      <Stack>
        <Typography fontWeight={500}>{header}</Typography>
        {subHeader ? (
          <Typography variant="body2" color="text.secondary">
            {subHeader}
          </Typography>
        ) : null}
      </Stack>
      {children}
    </Stack>
  );
}
