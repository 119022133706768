import { Chip, Grid2 } from '@mui/material';
import {
  Download,
  CampaignStatus,
  CampaignTypeable,
} from '@social-garden/utils/types.ts';
import {
  getChannelTypeColor,
  getChannelTypeName,
} from '@social-garden/utils/channel.ts';
import { getContrastColor } from '@social-garden/utils/helpers.ts';
import { getContentTypeNameFromCampaignType } from '@social-garden/utils/content.ts';
import PageTitle from '../../components/PageTitle.tsx';
import Brand from '../../components/Brand.tsx';
import useCampaignStatus from '../../hooks/useCampaignStatus.ts';
import CampaignStatistics, {
  Statistics,
} from '../../components/CampaignStatistics.tsx';
import CampaignDetailsButtonGroup, {
  CampaignDetailsButtonGroupProps,
} from './CampaignDetailsButtonGroup.tsx';

type CampaignDetailsHeaderProps = CampaignDetailsButtonGroupProps & {
  campaign: {
    id: string;
    name: string;
    brand: {
      name: string;
      logo: Download;
    };
    category: {
      name: string;
    };
    status: CampaignStatus;
    typeable: CampaignTypeable;
    statistics?: Statistics | null;
  };
};

export default function CampaignDetailsHeader({
  campaign,
}: CampaignDetailsHeaderProps) {
  const { getCampaignStatusName } = useCampaignStatus();

  return (
    <Grid2 container spacing={2}>
      <Grid2 container alignItems="center" size={12}>
        <Grid2
          size={{
            xs: 12,
            md: 'grow',
          }}>
          <PageTitle>{campaign.name}</PageTitle>
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 'auto',
          }}>
          <CampaignDetailsButtonGroup campaign={campaign} />
        </Grid2>
      </Grid2>
      <Grid2 size={12}>
        <Brand name={campaign.brand.name} logo={campaign.brand.logo} />
      </Grid2>
      <Grid2 container spacing={1} size={12}>
        <Grid2 size="auto">
          <Chip
            sx={{
              backgroundColor: getChannelTypeColor(
                campaign.typeable.channelType,
              ),
              color: getContrastColor(
                getChannelTypeColor(campaign.typeable.channelType),
              ),
            }}
            label={getChannelTypeName(campaign.typeable.channelType)}
            variant="filled"
            size="small"
          />
        </Grid2>
        <Grid2 size="auto">
          <Chip
            sx={{
              backgroundColor: getChannelTypeColor(
                campaign.typeable.channelType,
              ),
              color: getContrastColor(
                getChannelTypeColor(campaign.typeable.channelType),
              ),
            }}
            label={getContentTypeNameFromCampaignType(campaign.typeable)}
            variant="filled"
            size="small"
          />
        </Grid2>
        <Grid2 size="auto">
          <Chip label={campaign.category.name} variant="filled" size="small" />
        </Grid2>
        <Grid2 size="auto">
          <Chip
            label={getCampaignStatusName(campaign.status)}
            variant="filled"
            size="small"
          />
        </Grid2>
        {campaign.statistics ? (
          <Grid2 size={12}>
            <CampaignStatistics statistics={campaign.statistics} />
          </Grid2>
        ) : null}
      </Grid2>
    </Grid2>
  );
}
