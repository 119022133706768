import {
  CampaignTypeable,
  CreatorHasCampaignStatus,
} from '@social-garden/utils/types.ts';
import { Stack } from '@mui/material';
import ContentPreview from '../../components/ContentPreview.tsx';
import CreatorHasCampaignContentFile from './CreatorHasCampaignContentFile.tsx';

export interface CampaignGoalSubmissionProps {
  creatorHasCampaign: {
    id: string;
    status: CreatorHasCampaignStatus;
    contentUrl?: string | null;
    campaign: {
      includeContentFileOnSubmission: boolean;
      typeable: CampaignTypeable;
    };
  };
}

export default function CampaignGoalSubmission({
  creatorHasCampaign,
}: CampaignGoalSubmissionProps) {
  return (
    <Stack spacing={2}>
      <CreatorHasCampaignContentFile creatorHasCampaign={creatorHasCampaign} />
      {creatorHasCampaign.contentUrl ? (
        <ContentPreview
          src={creatorHasCampaign.contentUrl}
          channelType={creatorHasCampaign.campaign.typeable.channelType}
        />
      ) : null}
    </Stack>
  );
}
