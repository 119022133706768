import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  cloneElement,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

interface ChildComponentProps {
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  children?: ReactNode | undefined;
  onClick: MouseEventHandler;
}

interface ConfirmProps {
  title: string;
  description: string;
  buttonText?: string;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  children: ReactElement<ChildComponentProps>;
}

export default function Confirm({
  title,
  description,
  buttonText,
  color,
  children,
}: ConfirmProps) {
  const { t } = useTranslation(['common']);
  const [open, setOpen] = useState(false);

  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOnAgreeClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      setOpen(false);
      children.props.onClick(event);
    },
    [children.props],
  );

  const childWithOnClickProp = useMemo(
    () =>
      cloneElement(children, {
        onClick: () => {
          setOpen(true);
        },
      }),
    [children],
  );

  const confirmButtonText = useMemo(() => {
    if (buttonText) {
      return buttonText;
    }
    if (
      typeof children.props.children === 'string' ||
      typeof children.props.children === 'number'
    ) {
      return children.props.children;
    }
    return t('common:accept');
  }, [buttonText, children.props.children, t]);

  return (
    <>
      {childWithOnClickProp}
      <Dialog
        open={open}
        onClose={handleOnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={handleOnClose}>
            {t('common:cancel')}
          </Button>
          <Button
            variant="contained"
            color={children.props.color ? children.props.color : color}
            onClick={handleOnAgreeClick}>
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
