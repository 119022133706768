import { MenuItem, Select, SelectProps } from '@mui/material';
import { CampaignStatus } from '@social-garden/utils/types.ts';
import useCampaignStatus from '../hooks/useCampaignStatus.ts';

export default function CampaignStatusSelect(
  props: Omit<SelectProps<CampaignStatus[]>, 'children'>,
) {
  const { getCampaignStatusName } = useCampaignStatus();

  return (
    <Select<CampaignStatus[]> {...props}>
      {Object.values(CampaignStatus).map((value) => (
        <MenuItem key={value} value={value}>
          {getCampaignStatusName(value)}
        </MenuItem>
      ))}
    </Select>
  );
}
