import { useSuspenseQuery } from '@apollo/client';
import { Container, Stack, Tab, Tabs } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { creatorHasCampaignDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import CreatorHasCampaignHeader from './CreatorHasCampaignHeader.tsx';
import CreatorHasCampaignFlow from './CreatorHasCampaignFlow.tsx';
import CreatorHasCampaignMessages from './CreatorHasCampaignMessages.tsx';

export default function CreatorHasCampaign() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation(['manager']);
  const [activeTabIndex, setActiveTabIndex] = useState<0 | 1>(() => {
    const value = searchParams.get('tab-index');
    if (value) {
      if (parseInt(value, 10) === 0) {
        return 0;
      }
      if (parseInt(value, 10) === 1) {
        return 1;
      }
    }
    return 0;
  });

  const {
    data: { creatorHasCampaign },
  } = useSuspenseQuery(creatorHasCampaignDocument, {
    variables: {
      id: id ?? '',
    },
  });

  const tabContent = useMemo(() => {
    switch (activeTabIndex) {
      case 0:
        return (
          <CreatorHasCampaignFlow creatorHasCampaign={creatorHasCampaign} />
        );
      case 1:
        return (
          <CreatorHasCampaignMessages creatorHasCampaign={creatorHasCampaign} />
        );
      default:
        assertUnreachable(activeTabIndex);
    }
  }, [activeTabIndex, creatorHasCampaign]);

  const handleOnActiveTabIndexChange = useCallback(
    (_: SyntheticEvent, value: 0 | 1) => {
      setActiveTabIndex(value);
      setSearchParams({
        'tab-index': value.toString(),
      });
    },
    [setSearchParams],
  );

  return (
    <Container>
      <Stack spacing={3}>
        <CreatorHasCampaignHeader creatorHasCampaign={creatorHasCampaign} />
        <Tabs
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          value={activeTabIndex}
          variant="fullWidth"
          onChange={handleOnActiveTabIndexChange}>
          <Tab
            label={t('manager:creatorHasCampaign.tab.campaignFlow')}
            value={0}
          />
          <Tab label={t('manager:creatorHasCampaign.tab.messages')} value={1} />
        </Tabs>
        <Stack>{tabContent}</Stack>
      </Stack>
    </Container>
  );
}
