import { RouteObject } from 'react-router-dom';
import Campaigns from '../pages/Campaigns/Campaigns.tsx';
import CreateCampaign from '../pages/CreateCampaign/CreateCampaign.tsx';
import UpdateCampaign from '../pages/UpdateCampaign/UpdateCampaign.tsx';
import CampaignDetails from '../pages/CampaignDetails/CampaignDetails.tsx';
import CreatorHasCampaign from '../pages/CreatorHasCampaign/CreatorHasCampaign.tsx';
import Brands from '../pages/Brands/Brands.tsx';
import UpdateBrand from '../pages/UpdateBrand/UpdateBrand.tsx';
import CreateBrand from '../pages/CreateBrand/CreateBrand.tsx';
import Settings from '../pages/Settings/Settings.tsx';
import CampaignReports from '../pages/CampaignReports/CampaignReports.tsx';
import CampaignReport from '../pages/CampaignReport/CampaignReport.tsx';
import Dashboard from '../pages/Dashboard/Dashboard.tsx';
import AppLayout from '../layouts/AppLayout.tsx';

const AppRoutes: RouteObject = {
  element: <AppLayout />,
  children: [
    {
      index: true,
      path: '',
      element: <Dashboard />,
    },
    {
      path: 'campaigns',
      element: <Campaigns />,
    },
    {
      path: 'create-campaign',
      element: <CreateCampaign />,
    },
    {
      path: 'update-campaign/:id',
      element: <UpdateCampaign />,
    },
    {
      path: 'campaign-details/:id',
      element: <CampaignDetails />,
    },
    {
      path: 'creator-has-campaign/:id',
      element: <CreatorHasCampaign />,
    },
    {
      path: 'campaign-reports',
      element: <CampaignReports />,
    },
    {
      path: 'campaign-report/:id',
      element: <CampaignReport />,
    },
    {
      path: 'brands',
      element: <Brands />,
    },
    {
      path: 'create-brand',
      element: <CreateBrand />,
    },
    {
      path: 'update-brand/:id',
      element: <UpdateBrand />,
    },
    {
      path: 'settings',
      element: <Settings />,
    },
  ],
};

export default AppRoutes;
