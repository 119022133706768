import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CampaignGiftType } from '@social-garden/utils/types.ts';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';

export default function useCampaignGiftType() {
  const { t } = useTranslation(['common']);

  const getCampaignGiftTypeName = useCallback(
    (type: CampaignGiftType) => {
      switch (type) {
        case CampaignGiftType.CODE:
          return t('common:campaignGiftType.code');
        case CampaignGiftType.PARCEL:
          return t('common:campaignGiftType.parcel');
        default:
          assertUnreachable(type);
      }
    },
    [t],
  );

  return { getCampaignGiftTypeName };
}
