import {
  convertHexToRGBA,
  getInitials,
  getUriWithHash,
} from '@social-garden/utils/helpers.ts';
import { Avatar } from '@mui/material';
import { OPACITY } from '@social-garden/utils/opacity.ts';

interface ProfilePictureProps {
  username: string;
  src?: {
    uri: string;
    hash?: string | null;
  } | null;
  size?: number;
}

export default function ProfilePicture({
  username,
  src,
  size = 40,
}: ProfilePictureProps) {
  return (
    <Avatar
      sx={{
        backgroundColor: (theme) =>
          convertHexToRGBA(theme.palette.primary.main, OPACITY.low),
        color: (theme) => theme.palette.primary.main,
        borderColor: (theme) => theme.palette.primary.main,
        borderWidth: src ? 0 : 0.5,
        borderStyle: 'solid',
        width: size,
        height: size,
        fontSize: size / 2.2,
      }}
      alt={username}
      src={src ? getUriWithHash(src) : undefined}>
      {getInitials(username)}
    </Avatar>
  );
}
