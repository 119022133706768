import { Stack, StackProps, Typography } from '@mui/material';

interface ChannelStatisticItemProps extends StackProps {
  label: string;
  value: string | number;
}

export default function ChannelStatisticItem({
  label,
  value,
  ...rest
}: ChannelStatisticItemProps) {
  return (
    <Stack alignItems="center" {...rest}>
      <Typography
        variant="body1"
        lineHeight="normal"
        fontWeight={500}
        textAlign="center">
        {value}
      </Typography>
      <Typography
        variant="body2"
        lineHeight="normal"
        color="text.secondary"
        textAlign="center">
        {label}
      </Typography>
    </Stack>
  );
}
