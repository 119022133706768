import { graphql } from '../gql';

export const subscriptionPlansDocument = graphql(`
  query subscriptionPlans {
    subscriptionPlans {
      id
      name
      prices {
        id
        amount
        recurringInterval
        lookupKey
      }
      bookingFeePercentage
      minBookingFee
      maxBrandCount
      maxCampaignCount
      storageSpaceLimit
    }
  }
`);
