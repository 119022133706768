import { ReactNode, useCallback, useState } from 'react';
import { ColorMode, ColorModeContext } from '../contexts/colorModeContext.ts';

interface ColorModeProviderProps {
  storageKey: string;
  children?: ReactNode;
}

export default function ColorModeProvider({
  storageKey,
  children,
}: ColorModeProviderProps) {
  const [mode, setMode] = useState<ColorMode>(() => {
    const value = localStorage.getItem(storageKey);
    if (value === 'light') {
      return 'light';
    }
    if (value === 'dark') {
      return 'dark';
    }
    return 'system';
  });

  const set = useCallback(
    (value: ColorMode) => {
      localStorage.setItem(storageKey, value);
      setMode(value);
    },
    [storageKey],
  );

  return (
    <ColorModeContext.Provider
      value={{
        mode,
        setMode: set,
      }}>
      {children}
    </ColorModeContext.Provider>
  );
}
