import { graphql } from '../gql';

export const brandsDocument = graphql(`
  query brands {
    whoAmI {
      ... on Manager {
        id
        company {
          id
          brands {
            ...brand
          }
        }
      }
    }
  }
`);

export const brandDocument = graphql(`
  query brand($id: ID!) {
    brand(id: $id) {
      ...brand
    }
  }
`);

export const createBrandDocument = graphql(`
  mutation createBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      ...brand
    }
  }
`);

export const updateBrandDocument = graphql(`
  mutation updateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      ...brand
    }
  }
`);

export const updateBrandLogoDocument = graphql(`
  mutation updateBrandLogo($input: UpdateBrandLogoInput!) {
    updateBrandLogo(input: $input) {
      ...brand
    }
  }
`);
