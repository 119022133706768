import { Container, Stack } from '@mui/material';
import DashboardContent from './DashboardContent.tsx';
import DashboardHeader from './DashboardHeader.tsx';

export default function Dashboard() {
  return (
    <Stack flex={1} my={-4} pb={4}>
      <DashboardHeader />
      <Container
        sx={{
          p: 4,
        }}>
        <DashboardContent />
      </Container>
    </Stack>
  );
}
