import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InstagramChannelInsights, {
  InstagramChannelInsightsData,
} from './InstagramChannelInsights.tsx';

interface InstagramChannelInsightsDialogProps {
  data: InstagramChannelInsightsData;
}

export default function InstagramChannelInsightsDialog({
  data,
}: InstagramChannelInsightsDialogProps) {
  const { t } = useTranslation(['common', 'manager']);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button variant="text" size="small" onClick={handleOpen}>
        {t('manager:channelInsights.button')}
      </Button>
      <Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose}>
        <DialogTitle>{t('manager:channelInsights.title')}</DialogTitle>
        <DialogContent>
          <InstagramChannelInsights data={data} />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            {t('common:done')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
