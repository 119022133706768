import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import AuthToken from '../../constants/AuthToken.ts';
import SuspenseFallback from '../../components/SuspenseFallback.tsx';
import { persistor } from '../../utils/apolloInMemoryCache.ts';

export default function Impersonate() {
  const { token } = useParams();
  const client = useApolloClient();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (token && token.length > 0) {
        AuthToken.setToken(token);
        await persistor.purge();
        await client.clearStore();
      }
      navigate('/');
    })();
  }, [client, navigate, token]);

  return <SuspenseFallback />;
}
