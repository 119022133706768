import {
  Box,
  Button,
  ButtonProps,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Toolbar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars';
import { faMemoPad } from '@fortawesome/pro-regular-svg-icons/faMemoPad';
import { faChartMixed } from '@fortawesome/pro-regular-svg-icons/faChartMixed';
import { faGearComplex } from '@fortawesome/pro-regular-svg-icons/faGearComplex';
import { faCopyright } from '@fortawesome/pro-regular-svg-icons/faCopyright';
import AnimatedAppBar from './AnimatedAppBar.tsx';
import ProfileIconButton from './ProfileIconButton.tsx';
import FeedbackButton from './FeedbackButton.tsx';

const NavigationButton = styled(Button)<ButtonProps>(() => ({
  textTransform: 'none',
  color: 'inherit',
}));

interface NavigationProps {
  manager: {
    email: string;
    company: {
      name: string;
    };
  };
  setupCompleted: boolean;
}

export default function Navigation({
  manager,
  setupCompleted,
}: NavigationProps) {
  const { t } = useTranslation(['manager']);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const navigationItems = useMemo(() => {
    if (!setupCompleted) {
      return [];
    }
    return [
      {
        label: t('navigation.campaigns'),
        icon: faMemoPad,
        path: '/campaigns',
      },
      {
        label: t('navigation.reports'),
        icon: faChartMixed,
        path: '/campaign-reports',
      },
      {
        label: t('navigation.brands'),
        icon: faCopyright,
        path: '/brands',
      },
      {
        label: t('navigation.settings'),
        icon: faGearComplex,
        path: '/settings',
      },
    ];
  }, [setupCompleted, t]);

  const handleOpenDrawer = useCallback(() => setDrawerOpen(true), []);

  const handleCloseDrawer = useCallback(() => setDrawerOpen(false), []);

  return (
    <>
      <AnimatedAppBar>
        <Toolbar variant="dense">
          <Box flex={1}>
            <Stack
              sx={{
                display: {
                  xs: 'flex',
                  md: 'none',
                },
              }}
              flex={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}>
              {navigationItems.length > 0 ? (
                <IconButton color="inherit" onClick={handleOpenDrawer}>
                  <FontAwesomeIcon icon={faBars} />
                </IconButton>
              ) : null}
              <NavigationButton href="/" translate="no">
                {manager.company.name}
              </NavigationButton>
              <ProfileIconButton email={manager.email} />
            </Stack>
            <Stack
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex',
                },
              }}
              flex={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={4}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <NavigationButton href="/" translate="no">
                  {manager.company.name}
                </NavigationButton>
                {navigationItems.map(({ label, path }) => (
                  <NavigationButton key={path} href={path}>
                    {label}
                  </NavigationButton>
                ))}
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <FeedbackButton size="small" color="inherit" />
                <ProfileIconButton email={manager.email} />
              </Stack>
            </Stack>
          </Box>
        </Toolbar>
      </AnimatedAppBar>
      <Drawer open={drawerOpen} onClose={handleCloseDrawer}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={handleCloseDrawer}>
          <List>
            {navigationItems.map(({ label, icon, path }) => (
              <ListItem key={path} disablePadding>
                <ListItemButton href={path}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={icon} size="lg" fixedWidth />
                  </ListItemIcon>
                  <ListItemText primary={label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Toolbar variant="dense" />
    </>
  );
}
