import { useEffect, useState } from 'react';
import { getOEmbedUrl } from '@social-garden/utils/oEmbed.ts';
import { ChannelType } from '@social-garden/utils/types.ts';

export default function useOEmbed(
  url: string,
  channelType: ChannelType,
  options: { maxWidth?: number; maxHeight?: number } = {},
) {
  const [data, setData] = useState<{
    html: string;
    title?: string;
    width?: number | string;
    height?: number | string;
  }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          getOEmbedUrl({
            channelType,
            url,
            accessToken: `${import.meta.env.VITE_FACEBOOK_APP_ID}|${
              import.meta.env.VITE_FACEBOOK_CLIENT_TOKEN
            }`,
            maxWidth: options.maxWidth,
            maxHeight: options.maxHeight,
          }),
          {
            mode: 'cors',
          },
        );
        if (!response.ok) {
          setError(`${response.status}: ${response.statusText}`);
          setData(undefined);
          setLoading(false);
          return;
        }
        const { html, title, width, height } = await response.json();
        setError(undefined);
        setData({ html, title, width, height });
        setLoading(false);
      } catch (e) {
        setError('Something went wrong!');
        setData(undefined);
        setLoading(false);
      }
    })();
  }, [url, options.maxWidth, options.maxHeight, channelType]);

  return {
    data,
    loading,
    error,
  };
}
