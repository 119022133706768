import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare';
import { Button, Card, Link, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChannelType } from '@social-garden/utils/types.ts';
import useOEmbed from '../hooks/useOEmbed.ts';

interface ContentPreviewProps {
  src: string;
  channelType: ChannelType;
}

export default function ContentPreview({
  src,
  channelType,
}: ContentPreviewProps) {
  const { t } = useTranslation(['manager']);
  const { data, loading } = useOEmbed(src, channelType, {
    maxWidth: 800,
    maxHeight: 800,
  });

  if (loading) {
    return <Skeleton variant="rounded" width={800} height={800} />;
  }

  if (data) {
    return (
      <Stack spacing={0.5}>
        <iframe
          style={{
            width: data.width || '100%',
            height: data.height || 800,
            maxWidth: '100%',
            border: 0,
            margin: 0,
            padding: 0,
          }}
          srcDoc={data.html}
          title={data.title ?? src}
        />
        <Stack direction="row">
          <Link href={src} target="_blank" variant="caption">
            <FontAwesomeIcon
              style={{ marginRight: 4 }}
              icon={faArrowUpRightFromSquare}
            />
            {src}
          </Link>
        </Stack>
      </Stack>
    );
  }

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        maxWidth: 800,
        aspectRatio: 1,
        p: 4,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        background: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
      }}
      variant="outlined">
      <Typography variant="h5" align="center">
        {t('manager:contentPreview.unavailable.header')}
      </Typography>
      <Button
        variant="outlined"
        href={src}
        target="_blank"
        startIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}>
        {t('manager:contentPreview.unavailable.openContentButton')}
      </Button>
    </Card>
  );
}
