import { convertHexToRGBA } from '@social-garden/utils/helpers.ts';
import { OPACITY } from '@social-garden/utils/opacity.ts';
import { AppBar, AppBarProps } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

export default function AnimatedAppBar({ sx, children, ...rest }: AppBarProps) {
  const [isScrolledDown, setIsScrolledDown] = useState<boolean>(
    window.scrollY > 0,
  );

  const handleScroll = useCallback(() => {
    setIsScrolledDown(window.scrollY > 0);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <AppBar
      sx={{
        backgroundColor: (theme) => {
          if (isScrolledDown) {
            return convertHexToRGBA(
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
              OPACITY.high,
            );
          }
          return theme.palette.background.default;
        },
        color: (theme) => theme.palette.text.primary,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderBottomColor: (theme) =>
          isScrolledDown ? theme.palette.divider : 'transparent',
        transition:
          'background-color 250ms ease-in-out, border-bottom-color 250ms ease-in-out, backdrop-filter 250ms ease-in-out',
        backdropFilter: 'blur(10px)',
        ...sx,
      }}
      square
      position="fixed"
      variant="outlined"
      elevation={0}
      {...rest}>
      {children}
    </AppBar>
  );
}
