import {
  Button,
  Dialog,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Grid2,
} from '@mui/material';
import { Suspense, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMemoPad } from '@fortawesome/pro-regular-svg-icons/faMemoPad';
import { useTranslation } from 'react-i18next';
import CampaignBriefing from './CampaignBriefing.tsx';
import SuspenseFallback from '../../components/SuspenseFallback.tsx';

interface CampaignBriefingMenuItemProps {
  campaign: {
    id: string;
  };
}

export default function CampaignBriefingMenuItem({
  campaign,
}: CampaignBriefingMenuItemProps) {
  const { t } = useTranslation(['common', 'manager']);
  const [open, setOpen] = useState<boolean>(false);

  const handleOnOpen = useCallback(() => setOpen(true), []);

  const handleOnClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <MenuItem onClick={handleOnOpen}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faMemoPad} />
        </ListItemIcon>
        <ListItemText>{t('manager:campaign.briefing')}</ListItemText>
      </MenuItem>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        scroll="body"
        onClose={handleOnClose}>
        <Suspense fallback={<SuspenseFallback />}>
          <CampaignBriefing campaignId={campaign.id}>
            <Grid2 size="auto" offset="auto">
              <Button color="inherit" onClick={handleOnClose}>
                {t('common:done')}
              </Button>
            </Grid2>
          </CampaignBriefing>
        </Suspense>
      </Dialog>
    </>
  );
}
