import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function useCopyToClipboard() {
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = useCallback<(text: string) => Promise<void>>(
    async (text) => {
      if ('clipboard' in navigator) {
        try {
          await navigator.clipboard.writeText(text);
          enqueueSnackbar(t('copyToClipboard.success'), {
            variant: 'success',
          });
        } catch {
          enqueueSnackbar(t('copyToClipboard.error'), {
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar(t('copyToClipboard.notSupported'), {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, t],
  );

  return [handleCopy] as const;
}
