import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/pro-light-svg-icons/faCopyright';
import { Typography, TypographyProps } from '@mui/material';

export default function Copyright({
  sx,
  variant = 'caption',
  ...rest
}: Omit<TypographyProps, 'children'>) {
  return (
    <Typography
      sx={{
        color: (theme) => theme.palette.text.disabled,
        userSelect: 'none',
        pointerEvents: 'none',
        ...sx,
      }}
      variant={variant}
      {...rest}>
      <FontAwesomeIcon style={{ marginRight: 3 }} icon={faCopyright} />
      {`Social Quest GmbH ${new Date().getFullYear()}`}
    </Typography>
  );
}
