import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { assertUnreachable } from '@social-garden/utils/helpers';
import { CreatorHasCampaignStatus } from '@social-garden/utils/types';

export default function useCreatorHasCampaignStatus() {
  const { t } = useTranslation(['common']);

  const getCreatorHasCampaignStatusName = useCallback(
    (status: CreatorHasCampaignStatus) => {
      switch (status) {
        case CreatorHasCampaignStatus.APPLIED:
          return t('common:creatorHasCampaignStatus.applied');
        case CreatorHasCampaignStatus.SUBMISSION_PENDING:
          return t('common:creatorHasCampaignStatus.submissionPending');
        case CreatorHasCampaignStatus.SUBMITTED:
          return t('common:creatorHasCampaignStatus.submitted');
        case CreatorHasCampaignStatus.SUBMISSION_ACCEPTED:
          return t('common:creatorHasCampaignStatus.submissionAccepted');
        case CreatorHasCampaignStatus.DECLINED:
          return t('common:creatorHasCampaignStatus.declined');
        case CreatorHasCampaignStatus.FAILED:
          return t('common:creatorHasCampaignStatus.failed');
        case CreatorHasCampaignStatus.CANCELLED:
          return t('common:creatorHasCampaignStatus.cancelled');
        case CreatorHasCampaignStatus.REPORTING_COMPLETED:
          return t('common:creatorHasCampaignStatus.reportingCompleted');
        case CreatorHasCampaignStatus.REPORTING_FAILED:
          return t('common:creatorHasCampaignStatus.reportingFailed');
        default:
          assertUnreachable(status);
      }
    },
    [t],
  );

  const getCreatorHasCampaignStatusColor = useCallback(
    (status: CreatorHasCampaignStatus) => {
      switch (status) {
        case CreatorHasCampaignStatus.APPLIED:
          return 'applied';
        case CreatorHasCampaignStatus.SUBMISSION_PENDING:
          return 'submissionPending';
        case CreatorHasCampaignStatus.SUBMITTED:
          return 'submitted';
        case CreatorHasCampaignStatus.SUBMISSION_ACCEPTED:
          return 'submissionAccepted';
        case CreatorHasCampaignStatus.DECLINED:
          return 'declined';
        case CreatorHasCampaignStatus.FAILED:
          return 'failed';
        case CreatorHasCampaignStatus.CANCELLED:
          return 'cancelled';
        case CreatorHasCampaignStatus.REPORTING_COMPLETED:
          return 'reportingCompleted';
        case CreatorHasCampaignStatus.REPORTING_FAILED:
          return 'reportingFailed';
        default:
          assertUnreachable(status);
      }
    },
    [],
  );

  return {
    getCreatorHasCampaignStatusName,
    getCreatorHasCampaignStatusColor,
  };
}
