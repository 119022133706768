import {
  Button,
  ButtonGroup,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { CampaignStatus } from '@social-garden/utils/types.ts';
import { isPast, isToday } from 'date-fns';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faPen } from '@fortawesome/pro-regular-svg-icons/faPen';
import { useTranslation } from 'react-i18next';
import { MouseEventHandler, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Confirm from '../../components/Confirm.tsx';
import useCampaignUsage from '../../hooks/useCampaignUsage.ts';
import useStorageSpaceUsage from '../../hooks/useStorageSpaceUsage.ts';
import useDuplicateCampaign from '../../hooks/useDuplicateCampaign.ts';
import usePublishCampaign from '../../hooks/usePublishCampaign.ts';
import ExtendCampaignDeadlinesMenuItem from './ExtendCampaignDeadlinesMenuItem.tsx';
import ShareCampaignMenuItem from './ShareCampaignMenuItem.tsx';
import CampaignBriefingMenuItem from './CampaignBriefingMenuItem.tsx';
import CompleteCampaignMenuItem from './CompleteCampaignMenuItem.tsx';
import DeleteCampaignMenuItem from './DeleteCampaignMenuItem.tsx';

export interface CampaignDetailsButtonGroupProps {
  campaign: {
    id: string;
    applyUntil: string;
    submitUntil: string;
    includeContentFileOnSubmission: boolean;
    status: CampaignStatus;
  };
}

export default function CampaignDetailsButtonGroup({
  campaign,
}: CampaignDetailsButtonGroupProps) {
  const { t } = useTranslation(['common', 'manager']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const { exceeded: campaignUsageExceeded } = useCampaignUsage();
  const { exceeded: storageSpaceUsageExceeded } = useStorageSpaceUsage();

  const [duplicateCampaign, { loading: duplicateCampaignLoading }] =
    useDuplicateCampaign();

  const [publishCampaign, { loading: publishCampaignLoading }] =
    usePublishCampaign();

  const handleOpen = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOnDuplicate = useCallback(async () => {
    setAnchorEl(null);
    await duplicateCampaign(campaign.id);
  }, [campaign.id, duplicateCampaign]);

  const handleOnEdit = useCallback(async () => {
    if (campaign.status !== CampaignStatus.DRAFT) {
      return;
    }
    setAnchorEl(null);
    navigate(`/update-campaign/${campaign.id}`);
  }, [campaign.status, campaign.id, navigate]);

  const handleOnPublish = useCallback(async () => {
    await publishCampaign(campaign.id);
  }, [campaign.id, publishCampaign]);

  return (
    <>
      <ButtonGroup variant="contained" size="large" color="secondary">
        {campaign.status === CampaignStatus.DRAFT ? (
          <Confirm
            title={t('manager:campaign.confirmPublish.title')}
            description={t('manager:campaign.confirmPublish.description')}>
            <Button
              disabled={
                campaignUsageExceeded ||
                (campaign.includeContentFileOnSubmission &&
                  storageSpaceUsageExceeded) ||
                isPast(new Date(campaign.applyUntil)) ||
                isToday(
                  new Date(campaign.applyUntil).setUTCHours(0, 0, 0, 0),
                ) ||
                publishCampaignLoading
              }
              onClick={handleOnPublish}>
              {t('common:publish')}
            </Button>
          </Confirm>
        ) : (
          <Button
            disabled={duplicateCampaignLoading}
            onClick={handleOnDuplicate}>
            {t('common:duplicate')}
          </Button>
        )}
        <Button
          size="small"
          aria-controls={anchorEl ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl ? 'true' : undefined}
          onClick={handleOpen}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <CampaignBriefingMenuItem campaign={campaign} />
        {campaign.status === CampaignStatus.DRAFT
          ? [
              <MenuItem
                key="copy"
                disabled={duplicateCampaignLoading}
                onClick={handleOnDuplicate}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faCopy} />
                </ListItemIcon>
                <ListItemText>{t('common:duplicate')}</ListItemText>
              </MenuItem>,
              <MenuItem key="edit" onClick={handleOnEdit}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faPen} />
                </ListItemIcon>
                <ListItemText>{t('common:edit')}</ListItemText>
              </MenuItem>,
              <DeleteCampaignMenuItem
                key="deleteCampaign"
                campaign={campaign}
              />,
            ]
          : null}
        {campaign.status === CampaignStatus.ACTIVE
          ? [
              <ShareCampaignMenuItem key="shareCampaign" campaign={campaign} />,
              <ExtendCampaignDeadlinesMenuItem
                key="extendDeadlines"
                campaign={campaign}
              />,
              <CompleteCampaignMenuItem
                key="completeCampaign"
                campaign={campaign}
              />,
            ]
          : null}
      </Menu>
    </>
  );
}
