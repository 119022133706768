import { createContext } from 'react';

export type ColorMode = 'light' | 'dark' | 'system';

export interface ColorModeContextData {
  mode: ColorMode;
  setMode(mode: ColorMode): void;
}

export const ColorModeContext = createContext<ColorModeContextData | undefined>(
  undefined,
);
