import { Taskable, TaskItemInput, TaskItemType } from './types';
import { assertUnreachable } from './helpers';

interface TaskItem {
  taskable: Taskable;
}

export function mapTaskItemToTaskItemInput(item: TaskItem): TaskItemInput {
  switch (item.taskable.__typename) {
    case 'FreeTextTask':
      return {
        type: TaskItemType.FREE_TEXT,
        freeTextTask: {
          description: item.taskable.description,
        },
      };
    case 'WebsiteTask':
      return {
        type: TaskItemType.WEBSITE,
        websiteTask: {
          url: item.taskable.url,
        },
      };
    case 'HashtagTask':
      return {
        type: TaskItemType.HASHTAG,
        hashtagTask: {
          hashtags: item.taskable.hashtags,
        },
      };
    default:
      assertUnreachable(item.taskable);
  }
}
