import { RouteObject } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout.tsx';
import Login from '../pages/Login/Login.tsx';
import Register from '../pages/Register/Register.tsx';
import VerifyEmail from '../pages/VerifyEmail/VerifyEmail.tsx';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword.tsx';
import RecoverPassword from '../pages/RecoverPassword/RecoverPassword.tsx';
import { authLoader } from './loader.ts';

const AuthRoutes: RouteObject = {
  loader: authLoader,
  element: <AuthLayout />,
  children: [
    {
      index: true,
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: 'verify-email',
      element: <VerifyEmail />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: 'recover-password',
      element: <RecoverPassword />,
    },
  ],
};

export default AuthRoutes;
