import { FieldPath, FieldValues } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { addDays, addWeeks } from 'date-fns';
import { FormFieldProps } from '../utils/types.ts';

export default function SubmitUntilField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  field: { value, onChange },
  fieldState: { error, invalid },
}: FormFieldProps<TFieldValues, TName>) {
  const { t } = useTranslation(['common']);
  return (
    <DatePicker
      label={t('common:campaign.submitUntil')}
      defaultValue={value}
      timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
      disablePast
      minDate={addWeeks(addDays(new Date().setHours(0, 0, 0, 0), 1), 2)}
      slotProps={{
        textField: {
          fullWidth: true,
          required: true,
          error: invalid,
          helperText: error?.message,
        },
      }}
      onChange={onChange}
    />
  );
}
