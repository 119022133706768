import { Button, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { persistor } from '../utils/apolloInMemoryCache.ts';

export default function ErrorBoundary() {
  const { t } = useTranslation(['manager']);
  const client = useApolloClient();

  const handleOnClick = useCallback(async () => {
    await persistor.purge();
    await client.clearStore();
    window.location.replace('/');
  }, [client]);

  return (
    <Stack
      flex={1}
      spacing={3}
      p={3}
      alignItems="center"
      justifyContent="center"
      textAlign="center">
      <Typography variant="h2" align="center">
        {t('errorBoundary.header')}
      </Typography>
      <Typography variant="h4" align="center">
        {t('errorBoundary.description')}
      </Typography>
      <Button variant="text" onClick={handleOnClick}>
        {t('errorBoundary.restart.actionButton')}
      </Button>
    </Stack>
  );
}
