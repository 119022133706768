import { MenuItem, Select, SelectProps } from '@mui/material';
import { ChannelType } from '@social-garden/utils/types.ts';
import { getChannelTypeName } from '@social-garden/utils/channel.ts';

export default function ChannelTypeSelect(
  props: Omit<SelectProps<ChannelType>, 'children'>,
) {
  return (
    <Select<ChannelType> {...props}>
      {Object.values(ChannelType).map((value) => (
        <MenuItem key={value} value={value}>
          {getChannelTypeName(value)}
        </MenuItem>
      ))}
    </Select>
  );
}
