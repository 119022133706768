import { useSnackbar } from 'notistack';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { Button, Container, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getUriWithHash } from '@social-garden/utils/helpers.ts';
import {
  brandDocument,
  updateBrandDocument,
  updateBrandLogoDocument,
} from '@social-garden/api/documents/brand.ts';
import {
  UpdateBrandFieldValues,
  UpdateBrandSchema,
} from '../../constants/ValidationSchema.ts';
import ImageCropField from '../../components/ImageCropField.tsx';
import PageTitle from '../../components/PageTitle.tsx';
import BrandNameField from '../../components/BrandNameField.tsx';

export default function UpdateBrand() {
  const { id } = useParams();
  const { t } = useTranslation(['manager']);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(brandDocument, {
    variables: {
      id: id ?? '',
    },
  });
  const [updateBrand, { loading: updateBrandLoading }] =
    useMutation(updateBrandDocument);
  const [updateBrandLogo] = useMutation(updateBrandLogoDocument);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<UpdateBrandFieldValues>({
    mode: 'all',
    resolver: zodResolver(UpdateBrandSchema),
    defaultValues: {
      name: data.brand.name,
    },
  });

  const handleOnValid = useCallback(
    async ({ name }: UpdateBrandFieldValues) => {
      const response = await updateBrand({
        variables: {
          input: {
            id: id ?? '',
            name,
          },
        },
      });
      if (response.data?.updateBrand) {
        enqueueSnackbar(t('brand.update.successText'), {
          variant: 'success',
        });
        navigate('/brands');
      }
    },
    [navigate, enqueueSnackbar, id, updateBrand, t],
  );

  const handleOnImageChange = useCallback(
    async (logo: File) => {
      const response = await updateBrandLogo({
        variables: {
          input: {
            id: id ?? '',
            logo,
          },
        },
      });
      if (response.data?.updateBrandLogo) {
        enqueueSnackbar(t('brand.update.logo.successText'), {
          variant: 'success',
        });
        navigate('/brands');
      }
    },
    [navigate, updateBrandLogo, id, enqueueSnackbar, t],
  );

  return (
    <Container
      maxWidth="md"
      component="form"
      noValidate
      onSubmit={handleSubmit(handleOnValid)}>
      <Stack spacing={4}>
        <PageTitle>{t('brand.update.title')}</PageTitle>
        <Stack spacing={2}>
          <ImageCropField
            fullWidth
            label={t('brand.update.logo.label')}
            previewSrc={getUriWithHash(data.brand.logo)}
            width={500}
            height={500}
            cropShape="round"
            onChange={handleOnImageChange}
          />
          <Controller<UpdateBrandFieldValues, 'name'>
            name="name"
            control={control}
            render={BrandNameField}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={!isValid || updateBrandLoading}>
            {t('brand.update.submitButton')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
