import { Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/PageTitle.tsx';
import DashboardHeaderImage from '../../assets/dashboard-header.jpg';

export default function DashboardHeader() {
  const { t } = useTranslation(['manager']);

  return (
    <Stack
      sx={{
        backgroundImage: `url(${DashboardHeaderImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: (theme) => theme.palette.background.paper,
      }}>
      <Stack
        sx={{
          backdropFilter: 'brightness(35%) blur(3px)',
          color: (theme) => theme.palette.common.white,
        }}>
        <Container
          sx={{
            display: 'flex',
            minHeight: 300,
            height: '50vh',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 4,
            gap: 2,
          }}
          maxWidth="md">
          <PageTitle align="center">
            {t('dashboard.title', {
              appName: import.meta.env.VITE_APP_NAME,
            })}
          </PageTitle>
          <Typography variant="h5" align="center">
            {t('dashboard.subtitle')}
          </Typography>
        </Container>
      </Stack>
    </Stack>
  );
}
