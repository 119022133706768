import { Grid2, Link, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  abbreviateCount,
  formatPercentage,
} from '@social-garden/utils/helpers.ts';
import ChannelStatisticItem from './ChannelStatisticItem.tsx';

export interface InstagramChannelStatisticsData {
  username: string;
  url: string;
  followersCount: number;
  mediaCount: number;
  averageEngagementRate?: number | null;
  averageReach?: number | null;
}

interface InstagramChannelStatisticsProps {
  data: InstagramChannelStatisticsData;
}

export default function InstagramChannelStatistics({
  data,
}: InstagramChannelStatisticsProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common']);

  return (
    <Stack spacing={1}>
      <Stack direction="row">
        <Link href={data.url} target="_blank">
          {data.username}
        </Link>
      </Stack>
      <Grid2 container spacing={2}>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.followerCount', {
              count: data.followersCount,
            })}
            value={abbreviateCount(data.followersCount, resolvedLanguage)}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.averageEngagementRate')}
            value={formatPercentage(
              data.averageEngagementRate || 0,
              resolvedLanguage,
            )}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.averageReach')}
            value={abbreviateCount(data.averageReach || 0, resolvedLanguage)}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.mediaCount', {
              count: data.mediaCount,
            })}
            value={abbreviateCount(data.mediaCount, resolvedLanguage)}
          />
        </Grid2>
      </Grid2>
    </Stack>
  );
}
