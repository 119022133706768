import {
  CircularProgress,
  CircularProgressProps,
  Stack,
  StackProps,
} from '@mui/material';

interface SuspenseFallbackProps extends StackProps {
  circularProgressProps?: CircularProgressProps;
}

export default function SuspenseFallback({
  circularProgressProps,
  children,
  ...rest
}: SuspenseFallbackProps) {
  return (
    <Stack
      flex={1}
      spacing={2}
      p={4}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      {...rest}>
      <CircularProgress {...circularProgressProps} />
      {children}
    </Stack>
  );
}
