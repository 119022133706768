import { Grid2, Link, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  abbreviateCount,
  formatPercentage,
} from '@social-garden/utils/helpers.ts';
import ChannelStatisticItem from './ChannelStatisticItem.tsx';

export interface YoutubeChannelStatisticsData {
  title: string;
  url: string;
  subscriberCount: number;
  videoCount: number;
  averageEngagementRate?: number | null;
  averageReach?: number | null;
}

interface YoutubeChannelStatisticsProps {
  data: YoutubeChannelStatisticsData;
}

export default function YoutubeChannelStatistics({
  data,
}: YoutubeChannelStatisticsProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common']);

  return (
    <Stack spacing={1}>
      <Stack direction="row">
        <Link href={data.url} target="_blank">
          {data.title}
        </Link>
      </Stack>
      <Grid2 container spacing={2}>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.subscriberCount', {
              count: data.subscriberCount,
            })}
            value={abbreviateCount(data.subscriberCount, resolvedLanguage)}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.averageEngagementRate')}
            value={formatPercentage(
              data.averageEngagementRate || 0,
              resolvedLanguage,
            )}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.averageReach')}
            value={abbreviateCount(data.averageReach || 0, resolvedLanguage)}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.videoCount', {
              count: data.videoCount,
            })}
            value={abbreviateCount(data.videoCount, resolvedLanguage)}
          />
        </Grid2>
      </Grid2>
    </Stack>
  );
}
