import { Card, CardContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-thin-svg-icons/faTriangleExclamation';

export interface CampaignGoalSubmissionFailedProps {
  creatorHasCampaign: {
    reason?: string | null;
  };
}

export default function CampaignGoalSubmissionFailed({
  creatorHasCampaign,
}: CampaignGoalSubmissionFailedProps) {
  const { t } = useTranslation(['common', 'manager']);

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={2}
          p={4}
          minHeight={250}>
          <FontAwesomeIcon icon={faTriangleExclamation} size="6x" />
          <Typography variant="h4" align="center">
            {t('manager:creatorHasCampaignFlow.failed.title')}
          </Typography>
          <Typography variant="subtitle1" align="center">
            {t('manager:creatorHasCampaignFlow.failed.content')}
          </Typography>
          {creatorHasCampaign.reason ? (
            <Stack alignSelf="stretch">
              <Typography fontWeight={500}>
                {t('common:creatorHasCampaign.reason')}
              </Typography>
              <Typography>{creatorHasCampaign.reason}</Typography>
            </Stack>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
}
