import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardProps,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import { getUriWithHash } from '@social-garden/utils/helpers.ts';
import { CampaignStatus, Download } from '@social-garden/utils/types.ts';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import Brand from './Brand.tsx';
import useCampaignStatus from '../hooks/useCampaignStatus.ts';

export interface CampaignListItemData {
  id: string;
  name: string;
  status: CampaignStatus;
  headerImage: Download;
  brand: { name: string; logo: Download };
  statistics?: {
    reviewApplicationBefore?: string | null;
    reviewSubmissionBefore?: string | null;
  } | null;
}

interface CampaignListItemProps extends Omit<CardProps, 'children'> {
  data: CampaignListItemData;
}

export default function CampaignListItem({
  data,
  ...rest
}: CampaignListItemProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['manager']);

  const { getCampaignStatusName } = useCampaignStatus();

  return (
    <Card {...rest}>
      <CardActionArea href={`/campaign-details/${data.id}`}>
        <CardMedia
          sx={{ aspectRatio: 21 / 9 }}
          image={getUriWithHash(data.headerImage)}
          title={data.name}
        />
        <Chip
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
          label={getCampaignStatusName(data.status)}
          variant="filled"
          size="small"
        />
        <CardContent
          component={Stack}
          spacing={2}
          alignItems="flex-start"
          justifyContent="center">
          <Stack spacing={0.5}>
            <Typography fontSize={18}>{data.name}</Typography>
            <Brand name={data.brand.name} logo={data.brand.logo} size="small" />
          </Stack>
          {data.statistics?.reviewApplicationBefore ? (
            <Stack spacing={0.5}>
              <Chip
                label={t('manager:campaign.unreviewedApplications')}
                size="small"
                color="applied"
              />
              <Typography px={0.5} variant="caption" color="text.secondary">
                <FontAwesomeIcon
                  style={{
                    marginRight: 2.5,
                  }}
                  icon={faClock}
                  fixedWidth
                />
                {t('manager:campaign.reviewBefore', {
                  date: new Date(
                    data.statistics.reviewApplicationBefore,
                  ).toLocaleString(resolvedLanguage, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }),
                })}
              </Typography>
            </Stack>
          ) : null}
          {data.statistics?.reviewSubmissionBefore ? (
            <Stack spacing={0.5}>
              <Chip
                label={t('manager:campaign.unreviewedSubmissions')}
                size="small"
                color="submitted"
              />
              <Typography px={0.5} variant="caption" color="text.secondary">
                <FontAwesomeIcon
                  style={{
                    marginRight: 2.5,
                  }}
                  icon={faClock}
                  fixedWidth
                />
                {t('manager:campaign.reviewBefore', {
                  date: new Date(
                    data.statistics.reviewSubmissionBefore,
                  ).toLocaleString(resolvedLanguage, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }),
                })}
              </Typography>
            </Stack>
          ) : null}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
