import { MenuItem, Select, SelectProps } from '@mui/material';
import { useSuspenseQuery } from '@apollo/client';
import { categoriesDocument } from '@social-garden/api/documents/category.ts';

export default function CategorySelect(
  props: Omit<SelectProps<string>, 'children'>,
) {
  const { data } = useSuspenseQuery(categoriesDocument);

  return (
    <Select<string> {...props}>
      {data.categories.map((category) => (
        <MenuItem key={category.id} value={category.id}>
          {category.name}
        </MenuItem>
      ))}
    </Select>
  );
}
