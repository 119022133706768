import reactStringReplace from 'react-string-replace';
import { ReactNode } from 'react';
import { Link } from '@mui/material';
import { LinkProps } from '@mui/material/Link';
import { URL_REGEX } from '@social-garden/utils/regex.ts';
import { withHttps } from '@social-garden/utils/helpers.ts';

interface ReplaceUrlWithLinkProps extends Omit<LinkProps, 'href'> {
  children?: string | ReactNode[];
}

export default function ReplaceUrlWithLink({
  children,
  target = '_blank',
  ...rest
}: ReplaceUrlWithLinkProps) {
  return (
    <>
      {reactStringReplace(children, URL_REGEX, (match, index) => (
        <Link key={index} href={withHttps(match)} target={target} {...rest}>
          {match}
        </Link>
      ))}
    </>
  );
}
