export interface Paginator {
  data?: any[];
  paginatorInfo?: Record<string, any>;
}

export function mergePagination(
  existing: Readonly<Paginator> | undefined,
  incoming: Readonly<Paginator>,
  { args }: { args: Record<string, any> | null },
): Paginator {
  const mergedData = existing?.data ? existing.data.slice(0) : [];

  if (args) {
    const { first, page = 1 } = args;
    const offset = first * (page - 1);
    const incomingData = incoming.data ? incoming.data : [];
    for (let i = 0; i < incomingData.length; ++i) {
      mergedData[offset + i] = incomingData[i];
    }
  } else {
    throw new Error('Can not merge incoming data if args are null!');
  }

  return {
    data: mergedData,
    paginatorInfo: incoming.paginatorInfo,
  };
}
