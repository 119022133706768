import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { publishCampaignDocument } from '@social-garden/api/documents/campaign.ts';

export default function usePublishCampaign() {
  const { t } = useTranslation(['manager']);
  const { enqueueSnackbar } = useSnackbar();

  const [publishCampaign, { loading, error }] = useMutation(
    publishCampaignDocument,
  );

  const handlePublishCampaign = useCallback(
    async (id: string) => {
      const response = await publishCampaign({
        variables: {
          id,
        },
      });
      if (response.data) {
        enqueueSnackbar(t('manager:publishCampaign.successText'), {
          variant: 'success',
        });
      }

      return response;
    },
    [t, enqueueSnackbar, publishCampaign],
  );

  return [handlePublishCampaign, { loading, error }] as const;
}
