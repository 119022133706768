import { Outlet } from 'react-router-dom';
import { useSuspenseQuery } from '@apollo/client';
import { Suspense, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { managerLayoutDocument } from '@social-garden/api/documents/manager.ts';
import { Stack } from '@mui/material';
import SuspenseFallback from '../components/SuspenseFallback.tsx';
import Navigation from '../components/Navigation.tsx';
import ErrorBoundary from '../components/ErrorBoundary.tsx';
import AuthToken from '../constants/AuthToken.ts';

export default function ManagerLayout() {
  const {
    i18n: { changeLanguage },
  } = useTranslation();
  const { data } = useSuspenseQuery(managerLayoutDocument);

  const setupCompleted = useMemo<boolean>(() => {
    if (data.whoAmI.__typename !== 'Manager') {
      return false;
    }
    return Boolean(
      data.whoAmI.company.currentSubscription &&
        data.whoAmI.company.currentSubscription.subscriptionPlan &&
        data.whoAmI.company.currentSubscription.status === 'active' &&
        data.whoAmI.company.brands.length > 0,
    );
  }, [data.whoAmI]);

  useEffect(() => {
    (async () => {
      if (data.whoAmI.__typename === 'Manager') {
        await changeLanguage(data.whoAmI.locale);
      } else {
        AuthToken.removeToken();
      }
    })();
  }, [data.whoAmI, changeLanguage]);

  if (data.whoAmI.__typename !== 'Manager') {
    return <ErrorBoundary />;
  }

  return (
    <>
      <Navigation manager={data.whoAmI} setupCompleted={setupCompleted} />
      <Stack flex={1} py={4}>
        <Suspense fallback={<SuspenseFallback />}>
          <Outlet context={{ manager: data.whoAmI }} />
        </Suspense>
      </Stack>
    </>
  );
}
