import { useSuspenseQuery } from '@apollo/client';
import { campaignUsageDocument } from '@social-garden/api/documents/manager.ts';

export default function useCampaignUsage() {
  const {
    data: { whoAmI },
  } = useSuspenseQuery(campaignUsageDocument);

  if (whoAmI.__typename !== 'Manager') {
    return { current: 0, max: 0, exceeded: true };
  }

  if (
    !whoAmI.company.currentSubscription ||
    !whoAmI.company.currentSubscription.subscriptionPlan
  ) {
    return {
      current: whoAmI.company.currentActiveCampaignCount,
      max: 0,
      exceeded: true,
    };
  }

  if (
    whoAmI.company.currentSubscription.subscriptionPlan.maxCampaignCount ===
      undefined ||
    whoAmI.company.currentSubscription.subscriptionPlan.maxCampaignCount ===
      null
  ) {
    return {
      current: whoAmI.company.currentActiveCampaignCount,
      max: null,
      exceeded: false,
    };
  }

  return {
    current: whoAmI.company.currentActiveCampaignCount,
    max: whoAmI.company.currentSubscription.subscriptionPlan.maxCampaignCount,
    exceeded:
      whoAmI.company.currentActiveCampaignCount >=
      whoAmI.company.currentSubscription.subscriptionPlan.maxCampaignCount,
  };
}
