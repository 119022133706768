import { Button, Container, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SubscriptionPlanTable from '../../components/SubscriptionPlanTable.tsx';

export default function ChooseSubscription() {
  const { t } = useTranslation(['common', 'manager']);

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 6,
      }}>
      <Typography variant="h2" align="center">
        {t('manager:noSubscription.title')}
      </Typography>
      <SubscriptionPlanTable />
      <Divider>
        <Typography variant="h5" align="center">
          {t('manager:subscriptionPlan.customPlan.header')}
        </Typography>
      </Divider>
      <Stack direction="row" justifyContent="center" pb={6}>
        <Button
          href={import.meta.env.VITE_CONTACT_URL}
          target="_blank"
          variant="outlined"
          color="secondary"
          size="large">
          {t('common:contactSales')}
        </Button>
      </Stack>
    </Container>
  );
}
