import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForwardStep } from '@fortawesome/pro-regular-svg-icons/faForwardStep';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { completeCampaignDocument } from '@social-garden/api/documents/campaign.ts';
import { useCallback } from 'react';
import { CampaignStatus } from '@social-garden/utils/types.ts';
import Confirm from '../../components/Confirm.tsx';

interface CompleteCampaignMenuItemProps {
  campaign: {
    id: string;
    status: CampaignStatus;
  };
}

export default function CompleteCampaignMenuItem({
  campaign,
}: CompleteCampaignMenuItemProps) {
  const { t } = useTranslation(['common', 'manager']);

  const [completeCampaign, { loading }] = useMutation(completeCampaignDocument);

  const handleOnCompleteCampaign = useCallback(async () => {
    if (campaign.status !== CampaignStatus.ACTIVE) {
      return;
    }
    await completeCampaign({
      variables: {
        id: campaign.id,
      },
    });
  }, [campaign.status, campaign.id, completeCampaign]);

  if (campaign.status !== CampaignStatus.ACTIVE) {
    return null;
  }

  return (
    <Confirm
      title={t('manager:campaign.confirmComplete.title')}
      description={t('manager:campaign.confirmComplete.description')}
      buttonText={t('common:complete')}
      color="warning">
      <MenuItem disabled={loading} onClick={handleOnCompleteCampaign}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faForwardStep} />
        </ListItemIcon>
        <ListItemText>{t('common:complete')}</ListItemText>
      </MenuItem>
    </Confirm>
  );
}
