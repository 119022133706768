import { RouterProvider } from 'react-router-dom';
import { CssBaseline, Stack } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import router from './routing/router.ts';
import SuspenseFallback from './components/SuspenseFallback.tsx';
import useDateFnsLocale from './hooks/useDateFnsLocale.ts';
import ColorModeProvider from './providers/ColorModeProvider.tsx';
import ThemeProvider from './providers/ThemeProvider.tsx';
import ApolloClientProvider from './providers/ApolloClientProvider.tsx';

export default function App() {
  const adapterLocale = useDateFnsLocale();

  return (
    <ColorModeProvider storageKey="manager-social-garden-color-mode">
      <ThemeProvider>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={adapterLocale}>
          <SnackbarProvider
            maxSnack={5}
            autoHideDuration={5000}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <ApolloClientProvider>
              <CssBaseline />
              <Stack minHeight="100vh">
                <RouterProvider
                  router={router}
                  fallbackElement={<SuspenseFallback />}
                />
              </Stack>
            </ApolloClientProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ColorModeProvider>
  );
}
