import { graphql } from '../gql';

export const categoriesDocument = graphql(`
  query categories {
    categories {
      id
      name
    }
  }
`);
