import { useTranslation } from 'react-i18next';
import { enUS } from 'date-fns/locale/en-US';
import { deAT } from 'date-fns/locale/de-AT';
import { Locale } from 'date-fns';
import { useEffect, useState } from 'react';

const DATE_FNS_RESOURCES: { [key: string]: Locale } = {
  en: enUS,
  de: deAT,
};

function getLocaleFromLanguage(language: string): Locale {
  return DATE_FNS_RESOURCES[language];
}

export default function useDateFnsLocale() {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState<Locale>(
    getLocaleFromLanguage(i18n.resolvedLanguage ?? i18n.languages[0]),
  );

  useEffect(() => {
    setLocale(
      getLocaleFromLanguage(i18n.resolvedLanguage ?? i18n.languages[0]),
    );
  }, [i18n.resolvedLanguage, i18n.languages]);

  return locale;
}
