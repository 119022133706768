import { useSuspenseQuery } from '@apollo/client';
import { stripeCustomerPortalUrlDocument } from '@social-garden/api/documents/company.ts';

export default function useStripeCustomerPortalUrl(
  returnUrl: string = window.location.origin,
) {
  return useSuspenseQuery(stripeCustomerPortalUrlDocument, {
    variables: {
      input: {
        returnUrl,
      },
    },
    fetchPolicy: 'network-only',
  });
}
