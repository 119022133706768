import { graphql } from '../gql';

export const stripeCustomerPortalUrlDocument = graphql(`
  query stripeCustomerPortalUrl($input: StripeCustomerPortalUrlInput!) {
    stripeCustomerPortalUrl(input: $input)
  }
`);

export const registerCompanyDocument = graphql(`
  mutation registerCompany($input: RegisterCompanyInput!) {
    registerCompany(input: $input) {
      id
      name
    }
  }
`);

export const checkoutSubscriptionForCompanyDocument = graphql(`
  mutation checkoutSubscriptionForCompany(
    $input: CheckoutSubscriptionForCompanyInput!
  ) {
    checkoutSubscriptionForCompany(input: $input)
  }
`);
