import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useState } from 'react';
import { addWeeks, differenceInWeeks, formatISO, isFuture } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  CampaignTypeable,
  CreatorHasCampaignStatus,
} from '@social-garden/utils/types.ts';
import {
  acceptCampaignGoalSubmissionDocument,
  failCampaignGoalSubmissionDocument,
  retryCampaignGoalSubmissionDocument,
} from '@social-garden/api/documents/creatorHasCampaign.ts';
import { DatePicker } from '@mui/x-date-pickers';
import {
  DeclineCampaignGoalSubmissionFieldValues,
  DeclineCampaignGoalSubmissionSchema,
} from '../../constants/ValidationSchema.ts';
import { FormFieldProps, RenderControllerElement } from '../../utils/types.ts';
import ContentPreview from '../../components/ContentPreview.tsx';
import CreatorHasCampaignContentFile from './CreatorHasCampaignContentFile.tsx';

function getSubmitUntilDefaultValue(submitUntil: string) {
  if (differenceInWeeks(new Date(submitUntil), new Date()) > 1) {
    return new Date(new Date(submitUntil).setUTCHours(0, 0, 0, 0));
  }
  return addWeeks(new Date().setUTCHours(0, 0, 0, 0), 1);
}

export interface ReviewCampaignGoalSubmissionProps {
  creatorHasCampaign: {
    id: string;
    status: CreatorHasCampaignStatus;
    contentUrl?: string | null;
    submitUntil: string;
    creator: {
      username: string;
    };
    campaign: {
      includeContentFileOnSubmission: boolean;
      typeable: CampaignTypeable;
    };
  };
}

export default function ReviewCampaignGoalSubmission({
  creatorHasCampaign,
}: ReviewCampaignGoalSubmissionProps) {
  const { t } = useTranslation(['common', 'manager']);
  const [reasonDialogOpen, setReasonDialogOpen] = useState<boolean>(false);

  const {
    watch,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<DeclineCampaignGoalSubmissionFieldValues>({
    mode: 'all',
    resolver: zodResolver(DeclineCampaignGoalSubmissionSchema),
    defaultValues: {
      allowRetry: false,
      submitUntil: getSubmitUntilDefaultValue(creatorHasCampaign.submitUntil),
    },
  });

  const [
    acceptCampaignGoalSubmission,
    { loading: acceptCampaignGoalSubmissionLoading },
  ] = useMutation(acceptCampaignGoalSubmissionDocument);

  const [
    retryCampaignGoalSubmission,
    { loading: retryCampaignGoalSubmissionLoading },
  ] = useMutation(retryCampaignGoalSubmissionDocument);

  const [
    failCampaignGoalSubmission,
    { loading: failCampaignGoalSubmissionLoading },
  ] = useMutation(failCampaignGoalSubmissionDocument);

  const handleOnAcceptCampaignGoalSubmission = useCallback(async () => {
    await acceptCampaignGoalSubmission({
      variables: {
        creatorHasCampaignId: creatorHasCampaign.id,
      },
    });
  }, [acceptCampaignGoalSubmission, creatorHasCampaign.id]);

  const handleOnValid = useCallback(
    async ({
      reason,
      allowRetry,
      submitUntil,
    }: DeclineCampaignGoalSubmissionFieldValues) => {
      if (allowRetry) {
        await retryCampaignGoalSubmission({
          variables: {
            input: {
              creatorHasCampaignId: creatorHasCampaign.id,
              reason,
              submitUntil: formatISO(submitUntil, {
                representation: 'date',
              }),
            },
          },
        });
      } else {
        await failCampaignGoalSubmission({
          variables: {
            input: {
              creatorHasCampaignId: creatorHasCampaign.id,
              reason,
            },
          },
        });
      }
    },
    [
      creatorHasCampaign.id,
      failCampaignGoalSubmission,
      retryCampaignGoalSubmission,
    ],
  );

  const openReasonDialog = useCallback(() => setReasonDialogOpen(true), []);

  const closeReasonDialog = useCallback(() => setReasonDialogOpen(false), []);

  const renderReason = useCallback<
    RenderControllerElement<DeclineCampaignGoalSubmissionFieldValues, 'reason'>
  >(
    ({
      field: { name, value, onBlur, onChange },
      fieldState: { error, invalid },
    }) => (
      <TextField
        required
        fullWidth
        variant="outlined"
        multiline
        minRows={3}
        label={t('common:creatorHasCampaign.reason')}
        name={name}
        defaultValue={value}
        error={invalid}
        helperText={error?.message}
        onBlur={onBlur}
        onChange={onChange}
      />
    ),
    [t],
  );

  const renderAllowRetry = useCallback<
    RenderControllerElement<
      DeclineCampaignGoalSubmissionFieldValues,
      'allowRetry'
    >
  >(
    ({ field: { value, name, onBlur, onChange } }) => (
      <FormControlLabel
        label={t('manager:form.field.allowRetry.label')}
        control={
          <Checkbox
            color="primary"
            name={name}
            checked={Boolean(value)}
            onChange={onChange}
            onBlur={onBlur}
          />
        }
      />
    ),
    [t],
  );

  const renderSubmitUntil = useCallback(
    ({
      field: { value, onChange },
      fieldState: { invalid, error },
      formState: { defaultValues },
    }: FormFieldProps<
      DeclineCampaignGoalSubmissionFieldValues,
      'submitUntil'
    >) => (
      <DatePicker
        label={t('common:campaign.submitUntil')}
        timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        defaultValue={value}
        minDate={defaultValues?.submitUntil}
        slotProps={{
          textField: {
            fullWidth: true,
            required: true,
            error: invalid,
            helperText: error?.message,
          },
        }}
        onChange={onChange}
      />
    ),
    [t],
  );

  return (
    <Stack spacing={2}>
      <CreatorHasCampaignContentFile creatorHasCampaign={creatorHasCampaign} />
      {creatorHasCampaign.contentUrl ? (
        <ContentPreview
          src={creatorHasCampaign.contentUrl}
          channelType={creatorHasCampaign.campaign.typeable.channelType}
        />
      ) : null}
      <Stack direction="row" spacing={2}>
        <Button variant="contained" color="error" onClick={openReasonDialog}>
          {t('common:fail')}
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={acceptCampaignGoalSubmissionLoading}
          onClick={handleOnAcceptCampaignGoalSubmission}>
          {t('common:accept')}
        </Button>
      </Stack>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={Boolean(reasonDialogOpen)}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(handleOnValid),
        }}
        onClose={closeReasonDialog}>
        <DialogTitle>
          {t('manager:campaignGoalSubmission.failDialog.title', {
            username: creatorHasCampaign.creator.username,
          })}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              {t('manager:campaignGoalSubmission.failDialog.description')}
            </DialogContentText>
            <Controller<DeclineCampaignGoalSubmissionFieldValues, 'reason'>
              render={renderReason}
              name="reason"
              control={control}
            />
            {isFuture(new Date(creatorHasCampaign.submitUntil)) ? (
              <Controller<
                DeclineCampaignGoalSubmissionFieldValues,
                'allowRetry'
              >
                name="allowRetry"
                render={renderAllowRetry}
                control={control}
              />
            ) : null}
            {watch('allowRetry') ? (
              <Controller<
                DeclineCampaignGoalSubmissionFieldValues,
                'submitUntil'
              >
                name="submitUntil"
                control={control}
                render={renderSubmitUntil}
              />
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="reset" color="inherit" onClick={closeReasonDialog}>
            {t('common:cancel')}
          </Button>
          <Button
            type="submit"
            color={watch('allowRetry') ? 'success' : 'error'}
            disabled={
              !isValid ||
              retryCampaignGoalSubmissionLoading ||
              failCampaignGoalSubmissionLoading
            }>
            {watch('allowRetry') ? t('common:retry') : t('common:fail')}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
