import { FieldPolicy, InMemoryCache } from '@apollo/client';
import { LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import { mergePagination, Paginator } from '@social-garden/utils/pagination.ts';

type KeyArgs = FieldPolicy<Paginator>['keyArgs'];

function pageFirstPagination(keyArgs: KeyArgs = false): FieldPolicy<Paginator> {
  return {
    keyArgs,
    merge: mergePagination,
  };
}

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        messages: pageFirstPagination(['creatorHasCampaignId']),
        campaigns: pageFirstPagination(['input', ['brandIds', 'status']]),
      },
    },
    Download: {
      keyFields: ['hash'],
    },
  },
});

const APOLLO_CACHE_PERSIST_KEY = 'apolloCachePersist';

export const persistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
  key: APOLLO_CACHE_PERSIST_KEY,
  debug: import.meta.env.DEV,
});
