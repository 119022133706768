import { MenuItem, Select, SelectProps } from '@mui/material';
import { useSuspenseQuery } from '@apollo/client';
import { brandsDocument } from '@social-garden/api/documents/brand.ts';

export default function BrandSelect<Value>(
  props: Omit<SelectProps<Value>, 'children'>,
) {
  const { data } = useSuspenseQuery(brandsDocument);

  if (data.whoAmI.__typename !== 'Manager') {
    return null;
  }

  return (
    <Select<Value> {...props}>
      {data.whoAmI.company.brands.map((brand) => (
        <MenuItem key={brand.id} value={brand.id}>
          {brand.name}
        </MenuItem>
      ))}
    </Select>
  );
}
