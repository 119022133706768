import { ChannelType } from './types';
import { assertUnreachable } from './helpers';

interface InstagramOEmbedUrlParameters {
  channelType: ChannelType.INSTAGRAM | 'InstagramChannel';
  url: string;
  accessToken: string;
  maxWidth?: number;
  maxHeight?: number;
}

interface TiktokOEmbedUrlParameters {
  channelType: ChannelType.TIKTOK | 'TiktokChannel';
  url: string;
  maxWidth?: number;
  maxHeight?: number;
}

interface YoutubeOEmbedUrlParameters {
  channelType: ChannelType.YOUTUBE | 'YoutubeChannel';
  url: string;
  maxWidth?: number;
  maxHeight?: number;
}

type OEmbedUrlParameters =
  | InstagramOEmbedUrlParameters
  | TiktokOEmbedUrlParameters
  | YoutubeOEmbedUrlParameters;

export function getOEmbedUrl(params: OEmbedUrlParameters) {
  let optionalParam = '';
  if (params.maxWidth) {
    optionalParam = optionalParam + '&maxwidth=' + params.maxWidth;
  }
  if (params.maxHeight) {
    optionalParam = optionalParam + '&maxheight=' + params.maxHeight;
  }

  switch (params.channelType) {
    case ChannelType.YOUTUBE:
    case 'YoutubeChannel':
      return `https://www.youtube.com/oembed?url=${params.url}&format=json${optionalParam}`;
    case ChannelType.INSTAGRAM:
    case 'InstagramChannel':
      return `https://graph.facebook.com/v19.0/instagram_oembed?url=${params.url}&access_token=${params.accessToken}&format=json${optionalParam}`;
    case ChannelType.TIKTOK:
    case 'TiktokChannel':
      return `https://www.tiktok.com/oembed?url=${params.url}&format=json${optionalParam}`;
    default:
      assertUnreachable(params);
  }
}
