const AUTH_TOKEN_STORAGE_KEY = 'manager-social-garden-auth-token';

export default class AuthToken {
  public static getToken() {
    return localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  }
  public static setToken(value: string) {
    return localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, value);
  }
  public static removeToken() {
    return localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
  }
}
