import { InputAdornment, TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { useCallback } from 'react';
import { OnValueChange } from 'react-number-format/types/types';
import { useTranslation } from 'react-i18next';
import { getSeparator, isInt } from '@social-garden/utils/helpers.ts';

function getFloatValue(value: number | undefined) {
  if (value === undefined) {
    return undefined;
  }
  if (isInt(value)) {
    return value / 100;
  }
  return value;
}

interface MoneyFieldProps {
  fullWidth?: boolean;
  label?: string;
  value?: number;
  defaultValue?: number;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onChange?(value: number): void;
  onBlur?(): void;
}

export default function MoneyField({
  value,
  defaultValue,
  onChange,
  ...rest
}: MoneyFieldProps) {
  const {
    i18n: { resolvedLanguage, languages },
  } = useTranslation(['manager']);

  const handleOnChange = useCallback<OnValueChange>(
    (event) => {
      if (onChange) {
        onChange(event.floatValue ? event.floatValue * 100 : 0);
      }
    },
    [onChange],
  );

  return (
    <NumericFormat
      decimalScale={2}
      allowLeadingZeros={false}
      fixedDecimalScale={true}
      allowNegative={false}
      decimalSeparator={getSeparator(
        resolvedLanguage ?? languages[0],
        'decimal',
      )}
      thousandSeparator={getSeparator(
        resolvedLanguage ?? languages[0],
        'group',
      )}
      value={getFloatValue(value)}
      defaultValue={getFloatValue(defaultValue)}
      customInput={TextField}
      InputProps={{
        startAdornment: <InputAdornment position="start">€</InputAdornment>,
      }}
      onValueChange={handleOnChange}
      {...rest}
    />
  );
}
